import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { Divider } from '../../helperComponents/Divider';
import { Input } from '../../helperComponents/Input';
import { applyTransparency } from '../../../constants/colors';
import { MenuButton } from '../../helperComponents/MenuButton';
import { BackButton } from '../../helperComponents/BackButton';
import { UniversalProps } from '../../../typesInterfacesEnums/componentProps';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import { ErrorPopup } from '../../helperComponents/ErrorPopup';
import { useJoinHiveMutation } from './useJoinHiveMutation.gql';
import { ApolloError } from '@apollo/client';
import { getErrorText } from '../../../utils/errors';

enum INPUT_TYPES {
  code = 'code',
}

const JoinHive = ({ navigation }: UniversalProps) => {
  const [code, setCode] = useState('');
  const [joinHive, { loading, data }] = useJoinHiveMutation({ variables: { code: code } });
  const [inputError, setInputError] = useState<INPUT_TYPES[]>([]);

  const [hasError, setHasError] = useState({
    visible: false,
    popup: false,
    text: '',
  });

  const handleJoinHiveError = (err: ApolloError) => {
    setHasError({ popup: true, visible: true, text: getErrorText(err) });
  };

  const handleJoinPress = () => {
    setInputError([]);
    if (code.length !== 6) {
      setInputError([INPUT_TYPES.code]);
      setHasError({ popup: true, visible: true, text: 'The entered key is not valid!' });
      return;
    }
    joinHive().catch((err) => handleJoinHiveError(err));
  };

  useEffect(() => {
    if (data) {
      if (data.joinHive) {
        navigation.navigate('MyHives', { shouldRefetch: true });
      }
    }
  }, [data, navigation]);

  return (
    <>
      <ComponentWrapper
        hasInset
        hasScroll={false}
        scrollEnabled={false}
        style={{ paddingHorizontal: 0 }}
      >
        <View style={styles.topContainer}>
          <BackButton onPress={() => navigation.goBack()} text="Back" />
        </View>
        <Text maxFontSizeMultiplier={1} style={styles.title}>
          Enter Hive Key To Join
        </Text>
        <Divider empty height={40} />
        <Input
          value={code}
          setValue={(value) => setCode(value)}
          style={{ width: '100%', maxWidth: 320 }}
          textStyle={{ fontSize: 18 }}
          placeholder="Enter key"
          hasError={inputError.includes(INPUT_TYPES.code)}
          onSubmitEditing={handleJoinPress}
        />
        <Divider empty height={40} />
        <MenuButton
          onPress={handleJoinPress}
          colors={['#90DF75', '#62B655']}
          text="Join"
          height={64}
          width={320}
          loading={loading}
          disabled={loading || code.length !== 6}
        />
      </ComponentWrapper>
      <ErrorPopup
        isVisible={hasError.popup}
        setVisible={() => setHasError(v => ({ ...v, popup: false }))}
        text={hasError.text}
      />
    </>
  );
};

export { JoinHive };

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Secular One',
    fontSize: 32,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
  },
  topContainer: {
    height: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    top: 60,
    ...mbPlatformStyle({
      mobile: {
        paddingHorizontal: 24,
      },
      web: {
        paddingHorizontal: 120,
      },
    }),
  },
  forgetContainer: {
    width: 320,
    justifyContent: 'flex-start',
  },
  forgetText: {
    fontFamily: 'Secular One',
    fontSize: 16,
    color: '#E89823',
    textAlign: 'right',
  },
  signUpContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
