import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { applyTransparency } from '../../../constants/colors';
import { Divider } from '../Divider';
import { MenuButton } from '../MenuButton';
import { Popup } from '../Popup';
import { GameStatus, useJoinGameByCodeMutation } from './useJoinGameByCodeMutation.gql';
import { GameMode } from '../../screens/GameScreen';
import { getRootNavRef } from '../../../navigators/RootNavigator';
import { GAME_TYPES_CONFIG, GAME_TYPES_TYPE } from '../../../constants/constants';
import { useSignedInContext } from '../../../context/SignedInContext';
import { useOpenClose } from '../../../utils/hooks';
import { useLeaveWaitingHostGameMutation } from './useLeaveWaitingGameMutation.gql';
import { ApolloError } from '@apollo/client';
import { EventTypeGameEventType, useGameEventSubscription } from './useGameEventSubscription.gql';
import { getErrorText } from '../../../utils/errors';

interface IPopupProps {
  onYesPress: () => void;
  onNoPress: () => void;
  detail: {
    inviterUserName: string,
    gameCode: string,
    gameType: GAME_TYPES_TYPE,
    hiveName: string,
    inviterUserId: string
  };
}

const InviteToGame = ({ onYesPress, onNoPress, detail }: IPopupProps) => {
  const { user } = useSignedInContext();
  const [joinGame, { loading }] = useJoinGameByCodeMutation();
  const [closeLoading, setCloseLoading] = useState(false);
  const [leaveGame] = useLeaveWaitingHostGameMutation();
  const [isPopupVisible, setPopupOpen, setPopupClose] = useOpenClose();
  const { data: gameEventSubscription } = useGameEventSubscription();
  const [hasError, setHasError] = useState({
    visible: false,
    popup: false,
    text: '',
  });

  const handleJoinGame = () => {
    if (detail && user) {
      joinGame({ variables: { inputs: { joinCode: detail.gameCode, userName: user.email } } }).catch((err) => {
        handleError(err);
      });
    }
  };
  const handleNoPress = () => {
    if (detail && user) {
      leaveGame({ variables: { joinCode: detail.gameCode } }).then(() => {
        setCloseLoading(true);
        setTimeout(() => {
          setPopupClose();
          setCloseLoading(false);
          onNoPress();
        }, 1000);
      }).catch((err) => {
        handleError(err);
      });
    }
  };
  const handleError = (err: ApolloError) => {
    setHasError({ popup: true, visible: true, text: getErrorText(err) });
  };

  useEffect(() => {
    if (detail) {
      setHasError({ popup: false, visible: false, text: '' });
      // for smooth opening I added timeout.
      setTimeout(() => {
        setPopupOpen();
      }, 500);
    } else {
      setPopupClose();
    }
  }, [detail, setPopupClose, setPopupOpen]);

  useEffect(() => {
    if (gameEventSubscription) {
      if (
        gameEventSubscription?.gameEvent.eventType === EventTypeGameEventType.ON_GAME_START &&
        gameEventSubscription.gameEvent.game.status !== GameStatus.FINISHED
      ) {
        setPopupClose();
        setTimeout(() => {
          onYesPress();
        }, 1000);
        getRootNavRef()?.navigate('Game', {
          game: gameEventSubscription.gameEvent.game,
          mode: GameMode.JOIN_A_GAME,
        });
      }
      if (gameEventSubscription?.gameEvent && gameEventSubscription.gameEvent.game.status === GameStatus.FINISHED) {
        console.warn('finished game ignored!');
      }
    }
  }, [gameEventSubscription, onYesPress, setPopupClose]);

  return (
    <Popup
      height={216}
      title="Pairing Notification"
      hasHeader={true}
      headerTextStyle={{ fontSize: 18 }}
      visible={isPopupVisible}
      setVisible={onNoPress}
      closeOnBackdropPress={false}
      hideCloseButton={!hasError.popup}
    >
      <View style={styles.container}>
        <Divider empty height={36} />
        {
          hasError.visible ?
            <>
              <Divider empty height={24} />
              <Text maxFontSizeMultiplier={1} style={styles.title}>
                {hasError.text}
              </Text>
            </>
            :
            <>
              <Text maxFontSizeMultiplier={1} style={styles.title}>
                You’ve been paired with {detail.inviterUserName} to play {`${GAME_TYPES_CONFIG[detail.gameType].name}`} in the Hive “{detail.hiveName}”.
              </Text>
              <Divider empty height={12} />
              <View style={styles.buttonContainer}>
                <MenuButton
                  height={62}
                  width={160}
                  onPress={handleNoPress}
                  text="Decline"
                  colors={['#FF9252', '#FF3F15']}
                  textStyle={styles.buttonTextStyle}
                  style={styles.button}
                  disabled={closeLoading}
                  loading={closeLoading}
                />
                <MenuButton
                  height={62}
                  width={160}
                  onPress={handleJoinGame}
                  text="Join game"
                  colors={['#90DF75', '#62B655']}
                  textStyle={styles.buttonTextStyle}
                  style={styles.button}
                  disabled={loading}
                  loading={loading}
                />
              </View>
            </>
        }
      </View>
    </Popup>
  );
};

export { InviteToGame };

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  confetti: {
    position: 'absolute',
    width: 180,
    left: '50%',
    top: -50,
    transform: [{ translateX: -90 }],
  },
  title: {
    fontFamily: 'Secular One',
    color: '#000000',
    fontSize: 18,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    textAlign: 'center',
    paddingHorizontal: 12,
  },
  subtitle: {
    fontFamily: 'Secular One',
    color: '#86cf7b',
    fontSize: 24,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
  button: {
    marginHorizontal: 12,
  },
  buttonTextStyle: {
    fontFamily: 'Secular One',
    color: '#fff',
    fontSize: 20,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
