import { gql, useMutation, MutationHookOptions } from '@apollo/client';

export const query = gql`
  mutation JoinHive($code: String!) {
    joinHive(code: $code)
  }
`;

export interface RequestType {
  code: string;
}

export interface MutationType {
  joinHive: boolean;
}

export function useJoinHiveMutation(options?: MutationHookOptions<MutationType, RequestType>) {
  return useMutation<MutationType, RequestType>(query, options);
}
