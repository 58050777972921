import { SubscriptionHookOptions, useSubscription } from '@apollo/client';
import gql from 'graphql-tag';
import { GAME_TYPES_TYPE, OPERAND_TYPE } from '../../../constants/constants';

const subscription = gql`
  subscription subscribeToGameEvent {
    gameEvent {
      eventType
      game {
        id
        status
        users {
          id
          name
        }
        gameState {
          gameType
          turn
          userNameTurn
          winningPlayerId
          winningPlayerUserName
          lastPlayedTimeAt
          winningTiles
          winningDirection
          leftOperand{
            a
            x
            b
          }
          rightOperand{
            a
            x
            b
          }
          tiles {
            id
            value {
              a
              x
              b
            }
            edges
            claimedBy
            claimedByUserName
          }
        }
      }
      earnedPoints
    }
  }
`;

export interface SubscriptionType {
  gameEvent: GameEventType;
  __typename?: 'Subscription';
}

export interface GameEventType {
  eventType: EventTypeGameEventType;
  game: GameType;
    earnedPoints?: number
  __typename?: 'GameEvent';
}

export enum EventTypeGameEventType {
  ON_GAME_START = 'ON_GAME_START',
  ON_CLAIM_TILE = 'ON_CLAIM_TILE',
  ON_CHANGE_TURN = 'ON_CHANGE_TURN',
  ON_USER_LEFT = 'ON_USER_LEFT',
}

export interface GameType {
  id: string;
  status: GameStatus;
  users: GameUserType[];
  gameState: GameStateType;
  __typename?: 'Game';
}

export enum GameMode {
  PLAY_ONLINE = 'PLAY_ONLINE',
  HOST_A_GAME = 'HOST_A_GAME',
  JOIN_A_GAME = 'JOIN_A_GAME',
  PRACTICE = 'PRACTICE',
}

export enum GameStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

export interface GameUserType {
  id: string;
  name: string;
  __typename?: 'GameUser';
}

export interface GameStateType {
  gameType: GAME_TYPES_TYPE;
  turn: string;
  userNameTurn?: string;
  winningPlayerId: string;
  winningPlayerUserName?: string;
  leftOperand: OPERAND_TYPE;
  rightOperand: OPERAND_TYPE;
  tiles: GameHiveTileType[];
  winningTiles: number[];
  winningDirection: number[];
  lastPlayedTimeAt: string;
  __typename?: 'GameState';
}

export interface GameHiveTileType {
  id: number;
  value: OPERAND_TYPE;
  edges: number[];
  claimedBy: string;
  claimedByUserName?: string;
  __typename?: 'GameHiveTile';
}

export function useGameEventSubscription(
  options?: SubscriptionHookOptions<SubscriptionType, {}>,
) {
  return useSubscription<SubscriptionType, {}>(subscription, options);
}
