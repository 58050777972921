import React from 'react';
import { Divider } from '../../helperComponents/Divider';
import { Platform } from 'react-native';

export const lobbyButtonSharedProps = {
    height: 74,
    width: 280,
};

export enum lobbyProps {
    platAFriend = 'PlayAFriend',
}

export const ButtonRowDivider = ()=> {
    return <Divider empty height={Platform.OS === 'web' ? 16 : 12} />;
};
