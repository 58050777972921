import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { GAME_TYPES_TYPE } from '../../../constants/constants';

const mutation = gql`
  mutation joinOnlineGame($userName: String!, $gameType: GameType!) {
    joinOnlineGame(userName: $userName, gameType: $gameType)
  }
`;

export interface RequestType {
  userName: string;
  gameType: GAME_TYPES_TYPE
}

export interface MutationType {
  joinOnlineGame: boolean;
}

export function useJoinOnlineGameMutation(
  options?: MutationHookOptions<MutationType, RequestType>,
) {
  return useMutation<MutationType, RequestType>(mutation, options);
}
