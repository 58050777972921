import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Defs, G, LinearGradient, Path, Stop } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  containerStyle?: StyleProp<ViewStyle>;
}

const LeftGreenArrow = React.memo(({ color, containerStyle, height = 33, width = 68 }: Props) => {
  return (
    <View style={containerStyle}>
      <Svg width={width} height={height} viewBox="0 0 68 33" fill="none" color={color}>
        <G>
          <Path
            d="M21.51 29c-.126 0-.293-.042-.377-.125L2.251 14.082A.674.674 0 012 13.54c0-.209.125-.376.292-.502L22.47.085C22.554 0 22.679 0 22.804 0c.126 0 .21.042.293.084.208.125.334.334.292.585l-.25 5.557 42.318 7.146a.633.633 0 01.543.627.628.628 0 01-.543.626l-43.029 7.23-.292 6.518c0 .25-.167.46-.376.543-.084.042-.167.084-.25.084z"
            fill="url(#paint0_linear_46_3531)"
          />
          <Path
            d="M21.485 28.494l-.006.003a.207.207 0 01-.024-.006l-.013-.01L2.559 13.688h0l-.008-.006a.107.107 0 01-.031-.043.25.25 0 01-.02-.103.317.317 0 01.08-.09L22.74.505 22.746.5a2.624 2.624 0 01.062 0l.018.008a.13.13 0 01.068.054.02.02 0 01.003.006l-.001.018-.005.03-.001.03-.251 5.558-.02.442.436.073 42.319 7.146h0l.012.002c.029.004.059.02.081.044.02.022.033.05.033.088a.15.15 0 01-.033.09.105.105 0 01-.072.04l-.01.002-.01.001-43.03 7.23-.398.066-.018.404-.293 6.519v.022a.07.07 0 01-.015.042.112.112 0 01-.047.037l-.02.008-.018.01a2.589 2.589 0 01-.051.024z"
            stroke="#fff"
          />
        </G>
        <Defs>
          <LinearGradient
            id="paint0_linear_46_3531"
            x1={34}
            y1={0}
            x2={34}
            y2={29}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#90DF75" />
            <Stop offset={1} stopColor="#62B655" />
          </LinearGradient>
        </Defs>
      </Svg>
    </View>
  );
});

export { LeftGreenArrow };
