import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const TrashBinIcon = React.memo(({size, containerStyle, color}: {
    size?: number,
    containerStyle?: StyleProp<ViewStyle>
    color?: string,
    filled?: boolean,
}) => {
    const iconColor = color ?? '#FFFFFF';
    return (
        <View style={containerStyle}>
            <Svg
                width={size ?? 18}
                height={size ? size : 18}
                viewBox="0 0 18 18"
                fill="none"
            >
                <Path
                    d="M7.33 3.589h3.334a1.667 1.667 0 10-3.333 0zm-1.25 0a2.917 2.917 0 115.834 0h4.792a.625.625 0 110 1.25h-1.1L14.63 14.93a3.125 3.125 0 01-3.112 2.824H6.476a3.125 3.125 0 01-3.111-2.824L2.389 4.839h-1.1a.625.625 0 010-1.25h4.792zm1.667 3.958a.625.625 0 10-1.25 0v6.25a.625.625 0 001.25 0v-6.25zm3.125-.625a.625.625 0 01.625.625v6.25a.625.625 0 01-1.25 0v-6.25a.625.625 0 01.625-.625zM4.61 14.81a1.875 1.875 0 001.867 1.694h5.043a1.875 1.875 0 001.867-1.694l.965-9.972H3.644l.965 9.972z"
                    fill={iconColor}
                />
            </Svg>
        </View>
    );
});

export default TrashBinIcon;
