import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path, LinearGradient, Defs, G, Stop } from 'react-native-svg';

const RibbonYellow = React.memo(({containerStyle, width, height, fillColor, stopColor1, stopColor2}: {
    containerStyle?: StyleProp<ViewStyle>
    width?: number | string
    height?: number | string
    fillColor?: string | undefined,
    stopColor1?: string | undefined
    stopColor2?: string | undefined

}) => {
    //2287_7711
    return (
        <View style={containerStyle}>
            <Svg
                width={width ?? 375}
                height={height ?? 83}
                viewBox={'0 0 375 83'}
                fill="none"
            >
                <G>
                    <Path
                        d="M-2 10h378l-21.214 28.5L376 67H-2l22.5-28.5L-2 10z"
                        fill={`url(#paint0_linear_${fillColor ?? '2243_6055'})`}
                    />
                </G>
                <Defs>
                    <LinearGradient
                        id={`paint0_linear_${fillColor ?? '2243_6055'}`}
                        x1={187}
                        y1={10}
                        x2={187}
                        y2={67}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor={stopColor1 ?? '#FFCF53'} />
                        <Stop offset={1} stopColor={stopColor2 ?? '#F90'} />
                    </LinearGradient>
                </Defs>
            </Svg>
        </View>
    );
});

export default RibbonYellow;
