import React, { memo, useCallback } from 'react';
import { GAME_TYPES_CONFIG, GAME_TYPES_TYPE } from '../../../constants/constants';
import { TileRow } from './TileRow';
import { GameHiveTileType } from './useGameEventSubscription.gql';

interface Props {
  gameTiles: GameHiveTileType[];
  currentUserId: string;
  gameType: GAME_TYPES_TYPE;
  waitingTiles: number[];
  onPress: (tileId: number) => void;
  isPassAndPlay: boolean;
  currentUserName?: string;
}

const colors1: [string, string] = ['#FFCF53', '#FF9900'];
const colors2: [string, string] = ['#FF9252', '#FF3F15'];

function createTiles(rowMap: number[], gameTilesArg: GameHiveTileType[], waitingTiles: number[]): { tiles: GameHiveTileType[], isActiveRow: boolean }[] {
  let renderedCount = 0;
  const tiles: { tiles: GameHiveTileType[], isActiveRow: boolean }[] = [];
  rowMap.map(tilesInRow => {
    const rowTiles: GameHiveTileType[] = [];
    let isActiveRow: boolean = false;
    for (let i = renderedCount; i < renderedCount + tilesInRow; i++) {
      rowTiles.push(gameTilesArg[i]);
      if (waitingTiles.indexOf(gameTilesArg[i].id) !== -1) {
        isActiveRow = true;
      }
    }
    renderedCount = renderedCount + tilesInRow;
    tiles.push({ tiles: rowTiles, isActiveRow: isActiveRow });
  });

  return tiles;
}

export const TilesView = memo(({ gameTiles, currentUserId, gameType, waitingTiles, onPress, isPassAndPlay, currentUserName }: Props) => {
  const getClaimColors = useCallback(
    (playerId: string, playerUserName?: string) => {
      if (isPassAndPlay) {
        return (playerUserName === currentUserName ? colors1 : colors2);
      }
      return (playerId === currentUserId ? colors1 : colors2);
    },
    [currentUserId, currentUserName, isPassAndPlay],
  );

  const rowTileCounts = GAME_TYPES_CONFIG[gameType].rowsCount;
  const hiveTiles = createTiles(rowTileCounts, gameTiles, waitingTiles);

  return (
    <>
      {hiveTiles.map((tile, rowIndex) => (
        <TileRow
          key={`row_${rowIndex}`}
          rowIndex={rowIndex}
          tiles={tile.tiles}
          getClaimColors={getClaimColors}
          waitingTiles={waitingTiles}
          onPress={onPress}
          isActiveRow={tile.isActiveRow}
        />
      ))}
    </>
  );
});

TilesView.displayName = 'TilesView';
