import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Text, TouchableOpacity, Linking, Animated } from 'react-native';
import { Divider } from '../../helperComponents/Divider';
import { isMobile, isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { applyTransparency } from '../../../constants/colors';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { BackButton } from '../../helperComponents/BackButton';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import { envs } from '../../../../env';
import Clipboard from '@react-native-clipboard/clipboard';
import { UniversalProps } from '../../../typesInterfacesEnums/componentProps';


const ContactUs = ({ navigation }: UniversalProps) => {

    const [showCopied, setShowCopied] = useState(false);

    const fadeAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0

    useEffect(() => {
        if (showCopied) {
            Animated.timing(fadeAnim, {
                toValue: 1,
                duration: 700,
                useNativeDriver: true,
            }).start();
            setTimeout(() => {
                setShowCopied(false);
            }, 1000);
        } else {
            Animated.timing(fadeAnim, {
                toValue: 0,
                duration: 500,
                useNativeDriver: true,
            }).start();
        }
    }, [fadeAnim, showCopied]);

    const goToEmailClient = () => {
        if (!isMobileApp) {
            Clipboard.setString(envs.CONTACT_US_EMAIL);
            setShowCopied(true);
        } else {
            Linking.openURL('mailto:' + envs.CONTACT_US_EMAIL);
        }
    };

    const handleGoBackPress = () => {
        if (navigation.canGoBack()) {
            navigation.goBack();
        } else {
            navigation.reset({ index: 0, routes: [{ name: 'Lobby' }]});
        }
    };

    return (
        <ComponentWrapper hasInset hasScroll={true} style={{ paddingHorizontal: 0 }}>
            <Divider empty height={isMobile ? 170 : 130} />
            <Text style={styles.title}>Contact Us</Text>
            <Divider empty height={20} />
            <Text style={styles.text}>
                For more inquiries, feel free to contact us via our email address:
            </Text>
            <Divider empty height={20} />
            <TouchableOpacity onPress={goToEmailClient}
                              style={styles.buttonStyle}
            >
                <Text style={styles.buttonText}>
                    {envs.CONTACT_US_EMAIL}
                </Text>
            </TouchableOpacity>
            <Animated.View style={{opacity: fadeAnim}}>
            <Text style={[styles.copiedText]}>Copied to clipboard!</Text>
            </Animated.View>

            <View style={styles.topContainer}>
                <BackButton
                    text="Back"
                    onPress={handleGoBackPress}
                />
            </View>
            <Divider empty height={isMobile ? 170 : 240} />
        </ComponentWrapper>
    );
};

const styles = StyleSheet.create({
    topContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...mbPlatformStyle({
            mobile: {
                left: 20,
            },
            web: {
                left: 60,
            },
        }),
        position: 'absolute',
        top: 60,
    },
    title: {
        fontFamily: 'Secular One',
        fontSize: 32,
        textShadowColor: applyTransparency('#000000', 0.15),
        textShadowOffset: { width: 0, height: 4 },
        textShadowRadius: 2,
        color: '#121212',
    },
    text: {
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: '400',
        color: '#121212',
        textAlign: 'center',
    },
    buttonStyle: {
        backgroundColor: '#FFEED6',
        width: 290,
        height: 54,
        borderRadius: 50,
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonText: {
        fontFamily: 'Inter',
        fontSize: 18,
        fontWeight: '600',
        color: '#E89823',
        textAlign: 'center',
    },
    copiedText: {
        fontFamily: 'Inter',
        fontStyle: 'italic',
        fontSize: 16,
        fontWeight: '400',
        color: '#E89823',
    },
});

export {ContactUs};

