import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const TermsIcon = React.memo(({size, containerStyle, color}: {
    size?: number,
    containerStyle?: StyleProp<ViewStyle>
    color?: string,
    filled?: boolean,
}) => {
    const iconColor = color ?? '#FFFFFF';
    return (
        <View style={containerStyle}>
            <Svg
                width={size ?? 15}
                height={size ? size * (18 / 15) : 18}
                viewBox="0 0 15 18"
                fill="none"
            >
                <Path
                    d="M3.12 8.92h5.476M3.12 11.112h7.667m-7.667 2.19h3.286m7.667 1.096V6.73L8.595 1.254H3.12a2.19 2.19 0 00-2.19 2.19v10.953a2.19 2.19 0 002.19 2.19h8.762a2.19 2.19 0 002.19-2.19z"
                    stroke={iconColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <Path
                    d="M8.594 1.254V4.54a2.19 2.19 0 002.19 2.19h3.286"
                    stroke={iconColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </Svg>
        </View>
    );
});

export default TermsIcon;
