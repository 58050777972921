import { nanoid } from 'nanoid';
import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const HexagonWithTickIcon = React.memo(
  (props: {
    size?: number;
    color?: string;
    containerStyle?: StyleProp<ViewStyle>;
  }) => {
    const gradientId = nanoid();

    return (
      <View style={props.containerStyle}>
        <Svg
          width={props.size ?? 50}
          height={props.size ? (props.size * (59 / 50)) : 59}
          viewBox="0 0 50 59" {...props}>
          <Defs>
            <LinearGradient
              x1="49.9999064%"
              y1="1.72201183%"
              x2="49.9999064%"
              y2="98.2779349%"
              id={gradientId + '1'}
            >
              <Stop stopColor="#747474" offset="0%" />
              <Stop stopColor="#1F1F1F" offset="100%" />
            </LinearGradient>
            <LinearGradient
              x1="49.9998423%"
              y1="1.72219951%"
              x2="49.9998423%"
              y2="98.2778005%"
              id={gradientId + '2'}
            >
              <Stop stopColor="#FFCF53" offset="0%" />
              <Stop stopColor="#F90" offset="100%" />
            </LinearGradient>
          </Defs>
          <G
            transform="translate(1 1)"
            stroke="none"
            strokeWidth={1}
            fill="none"
            fillRule="evenodd"
          >
            <Path
              stroke={`url(#${gradientId + '1'})`}
              strokeWidth={1.67468}
              fill="#FDFDFD"
              fillRule="nonzero"
              d="M24.46591 0.24835L24.04231 0 23.61881 0.24835 0.41379 13.85507 0 14.09777 0 14.57747 0 41.79097 0 42.27067 0.41379 42.51327 23.61881 56.11997 24.04231 56.36837 24.46591 56.11997 47.67091 42.51327 48.08471 42.27067 48.08471 41.79097 48.08471 14.57747 48.08471 14.09777 47.67091 13.85507z"
            />
            <Path
              stroke={`url(#${gradientId + '2'})`}
              strokeWidth={1.10457}
              fill={`url(#${gradientId + '2'})`}
              fillRule="nonzero"
              d="M24.32181 9.75837L24.04241 9.59457 23.76311 9.75837 8.45761 18.73307 8.18471 18.89307 8.18471 19.20947 8.18471 37.15887 8.18471 37.47527 8.45761 37.63527 23.76311 46.60997 24.04241 46.77377 24.32181 46.60997 39.62721 37.63527 39.90021 37.47527 39.90021 37.15887 39.90021 19.20947 39.90021 18.89307 39.62721 18.73307z"
            />
            <Path
              stroke="#000"
              strokeWidth={4.5}
              strokeLinecap="square"
              strokeLinejoin="round"
              d="M31.98811 22.97307L21.63831 33.39527 16.93381 28.65797"
            />
          </G>
        </Svg>
      </View>
    );
  },
);

export { HexagonWithTickIcon };
