import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { applyTransparency } from '../../constants/colors';
import { Divider } from './Divider';
import { Popup } from './Popup';

const timeTableImage = require('../../resources/images/timeTable.png');

interface IPopupProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  gameId: string;
}
const HintPopup = ({ visible, setVisible, gameId }: IPopupProps) => {
  return (
    <Popup
      height={476}
      title="Hint"
      hasHeader={true}
      visible={visible}
      setVisible={setVisible}
      closeOnBackdropPress={false}
      hideCloseButton
    >
      <View style={styles.container}>
        <Divider empty height={36} />
        <Text maxFontSizeMultiplier={1} style={styles.title}>Here’s a multiplication table to assist you.</Text>
        <Divider empty height={24} />
        <Image style={{ width: 300, height: 300 }} source={timeTableImage} resizeMode="contain" />
        <Divider empty height={12} />
        <Text selectable style={styles.gameIdText}>GameID: {gameId}</Text>
        <Divider empty height={12} />
        <TouchableOpacity onPress={() => setVisible(false)}>
          <LinearGradient style={styles.button} colors={['#FFCF53', '#FF9900']}>
            <Text maxFontSizeMultiplier={1} style={styles.buttonText}>
              Exit
            </Text>
          </LinearGradient>
        </TouchableOpacity>
      </View>
    </Popup>
  );
};

export { HintPopup };

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  confetti: {
    position: 'absolute',
    width: 180,
    left: '50%',
    top: -50,
    transform: [{ translateX: -90 }],
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: '400',
    color: '#000000',
    fontSize: 13,
  },
  subtitle: {
    fontFamily: 'Secular One',
    color: '#86cf7b',
    fontSize: 24,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
  button: {
    marginHorizontal: 12,
    width: 300,
    height: 40,
    fontSize: 18,
    paddingVertical: 6,
    textAlign: 'center',
    color: '#121212',
    borderWidth: 2,
  },
  buttonTextStyle: {
    fontFamily: 'Secular One',
    color: '#fff',
    fontSize: 20,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonText: {
    fontFamily: 'Secular One',
    fontSize: 18,
    color: '#121212',
    textAlign: 'center',
  },
  gameIdText: {
    fontFamily: 'Secular One',
    fontSize: 12,
    color: '#121212',
    textAlign: 'center',
  },
});
