import { useMutation} from '@apollo/client';
import gql from 'graphql-tag';

const query = gql`
    mutation deleteAccount{
        deleteAccount
    }
`;

export function useDeleteAccountQuery() {
    return useMutation(query);
}
