import React from 'react';
import {
  StyleSheet,
  Image,
  Text,
  View,
  StyleProp,
  ViewStyle,
  Pressable,
  ImageSourcePropType,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { HexagonIcon } from '../../resources/svgComponents/HexagonIcon';

const bee1Icon = require('../../resources/images/profile.png');

const MAX_NAME_LENGTH = 10;

interface IProfileNameProps {
  image?: ImageSourcePropType;
  name?: string;
  isHorizontal?: boolean;
  style?: StyleProp<ViewStyle>;
  gradientColors: [string, string];
  color?: string;
  reverse?: boolean;
  active?: boolean;
  isLarge?: boolean;
  onPress?: (id: string) => void;
}
const ProfileName = ({
  name = '',
  isHorizontal = false,
  style,
  gradientColors,
  onPress,
  color,
  active = true,
  reverse = false,
  isLarge = false,
}: IProfileNameProps) => {
  const personName =
    name.length > MAX_NAME_LENGTH ? `${name.substring(0, MAX_NAME_LENGTH - 3)}...` : name;

  if (isHorizontal) {
    return (
      <Pressable
        onPress={() => onPress?.('yo')}
        style={[styles.horizontalContainer, reverse && styles.reverseContainer, style]}
      >
        <View style={{ position: 'relative' }}>
          <Image source={bee1Icon} style={styles.horizontalImage} />
          <HexagonIcon
            width={isLarge ? 64 : 48}
            height={isLarge ? 64 : 48}
            transparent
            colors={gradientColors}
          />
        </View>
        <LinearGradient
          style={[
            isLarge ? styles.largeNameContainerHorizontal : styles.nameContainerHorizontal,
            reverse && isLarge
              ? styles.largeReverseNameContainerHorizontal
              : styles.reverseNameContainerHorizontal,
            { borderWidth: 2, borderColor: color },
          ]}
          colors={active ? gradientColors : ['white', 'white']}
        >
          <Text maxFontSizeMultiplier={1} style={styles.horizontalName}>{personName}</Text>
        </LinearGradient>
      </Pressable>
    );
  }
  return (
    <Pressable onPress={() => onPress?.('yo')} style={[styles.container, style]}>
      <View style={{ position: 'relative' }}>
        <Image source={bee1Icon} style={styles.image} />
        <HexagonIcon width={80} height={80} transparent colors={gradientColors} />
      </View>
      <LinearGradient style={styles.nameContainer} colors={gradientColors}>
        <Text maxFontSizeMultiplier={1} style={styles.name}>{personName}</Text>
      </LinearGradient>
    </Pressable>
  );
};

export { ProfileName };

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  horizontalContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  reverseContainer: {
    flexDirection: 'row-reverse',
  },
  image: {
    width: 37,
    height: 40,
    position: 'absolute',
    top: 16,
    left: 24,
  },
  horizontalImage: {
    width: 24,
    height: 36,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: [{ translateX: -12 }, { translateY: -18 }],
  },
  largeNameContainerHorizontal: {
    width: 115,
    height: 35,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -6,
  },
  nameContainerHorizontal: {
    width: 89,
    height: 27,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -4,
  },
  largeReverseNameContainerHorizontal: {
    marginRight: -6,
  },
  reverseNameContainerHorizontal: {
    marginRight: -4,
  },
  nameContainer: {
    width: 112,
    height: 34,
    marginTop: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  name: {
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Secular One',
    fontSize: 18,
    color: 'black',
  },
  horizontalName: {
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Secular One',
    fontSize: 14,
    color: 'black',
  },
});
