import React, {useState, useRef} from 'react';
import { ScrollView, Animated, View } from 'react-native';
import { COLORS } from '../../constants/colors';

const CustomScrollView = ({
    children,
    barColor,
    indicatorColor,
}:{
    children: React.ReactNode
    barColor?: string,
    indicatorColor?: string
}) => {
    const [completeScrollBarHeight, setCompleteScrollBarHeight] = useState(1);
    const [visibleScrollBarHeight, setVisibleScrollBarHeight] = useState(0);

    const scrollIndicator = useRef(new Animated.Value(0)).current;

    const scrollIndicatorSize =
              completeScrollBarHeight > visibleScrollBarHeight
                  ? (visibleScrollBarHeight * visibleScrollBarHeight) /
                  completeScrollBarHeight
                  : visibleScrollBarHeight;

    const difference =
              visibleScrollBarHeight > scrollIndicatorSize
                  ? visibleScrollBarHeight - scrollIndicatorSize
                  : 1;

    const scrollIndicatorPosition = Animated.multiply(
        scrollIndicator,
        visibleScrollBarHeight / completeScrollBarHeight
    ).interpolate({
        inputRange: [0, difference],
        outputRange: [0, difference],
        extrapolate: 'clamp',
    });

    return (
        <View style={{ flex: 1, flexDirection: 'row'}}>
        <ScrollView
            contentContainerStyle={{ paddingRight: 14 }}
            showsVerticalScrollIndicator={false}
            scrollEventThrottle={16}
            onContentSizeChange={(_,height) => {
                setCompleteScrollBarHeight(height);
            }}
            onLayout={({
                           nativeEvent: {
                               layout: { height },
                           },
                       }) => {
                setVisibleScrollBarHeight(height);
            }}
            onScroll={Animated.event(
                [{ nativeEvent: { contentOffset: { y: scrollIndicator } } }],
                { useNativeDriver: false }
            )}
        >
            {children}
        </ScrollView>
            {completeScrollBarHeight > visibleScrollBarHeight && (
                <View
                    style={{
                        height: '100%',
                        width: 6,
                        backgroundColor: barColor ?? '#FCF0E0',

                        borderRadius: 8,
                    }}
                >
                    <Animated.View
                        style={{
                            width: 6,
                            borderRadius: 8,
                            backgroundColor: indicatorColor ?? COLORS.pumpkinYellow,
                            height: scrollIndicatorSize,
                            transform: [{ translateY: scrollIndicatorPosition }],
                        }}
                    />
                </View>
            )}

    </View>
    );
};

export {CustomScrollView};
