import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const RemoveIcon = React.memo(
  (props: {
    size?: number;
    color?: string;
    containerStyle?: StyleProp<ViewStyle>;
  }) => {
    return (
      <View style={props.containerStyle}>
        <Svg
          width={props.size ?? 24}
          height={props.size ?? 24}
          viewBox="0 0 24 24"
          fill="none"
          {...props}
        >
          <Path
            d="M19.324 9.467s-.543 6.735-.858 9.572c-.15 1.355-.987 2.149-2.358 2.174-2.61.047-5.221.05-7.83-.005-1.318-.027-2.141-.831-2.288-2.162-.317-2.862-.857-9.58-.857-9.58M20.708 6.238H3.75M17.44 6.239a1.648 1.648 0 01-1.614-1.324l-.243-1.216a1.28 1.28 0 00-1.237-.949h-4.233a1.28 1.28 0 00-1.237.949l-.243 1.216a1.648 1.648 0 01-1.615 1.324"
            stroke="#E02E2E"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </Svg>

      </View>
    );
  },
);

export { RemoveIcon };
