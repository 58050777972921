import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const PrivacyIcon = React.memo(({size, containerStyle, color}: {
    size?: number,
    containerStyle?: StyleProp<ViewStyle>
    color?: string,
    filled?: boolean,
}) => {
    const iconColor = color ?? '#FFFFFF';
    return (
        <View style={containerStyle}>
            <Svg
                width={size ?? 14}
                height={size ? size * (19 / 14) : 19}
                viewBox="0 0 14 19"
                fill="none"
            >

                <Path
                    d="M7 13.651a.487.487 0 00.5-.499V8.498a.481.481 0 00-.144-.356.485.485 0 00-.357-.144.481.481 0 00-.356.144.483.483 0 00-.143.356v4.654c0 .142.048.26.144.356a.485.485 0 00.357.144H7zm0-7.23c.175 0 .32-.059.438-.177a.595.595 0 00.177-.438.596.596 0 00-.177-.439A.593.593 0 007 5.19a.593.593 0 00-.438.177.596.596 0 00-.177.44c0 .173.059.32.177.437A.593.593 0 007 6.421zm0 11.842c-.091 0-.187-.008-.287-.025a1.44 1.44 0 01-.28-.075c-1.969-.75-3.533-2.022-4.693-3.816C.58 12.553 0 10.611 0 8.521V4.238c0-.342.098-.651.293-.926.195-.274.448-.473.757-.597l5.385-2C6.63.645 6.818.61 7 .61c.182 0 .37.036.565.106l5.385 2c.31.124.562.323.757.597.195.275.293.584.293.926v4.283c0 2.09-.58 4.032-1.74 5.826-1.16 1.794-2.724 3.066-4.692 3.816a1.463 1.463 0 01-.281.075c-.1.017-.196.025-.287.025zm0-.942c1.733-.55 3.167-1.65 4.3-3.3s1.7-3.483 1.7-5.5V4.22a.596.596 0 00-.106-.346.634.634 0 00-.298-.23l-5.384-2A.55.55 0 007 1.603a.55.55 0 00-.212.04l-5.384 2a.634.634 0 00-.298.23A.596.596 0 001 4.22v4.302c0 2.017.567 3.85 1.7 5.5s2.567 2.75 4.3 3.3z"
                    stroke={iconColor}
                />
            </Svg>
        </View>
    );
});

export default PrivacyIcon;
