import React from 'react';
import { StyleSheet, View, useWindowDimensions } from 'react-native';
import { Popup } from './Popup';
import { applyTransparency } from '../../constants/colors';
import { OnBoardSchema } from '../../resources/svgComponents/OnBoardSchema';
import { isMobile } from '@mightybyte/rnw.utils.device-info';

interface IPopupProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}
const OnBoardPopup = ({ visible, setVisible }: IPopupProps) => {
  const { width, height } = useWindowDimensions();

  const onBoardWidth = Math.min(width * 0.95, 690);
  const fitHeight = Math.min(height * 0.55, 509);
  const onBoardHeight = onBoardWidth * (fitHeight / 690);

  return (
    <Popup
      containerStyle={styles.popupContainer}
      title="How to onboard users"
      headerTextStyle={{ fontSize: 20 }}
      visible={visible}
      setVisible={setVisible}
      closeOnBackdropPress={true}
    >
      <View style={[styles.container, (width < 1000 || isMobile) && styles.mobileContainer]}>
        <OnBoardSchema width={onBoardWidth} height={onBoardHeight} />
      </View>
    </Popup>
  );
};

export { OnBoardPopup };

const styles = StyleSheet.create({
  popupContainer: {
    height: 'auto',
    width: 'auto',
    maxWidth: 'auto',
  },
  title: {
    fontFamily: 'Secular One',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
    fontSize: 18,
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 40,
  },
  mobileContainer: {
    paddingHorizontal: 0,
  },
  buttonTextStyle: {
    fontFamily: 'Secular One',
    color: '#fff',
    fontSize: 20,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
  label: {
    fontFamily: 'Secular One',
    marginBottom: 8,
    fontSize: 15,
  },
});
