import { nanoid } from 'nanoid';
import React, { memo, useEffect, useMemo, useRef } from 'react';
import { Animated, StyleSheet, Text, TouchableOpacity } from 'react-native';
import Svg, { Defs, G, LinearGradient, Path, Stop } from 'react-native-svg';
import { SCREEN_SIZES, useScreenSize } from '../../utils/dimsHooks';
import { isMobile } from '@mightybyte/rnw.utils.device-info';

interface GameTilePolygonProps {
  width?: number;
  colors?: [string, string];
  isActive?: boolean;
  text?: number | string;
  isLargeDisplay?: boolean;
  isMediumDisplay?: boolean;
  isWaitingToSelect?: boolean;
  onPress?: () => void;
}

const GameTilePolygon = memo((props: GameTilePolygonProps) => {
  const screenSize = useScreenSize();
  // const cellSize = screenSize === SCREEN_SIZES.LARGE ? 64 : screenSize === SCREEN_SIZES.SMALL ? 35 : screenSize === SCREEN_SIZES.MEDIUM ? 45 : 40;
  const calculateCellSize = useMemo(() => {
    if (isMobile) {
      if (screenSize === SCREEN_SIZES.LARGE) {
        return 64;
      }
      if (screenSize === SCREEN_SIZES.MEDIUM) {
        return 45;
      }
      if (screenSize === SCREEN_SIZES.SMALL) {
        return 35;
      }
      return 43;
    } else {
      if (screenSize === SCREEN_SIZES.LARGE) {
        return 64;
      }
      if (screenSize === SCREEN_SIZES.MEDIUM) {
        return 39;
      }
      if (screenSize === SCREEN_SIZES.SMALL) {
        return 35;
      }
      return 39;
    }
  }, [screenSize]);
  const gradientId = nanoid();
  const cellSize = calculateCellSize;

  // Flashing Animation
  const fadeAnim = useRef(new Animated.Value(1)).current; // Initial value for opacity: 1
  const startAnimations = Animated.sequence([
    Animated.timing(fadeAnim, {
      toValue: 0.3,
      duration: 500,
      useNativeDriver: true,
    }),
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }),
    Animated.timing(fadeAnim, {
      toValue: 0.3,
      duration: 500,
      useNativeDriver: true,
    }),
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }),
    Animated.timing(fadeAnim, {
      toValue: 0.3,
      duration: 500,
      useNativeDriver: true,
    }),
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }),
  ]);

  useEffect(() => {
    if (props.isActive) {
      startAnimations.start();
    }
  }, [props.isActive, startAnimations]);

  const labelStyle = React.useMemo(
    () => [
      styles.label,
      props.isActive ? styles.activeLabel : {},
      props.isLargeDisplay ? styles.largeDisplayLabel : {},
      props.isMediumDisplay ? styles.mediumDisplayLabel : {},
      props.isWaitingToSelect ? styles.waitingLabel : {},
    ],
    [props.isActive, props.isLargeDisplay, props.isMediumDisplay, props.isWaitingToSelect],
  );

  return (
    <TouchableOpacity disabled={!props.isWaitingToSelect} onPress={() => props.isWaitingToSelect ? props.onPress?.() : null} style={[styles.container, props.isWaitingToSelect ? { zIndex: 1000 } : {}]}>
      <Text maxFontSizeMultiplier={1} style={labelStyle} numberOfLines={1}>
        {props.text}
      </Text>
      <Animated.View style={{ opacity: fadeAnim }}>
        {
          props.isWaitingToSelect ?
            <Svg width={cellSize} height={(49 / 42) * cellSize} viewBox="0 0 42 49">
              <Defs>
                <LinearGradient x1="50.000012%" y1="100%" x2="50.000012%" y2="0%" id={gradientId + '123'}>
                  <Stop stopColor={'#8474DD'} offset="0%" />
                  <Stop stopColor={'#6099C5'} offset="100%" />
                </LinearGradient>
              </Defs>
              <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <Path
                  fill={`url(#${gradientId}123)`}
                  fillRule="nonzero"
                  d="M21.28461 48.57735L0.5 36.57735 0.5 12.57735 21.28461 0.57735 42.06921 12.57735 42.06921 36.57735z"
                />
                <Path
                  stroke="#FFF"
                  d="M21.03461 49.01035L21.28461 49.15465 21.53461 49.01035 42.31921 37.01035 42.56921 36.86605 42.56921 36.57735 42.56921 12.57735 42.56921 12.28865 42.31921 12.14435 21.53461 0.14434 21.28461 0 21.03461 0.14434 0.25 12.14435 0 12.28865 0 12.57735 0 36.57735 0 36.86605 0.25 37.01035z"
                />
              </G>
            </Svg>
            :
            props.isActive ? (
              <Svg width={cellSize} height={(49 / 42) * cellSize} viewBox="0 0 42 49">
                <Defs>
                  <LinearGradient x1="50.000012%" y1="100%" x2="50.000012%" y2="0%" id={gradientId}>
                    <Stop stopColor={props.colors?.[0] || '#FFCF53'} offset="0%" />
                    <Stop stopColor={props.colors?.[1] || '#F90'} offset="100%" />
                  </LinearGradient>
                </Defs>
                <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <Path
                    fill={`url(#${gradientId})`}
                    fillRule="nonzero"
                    d="M21.28461 48.57735L0.5 36.57735 0.5 12.57735 21.28461 0.57735 42.06921 12.57735 42.06921 36.57735z"
                  />
                  <Path
                    stroke="#FFF"
                    d="M21.03461 49.01035L21.28461 49.15465 21.53461 49.01035 42.31921 37.01035 42.56921 36.86605 42.56921 36.57735 42.56921 12.57735 42.56921 12.28865 42.31921 12.14435 21.53461 0.14434 21.28461 0 21.03461 0.14434 0.25 12.14435 0 12.28865 0 12.57735 0 36.57735 0 36.86605 0.25 37.01035z"
                  />
                </G>
              </Svg>
            ) : (
              <Svg width={cellSize} height={(49 / 42) * cellSize} viewBox="0 0 42 49">
                <Path
                  stroke="#FFCA4B"
                  strokeWidth={0.739654}
                  fill="#FFF"
                  fillRule="nonzero"
                  d="M21.1882075 0.109836765L21.0000221 0 20.8117361 0.109836765 0.183917487 12.1451899 0 12.2525324 0 12.4646138 0 36.5353581 0 36.7474396 0.183917487 36.8547821 20.8117361 48.8901542 21.0000221 49 21.1882075 48.8901542 41.8160413 36.8547821 42 36.7474396 42 36.5353581 42 12.4646138 42 12.2525324 41.8160413 12.1451899z"
                />
              </Svg>
            )}
      </Animated.View>
    </TouchableOpacity>
  );
}, arePropsEqual);

function arePropsEqual(oldProps: GameTilePolygonProps, newProps: GameTilePolygonProps) {
  if (oldProps.isWaitingToSelect === true || newProps.isWaitingToSelect === true) {
    return false;
  }
  if (oldProps.isActive !== newProps.isActive) {
    return false;
  }
  if (oldProps.text !== newProps.text) {
    return false;
  }

  return true;
}

export { GameTilePolygon };

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  label: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 2,
    width: 36,
    height: 24,
    fontFamily: 'Secular One',
    color: '#6D6D6D',
    textAlign: 'center',
    fontSize: 15,
    transform: [{ translateX: -18 }, { translateY: -10 }],
  },
  activeLabel: {
    color: '#000',
  },
  largeDisplayLabel: {
    fontSize: 20,
    width: 44,
    transform: [{ translateX: -22 }, { translateY: -12 }],
  },
  mediumDisplayLabel: {
    fontSize: 16,
    transform: [{ translateX: -18 }, { translateY: -10 }],
  },
  waitingLabel: {
    color: 'white',
  },
});
