// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* These styles make the body full-height */
html,
body {
    height: 100%;
    overflow: auto;
}


/* These styles disable body scrolling if you are using <ScrollView> */
/* body { overflow: hidden; } */

#app-root {
    display: flex;
    height: 100%;
}

input {
    outline: none !important;
}

textarea {
    outline: none !important;
}

#cssOverflowAuto {
    overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/css/AppStyles.css"],"names":[],"mappings":"AAAA,2CAA2C;AAC3C;;IAEI,YAAY;IACZ,cAAc;AAClB;;;AAGA,sEAAsE;AACtE,+BAA+B;;AAE/B;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,cAAc;AAClB","sourcesContent":["/* These styles make the body full-height */\r\nhtml,\r\nbody {\r\n    height: 100%;\r\n    overflow: auto;\r\n}\r\n\r\n\r\n/* These styles disable body scrolling if you are using <ScrollView> */\r\n/* body { overflow: hidden; } */\r\n\r\n#app-root {\r\n    display: flex;\r\n    height: 100%;\r\n}\r\n\r\ninput {\r\n    outline: none !important;\r\n}\r\n\r\ntextarea {\r\n    outline: none !important;\r\n}\r\n\r\n#cssOverflowAuto {\r\n    overflow: auto;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
