import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path, Circle } from 'react-native-svg';

const GraphIcon = React.memo(({size, containerStyle, color}: {
    size?: number,
    containerStyle?: StyleProp<ViewStyle>
    color?: string,
    filled?: boolean,
}) => {
    const iconColor = color ?? '#FFFFFF';
    return (
        <View style={containerStyle}>
            <Svg
                width={size ?? 22}
                height={size ? size * (23 / 22) : 23}
                viewBox="0 0 22 23"
                fill="none"
            >
                <Path
                    d="M6.641 14.216l2.744-3.566 3.13 2.458L15.2 9.643"
                    stroke={iconColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <Circle
                    cx={18.3292}
                    cy={4.51629}
                    r={1.76201}
                    stroke={iconColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <Path
                    d="M13.681 3.526H7.019c-2.76 0-4.472 1.955-4.472 4.716v7.409c0 2.76 1.678 4.707 4.472 4.707h7.887c2.76 0 4.472-1.947 4.472-4.707V9.198"
                    stroke={iconColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </Svg>
        </View>
    );
});

export default GraphIcon;
