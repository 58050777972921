import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../../../constants/colors';

const AdminPageHeader = ({text}: {
    text: string
}) => {
    return (
        <View style={styles.wrapper}>


        <Text style={styles.header}>{text}</Text>
        </View>
    );
};

export { AdminPageHeader };

const styles = StyleSheet.create({
    wrapper: {
        alignItems: 'flex-start',
    },
    header: {
        fontFamily: 'Inter',
        fontSize: 18,
        fontWeight: '600',
        color: COLORS.black,
    },
});
