import React, { useRef } from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { AdminNavigatorDrawer } from './AdminNavigatorDrawer';
import { COLORS } from '../../constants/colors';
import { StyleSheet } from 'react-native';
import UserThreeIcon from '../../resources/svgComponents/UsersThreeIcon';
import UserTwoIcon from '../../resources/svgComponents/UsersTwoIcon';
import GraphIcon from '../../resources/svgComponents/GraphIcon';
import GraphIconFilled from '../../resources/svgComponents/GraphIconFilled';
import { Teachers } from '../../components/screens/AdminPanel/Teachers';
import { Students } from '../../components/screens/AdminPanel/Students';
import { Analytics } from '../../components/screens/AdminPanel/Analytics';

export type AdminNavigatorParamList = {
    Teachers: {page: number},
    Students: {page: number}
    Analytics: undefined,
}

const Drawer = createDrawerNavigator<AdminNavigatorParamList>();

function GetDrawerIcon(routeName: keyof AdminNavigatorParamList, isActive: boolean) {
    const baseIconProps = {
        filled: isActive,
        color: isActive ? COLORS.white : COLORS.offWhite,
    };
    switch (routeName) {
        case 'Teachers':
            return <UserThreeIcon size={18} {...baseIconProps} />;
        case 'Students':
            return <UserTwoIcon size={16} {...baseIconProps} containerStyle={{width: 18}}/>;
        case 'Analytics':
            if (isActive) {
                return <GraphIconFilled size={18} {...baseIconProps} />;

            }
            return <GraphIcon size={18} {...baseIconProps} />;
            default:
                return null;
    }
}

const AdminNavigator = () => {
    const optionsBase = useRef({
        drawerActiveBackgroundColor: COLORS.pumpkinYellow,
    });

    return (
        <Drawer.Navigator
            screenOptions={{
                drawerActiveTintColor: COLORS.white,
                drawerInactiveTintColor: COLORS.offWhite,
                headerShown: false,
                drawerType: 'permanent',
                drawerLabelStyle: styles.drawerLabelStyle,
                drawerItemStyle: styles.drawerItemStyle,
                drawerStyle: {
                    width: 265,
                    borderRightWidth: 0,
                },

            }}

            drawerContent={AdminNavigatorDrawer}
        >
            <Drawer.Screen name="Teachers" component={Teachers} options={{
                ...optionsBase.current,
                drawerIcon: ({focused}) => GetDrawerIcon('Teachers', focused) }}
                           initialParams={{page: 1}}
            />
            <Drawer.Screen name="Students" component={Students} options={{
                ...optionsBase.current,
                drawerIcon: ({focused}) => GetDrawerIcon('Students', focused)}}
                           initialParams={{page: 1}}
            />
            <Drawer.Screen name={'Analytics'} component={Analytics} options={{
                ...optionsBase.current,
                drawerIcon: ({focused}) => GetDrawerIcon('Analytics', focused),
            }}/>


        </Drawer.Navigator>
    );
};

export { AdminNavigator };


const styles = StyleSheet.create({
    drawerLabelStyle: {
        fontFamily: 'Inter',
        fontWeight: '500',
        fontSize: 13,
        textAlign: 'left',
        minWidth: 90,
        marginLeft: -18,
        textAlignVertical: 'center',
    },

    drawerItemStyle: {
        borderRadius: 7,
        marginHorizontal: 0,
        paddingHorizontal: 10,
    },
});
