import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const mutation = gql`
  mutation leaveGame($gameId: String!) {
    leaveGame(gameId: $gameId)
  }
`;

export interface RequestType {
  gameId: string;
}

export interface MutationType {
  leaveGame: boolean;
}

export function useLeaveGameMutation(options?: MutationHookOptions<MutationType, RequestType>) {
  return useMutation<MutationType, RequestType>(mutation, options);
}
