import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AppState, Platform, StatusBar, StyleSheet, View } from 'react-native';
import { COLORS, applyTransparency } from '../../../constants/colors';
import { useGraphQL } from '../../../graphql';
import { ScreenProps } from '../../../typesInterfacesEnums/componentProps';
import { logAnalytics } from '../../../utils/analytics';
import { SCREEN_SIZES, useScreenSize } from '../../../utils/dimsHooks';
import { useOpenClose } from '../../../utils/hooks';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { Controls } from '../../helperComponents/Controls';
import { Divider } from '../../helperComponents/Divider';
import { ExitPopup } from '../../helperComponents/ExitPopup';
import { ResultPopup } from '../../helperComponents/ResultPopup';
import { Turn } from '../../helperComponents/Turn';
import WinningBee from '../../helperComponents/WinningBee';
import { GameStatus } from '../PlayOnline/useGameEventSubscription.gql';
import { TilesView } from './TilesView';
import { useChangeTurnMutation } from './useChangeTurnMutation.gql';
import { useClaimGameTileMutation } from './useClaimTileMutation.gql';
import {
  EventTypeGameEventType,
  GameMode,
  GameType,
  useGameEventSubscription,
} from './useGameEventSubscription.gql';
import { useLeaveGameMutation } from './useLeaveGameMutation.gql';
import { performOperation } from './utils';
import { useGetLatestUpdate } from './useGetLatestUpdate.gql';
import { HintPopup } from '../../helperComponents/HintPopup';
import { WorkingBar } from '../../helperComponents/WorkingBar';
import { ReconnectPopup } from '../../helperComponents/ReconnectPopup';
import { useSignedInContext } from '../../../context/SignedInContext';
import { GAME_TYPES_CONFIG, OPERAND_TYPE } from '../../../constants/constants';
import isEqual from 'lodash/isEqual';
import { isMobile } from '@mightybyte/rnw.utils.device-info';
import { useGetCurrentUserData } from '../../../hooks/user';
import cloneDeep from 'lodash/cloneDeep';

const Game = ({ navigation, route }: ScreenProps<'Game'>) => {
  const { user: currentUser } = useSignedInContext();
  const screenSize = useScreenSize();
  const [game, setGame] = useState(route.params.game);
  const gameMode = useRef(route.params.mode);
  const gameTiles = game.gameState.tiles;
  const turn = game.gameState.turn;
  const userNameTurn = game.gameState.userNameTurn;

  const gameType = game.gameState.gameType;

  const players = game.users;
  const gameLeftOperand = game.gameState.leftOperand ?? { a: 1, x: 0, b: 0 };
  const gameRightOperand = game.gameState.rightOperand ?? { a: 1, x: 0, b: 0 };

  const { currentUserId: userId, isOnline } = useGraphQL();
  const { refetchCurrentUserData } = useGetCurrentUserData();
  const [leaveGame] = useLeaveGameMutation();
  const [claimGameTile] = useClaimGameTileMutation();
  const [changeTurn] = useChangeTurnMutation();
  const [getLatestUpdate, { data: latestGameData }] = useGetLatestUpdate({
    variables: { gameId: game.id },
  });
  const { data: gameEventSubscription } = useGameEventSubscription();

  const currentUserId = currentUser ? currentUser.id : userId;

  const [firstSlider, setFirstSlider] = useState(gameLeftOperand);
  const [secondSlider, setSecondSlider] = useState(gameRightOperand);
  const [timer, setTimer] = useState(60 * 1000);

  const [isResultPopupOpen, setResultPopupOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isCurrentPlayerWinner, setCurrentPlayerWinner] = useState(false);
  const [isGameDraw, setGameDraw] = useState(false);
  const [isExitPopupVisible, setExitPopupOpen, setExitPopupClose] = useOpenClose();
  const [isHintPopupVisible, setHintPopupOpen, setHintPopupClose] = useOpenClose();

  const [changedSliderIndex, setChangedSliderIndex] = useState<null | number>(null);

  const isPassAndPlay = Boolean(game.gameState.userNameTurn);

  const currentPlayerIndex = isPassAndPlay ? 0 : players.findIndex(user => user.id === currentUserId);
  const otherPlayerIndex = currentPlayerIndex === 0 ? 1 : 0;
  const anotherPlayer = isPassAndPlay ? players[otherPlayerIndex] : players.find(user => user.id !== currentUserId);

  const [winningTiles, setWinningTiles] = useState<number[] | undefined>(undefined);
  const [winningDirection, setWinningDirection] = useState<number[] | undefined>(undefined);

  const [hasPlayedOnce, setHasPlayedOnce] = useState<boolean>(false);
  const remainedTime =
    Math.ceil(timer / 1000) - Math.ceil(new Date(game.gameState.lastPlayedTimeAt).getTime() / 1000);

  const isLarge = screenSize === SCREEN_SIZES.LARGE;
  const isSmall = screenSize === SCREEN_SIZES.SMALL;

  const [waitingTiles, setWaitingTiles] = useState<number[]>([]);

  const requestRef = useRef<any>();
  const previousTimeRef = useRef<any>();
  const animate = useCallback((time: number) => {
    if (previousTimeRef.current !== undefined) {
      setTimer(new Date().getTime());
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  }, []);

  const onHideResultPopUp = useCallback((value: boolean) => {
    if (!value) {
      refetchCurrentUserData();
    }
    setResultPopupOpen(value);
  }, [refetchCurrentUserData]);

  const restartTimer = () => {
    setTimer(new Date().getTime());
  };

  const handlePlayAgainPress = () => {
    refetchCurrentUserData();
    setExitPopupClose();
    setResultPopupOpen(false);
    setTimeout(() => {
      if (gameMode.current === GameMode.PRACTICE) {
        navigation.reset({
          index: 1,
          routes: [
            { name: 'Lobby' },
            {
              name: 'PlayWithAI',
              params: {
                userName: players[currentPlayerIndex].name,
                difficulty: route.params.difficulty,
                isPlayAgain: true,
                gameType,
              },
            },
          ],
        });
      } else {
        navigation.reset({
          index: 1,
          routes: [
            { name: 'Lobby' },
            {
              name: 'PlayOnline',
              params: {
                userName: players[currentPlayerIndex].name,
                isPlayAgain: true,
                gameType,
              },
            },
          ],
        });
      }
    }, 500);
  };

  const handleBackToLobbyPress = useCallback(() => {
    leaveGame({ variables: { gameId: game.id } }).then(() => {
      refetchCurrentUserData();
    });
    setExitPopupClose();
    setResultPopupOpen(false);
    setTimeout(() => {
      navigation.reset({
        index: 0,
        routes: [{ name: 'Lobby' }],
      });
    }, 500);
  }, [game.id, leaveGame, navigation, refetchCurrentUserData, setExitPopupClose]);

  const handleClaimTile = useCallback((leftOperand: OPERAND_TYPE, rightOperand: OPERAND_TYPE, tileId?: number) => {
    const currentGame = cloneDeep(game);

    setGame(oldGame => {
      const updatedGame = cloneDeep(oldGame);
      const claimedTileValue = performOperation({ leftOperand, rightOperand, gameType });
      updatedGame.gameState.leftOperand = leftOperand;
      updatedGame.gameState.rightOperand = rightOperand;
      updatedGame.gameState.turn = anotherPlayer?.id ?? updatedGame.gameState.turn;
      updatedGame.gameState.tiles = updatedGame.gameState.tiles.map(
        tile => (tileId ? tile.id === tileId : isEqual(claimedTileValue, tile.value) && (isPassAndPlay ? true : tile.claimedBy !== anotherPlayer?.id))
          ? ({ ...tile, claimedBy: currentUserId, claimedByUserName: isPassAndPlay ? updatedGame.gameState.userNameTurn : undefined }) : tile
      );
      return updatedGame;
    });

    setWaitingTiles([]);

    claimGameTile({
      variables: {
        leftOperand: `${leftOperand.a}_${leftOperand.x}_${leftOperand.b}`,
        rightOperand: `${rightOperand.a}_${rightOperand.x}_${rightOperand.b}`,
        gameId: game.id,
        tileId: tileId ?? undefined,
      },
    }).then(({ data }) => data && setGame(data.claimGameTileV2))
      .catch((error) => {
        if (error.message === 'Game not in progress.') {
          setIsError(true);
          setResultPopupOpen(true);
        } else {
          console.error('Error occurred while claim game tile', currentGame);
          setGame(currentGame);
          setFirstSlider(currentGame.gameState.leftOperand);
          setSecondSlider(currentGame.gameState.rightOperand);
          getLatestUpdate();
        }
      });
  }, [anotherPlayer?.id, claimGameTile, currentUserId, game, gameType, getLatestUpdate, isPassAndPlay]);

  const handleTilePress = (tileId: number) => {
    if (waitingTiles.length > 1) {
      handleClaimTile(firstSlider, secondSlider, tileId);
    }
  };

  const handleSliderChange = useCallback(
    (sliderIdx: number | null, leftOperand: OPERAND_TYPE, rightOperand: OPERAND_TYPE) => {
      setHasPlayedOnce(true);
      setChangedSliderIndex(sliderIdx);
      sliderIdx === 0 ? setFirstSlider(leftOperand) : setSecondSlider(rightOperand);
      if (GAME_TYPES_CONFIG[gameType].hasDuplicate) {
        const claimedTileValue = performOperation({
          leftOperand,
          rightOperand,
          gameType,
        });
        let waitingToSelectTiles: number[] = [];
        for (const tile of gameTiles) {
          if (isEqual(tile.value, claimedTileValue) && tile.claimedBy === '-1') {
            waitingToSelectTiles.push(tile.id);
          }
        }
        if (waitingToSelectTiles.length > 1) {
          setWaitingTiles(waitingToSelectTiles);
        } else {
          if (waitingToSelectTiles.length === 0) {
            handleClaimTile(leftOperand, rightOperand, undefined);
          }
          handleClaimTile(leftOperand, rightOperand, waitingToSelectTiles[0]);
        }
      } else {
        handleClaimTile(leftOperand, rightOperand);
      }
    },
    [gameType, gameTiles, handleClaimTile],
  );

  useEffect(() => {
    if (gameEventSubscription && gameEventSubscription.gameEvent.game.id !== game.id) {
      console.warn('ignore events from another game', gameEventSubscription?.gameEvent.game.id, game.id);
      return;
    }

    const showUpdate = (gameUpdate: GameType) => {
      if (gameUpdate.id === game.id) {
        if (gameUpdate.status === GameStatus.FINISHED) {
          if (gameUpdate.gameState.winningPlayerId === currentUserId) {
            setCurrentPlayerWinner(true);
            setResultPopupOpen(true);
          } else if (gameUpdate.gameState.winningPlayerId === '-1') {
            setGameDraw(true);
            setResultPopupOpen(true);
          } else {
            setCurrentPlayerWinner(false);
            setResultPopupOpen(true);
          }
        }
      }
    };

    if (gameEventSubscription?.gameEvent.eventType === EventTypeGameEventType.ON_CLAIM_TILE) {
      const gameUpdate = gameEventSubscription?.gameEvent.game;
      if (gameEventSubscription.gameEvent.game.gameState.winningDirection.length > 0) {
        setWinningDirection(gameEventSubscription.gameEvent.game.gameState.winningDirection);
      }
      if (gameEventSubscription.gameEvent.game.gameState.winningTiles.length > 0) {
        setWinningTiles(gameEventSubscription.gameEvent.game.gameState.winningTiles);
      }
      setGame(gameEventSubscription.gameEvent.game);
      setFirstSlider(gameUpdate.gameState.leftOperand);
      setSecondSlider(gameUpdate.gameState.rightOperand);
      setTimeout(() => {
        showUpdate(gameUpdate);
      }, 1500);
      restartTimer();
    }
    if (gameEventSubscription?.gameEvent.eventType === EventTypeGameEventType.ON_CHANGE_TURN) {
      const gameUpdate = gameEventSubscription?.gameEvent.game;
      setGame(gameEventSubscription.gameEvent.game);
      showUpdate(gameUpdate);
    }
    if (gameEventSubscription?.gameEvent.eventType === EventTypeGameEventType.ON_USER_LEFT) {
      showUpdate(gameEventSubscription?.gameEvent.game);
    }
  }, [currentUserId, game.id, gameEventSubscription, navigation]);

  useEffect(() => {
    if (currentUserId) {
      if (turn === currentUserId) {
        setChangedSliderIndex(null);
      }
    }
  }, [turn, currentUserId]);

  const handleTimerEnds = useMemo(
    () => () => {
      if (anotherPlayer?.id && game.status !== GameStatus.FINISHED) {
        changeTurn({
          variables: {
            turn: turn === currentUserId ? anotherPlayer.id : currentUserId,
            gameId: game.id,
          },
        });
      }
    },
    [anotherPlayer?.id, changeTurn, currentUserId, game.id, game.status, turn],
  );

  useEffect(() => {
    if (remainedTime > 59 && gameMode.current === GameMode.PLAY_ONLINE) {
      cancelAnimationFrame(requestRef.current);
      setTimeout(() => {
        handleTimerEnds();
      }, 1500);
    }
  }, [handleTimerEnds, remainedTime, timer]);

  useEffect(() => {
    if (gameMode.current === GameMode.PLAY_ONLINE) {
      restartTimer();
      requestRef.current = requestAnimationFrame(animate);
    }
    return () => cancelAnimationFrame(requestRef.current);
  }, [animate, turn]);

  useEffect(() => {
    if (game.id) {
      logAnalytics('game_started', {
        gameId: game.id,
        platform: Platform.OS,
        mode: gameMode.current,
      });
    }
  }, [game.id]);

  useEffect(() => {
    const subscription: any = AppState.addEventListener('change', nextAppState => {
      if (nextAppState === 'active') {
        getLatestUpdate();
      }
    });

    return () => {
      subscription.remove();
    };
  }, [game.id, getLatestUpdate]);

  useEffect(() => {
    if (isOnline) {
      setTimeout(() => {
        getLatestUpdate();
      }, 1000);
    }
  }, [getLatestUpdate, isOnline]);

  useEffect(() => {
    if (latestGameData?.getLatestUpdate && currentUserId) {
      setGame(latestGameData.getLatestUpdate.game);
      setFirstSlider(latestGameData.getLatestUpdate.game.gameState.leftOperand);
      setSecondSlider(latestGameData.getLatestUpdate.game.gameState.rightOperand);
      if (latestGameData?.getLatestUpdate.game.status === GameStatus.FINISHED) {
        cancelAnimationFrame(requestRef.current);
        if (latestGameData?.getLatestUpdate.game.gameState.winningPlayerId === currentUserId) {
          setCurrentPlayerWinner(true);
          setResultPopupOpen(true);
        } else if (latestGameData?.getLatestUpdate.game.gameState.winningPlayerId === '-1') {
          setGameDraw(true);
          setResultPopupOpen(true);
        } else {
          setCurrentPlayerWinner(false);
          setResultPopupOpen(true);
        }
      }
    }
  }, [currentUserId, latestGameData]);

  useEffect(() => {
    if (currentPlayerIndex === -1) {
      navigation.replace('Lobby');
    }
  }, [currentPlayerIndex, navigation]);

  if (currentPlayerIndex === -1) {
    return (<></>);
  }

  return (
    <>
      <StatusBar backgroundColor={COLORS.white} />
      <ComponentWrapper
        backgroundColor="#FFFCF3"
        hasBackground={isLarge ? true : false}
        horizontalBackground
        hasInset
        scrollEnabled={false}
        style={{ paddingHorizontal: 0 }}
      >
        <View style={styles.topContainer}>
          <Turn
            players={players}
            turn={turn}
            isPassAndPlay={isPassAndPlay}
            userNameTurn={userNameTurn}
            currentUserId={currentUserId}
            currentPlayerUserName={players[currentPlayerIndex].name}
            currentPlayerIndex={currentPlayerIndex}
            otherPlayerIndex={otherPlayerIndex}
            setExitPopupOpen={setExitPopupOpen}
            setHintPopupOpen={setHintPopupOpen}
            time={remainedTime < 0 ? 60 : remainedTime > 60 ? 60 : 60 - remainedTime}
            hideTimer={gameMode.current !== GameMode.PLAY_ONLINE}
            isPractice={gameMode.current === GameMode.PRACTICE}
            gameType={gameType}
          />
          <Divider empty height={isLarge ? 20 : 8} />
          <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <View style={{ position: 'relative', marginTop: !isSmall && !isMobile ? -30 : 0 }}>
              <TilesView
                onPress={handleTilePress}
                waitingTiles={waitingTiles}
                gameType={gameType}
                gameTiles={gameTiles}
                currentUserId={currentUserId}
                isPassAndPlay={isPassAndPlay}
                currentUserName={players[currentPlayerIndex].name}
              />
              <WinningBee gameType={gameType} winningDirection={winningDirection} winningTiles={winningTiles} />
            </View>
          </View>
        </View>
        <Controls
          turn={turn}
          currentPlayerIndex={currentPlayerIndex}
          currentUserId={currentUserId}
          secondSlider={secondSlider}
          firstSlider={firstSlider}
          handleSliderChange={handleSliderChange}
          setExitPopupOpen={setExitPopupOpen}
          changedSliderIndex={changedSliderIndex}
          time={remainedTime < 0 ? 60 : remainedTime > 60 ? 60 : 60 - remainedTime}
          showHands={!hasPlayedOnce && turn === currentUserId}
          hideTimer={gameMode.current !== GameMode.PLAY_ONLINE}
          gameType={gameType}
          isWaitingTiles={waitingTiles.length > 0}
        />
        <Divider empty height={16} />
        {
          GAME_TYPES_CONFIG[gameType].hasWorkingBar && <WorkingBar gameType={gameType} />
        }
        <ResultPopup
          isWinner={isCurrentPlayerWinner}
          isDraw={isGameDraw}
          isError={isError}
          isPassAndPlay={isPassAndPlay}
          winnerName={game.gameState.winningPlayerUserName}
          onPressAction={handleBackToLobbyPress}
          visible={isResultPopupOpen}
          setVisible={onHideResultPopUp}
          anotherPlayerLeft={
            (gameEventSubscription?.gameEvent.eventType === EventTypeGameEventType.ON_USER_LEFT) && !isError
          }
          handlePlayAgainPress={
            (gameMode.current === GameMode.PLAY_ONLINE || gameMode.current === GameMode.PRACTICE) && !isError
              ? handlePlayAgainPress : undefined
          }
          points={gameEventSubscription?.gameEvent?.earnedPoints ? Number(gameEventSubscription?.gameEvent?.earnedPoints) : undefined}
        />
        <ExitPopup
          onYesPress={handleBackToLobbyPress}
          onNoPress={setExitPopupClose}
          visible={isExitPopupVisible}
          setVisible={setExitPopupOpen}
        />
        <ReconnectPopup visible={!isOnline} setVisible={() => { }} />
        {
          gameMode.current === GameMode.PRACTICE ?
            <HintPopup
              gameId={game.id}
              visible={isHintPopupVisible}
              setVisible={setHintPopupClose}
            />
            : null
        }
      </ComponentWrapper>
    </>
  );
};

export { Game };

const styles = StyleSheet.create({
  topContainer: {
    paddingHorizontal: 12,
    position: 'relative',
    width: '100%',
    flexDirection: 'column',
  },
  gameInfo: {
    height: 36,
    width: 148,
    borderColor: applyTransparency('#FFFFFF', 0.4),
    borderWidth: 3,
    borderRadius: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  infoText: {
    fontSize: 12,
    fontFamily: 'Secular One',
    color: 'white',
  },
  infoContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  exitButton: { borderWidth: 3 },
  exitIcon: { marginTop: 4, marginRight: 2 },
});
