import React, { memo, useCallback } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { SCREEN_SIZES, useScreenSize } from '../../utils/dimsHooks';
import { GAME_TYPES_CONFIG, GAME_TYPES_TYPE, OPERAND_TYPE } from '../../constants/constants';
import isEqual from 'lodash/isEqual';
import { renderValue } from '../screens/GameScreen/utils';
import { isMobile } from '@mightybyte/rnw.utils.device-info';

interface ILargeSliderProps {
  colors: string[];
  gameType: GAME_TYPES_TYPE;
  value: OPERAND_TYPE;
  setValue: (value: OPERAND_TYPE) => void;
}
const whiteLinearGradient = ['#FFEEC6', '#FFE5A8'];

export const LargeSlider = memo((props: ILargeSliderProps) => {
  const screenSize = useScreenSize();
  const onPress = useCallback((val: OPERAND_TYPE) => () => props.setValue(val), [props]);

  const isLarge = screenSize === SCREEN_SIZES.LARGE;
  const gradientStyle = useCallback(
    () => [styles.cell, !isLarge && !isMobile ? styles.mediumCell : null],
    [isLarge],
  );

  return (
    <View style={!isLarge && !isMobile ? styles.mediumContainer : styles.container}>
      {GAME_TYPES_CONFIG[props.gameType].operands.map((el, index: number) => (
        <Pressable
          style={[
            {
              borderBottomColor: '#A9AAAC',
              borderBottomWidth: 3,
              marginHorizontal: !isLarge ? 1 : 3,
              marginVertical: !isLarge ? 3 : 6,
              borderRadius: 5,
            },
            isEqual(el, props.value) ? styles.activeCell : null,
          ]}
          key={index}
          onPress={onPress(el)}
        >
          <LinearGradient
            style={gradientStyle()}
            colors={isEqual(el, props.value) ? props.colors : whiteLinearGradient}
          >
            <Text
              maxFontSizeMultiplier={1}
              style={[styles.text, isEqual(el, props.value) ? styles.activeText : null]}
            >
              {renderValue(el)}
            </Text>
          </LinearGradient>
        </Pressable>
      ))}
      <View style={[styles.fakeCell, {
        marginHorizontal: !isLarge ? 1 : 3,
        marginVertical: !isLarge ? 3 : 6,
      }, !isLarge && !isMobile ? styles.mediumCell : null]} />
      <View style={[styles.fakeCell, {
        marginHorizontal: !isLarge ? 1 : 3,
        marginVertical: !isLarge ? 3 : 6,
      }, !isLarge && !isMobile ? styles.mediumCell : null]} />
      <View style={[styles.fakeCell, {
        marginHorizontal: !isLarge ? 1 : 3,
        marginVertical: !isLarge ? 3 : 6,
      }, !isLarge && !isMobile ? styles.mediumCell : null]} />
    </View>
  );
});

LargeSlider.displayName = 'LargeSlider';

const styles = StyleSheet.create({
  container: {
    width: 162,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  mediumContainer: {
    width: 120,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  cell: {
    width: 32,
    height: 45,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    borderBottomWidth: 2,
    borderColor: '#A9AAAC',
    marginBottom: -2,
    paddingTop: 4,
  },
  mediumCell: {
    width: 26,
    height: 30,
    paddingTop: 2,
  },
  activeCell: {
    borderBottomWidth: 0,
  },
  text: {
    fontFamily: 'Secular One',
    fontSize: 23,
    color: '#6A6A6A',
  },
  activeText: {
    color: '#000000',
  },
  fakeCell: {
    width: 32,
    height: 0,
  },
  fakeMediumCell: {
    width: 26,
    height: 0,
  },
});
