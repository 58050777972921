import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const mutation = gql`
  mutation changeTurn($gameId: String!, $turn: String!) {
    changeTurn(gameId: $gameId, turn: $turn)
  }
`;

export interface RequestType {
  gameId: string;
  turn: string;
}

export interface MutationType {
  changeTurn: boolean;
}

export function useChangeTurnMutation(options?: MutationHookOptions<MutationType, RequestType>) {
  return useMutation<MutationType, RequestType>(mutation, options);
}
