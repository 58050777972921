// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[text-mode="fit"] {
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    line-break: anywhere;
    word-break: break-all;
}
`, "",{"version":3,"sources":["webpack://./src/css/TextStyles.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,uBAAuB;IACvB,eAAe;IACf,oBAAoB;IACpB,qBAAqB;AACzB","sourcesContent":["[text-mode=\"fit\"] {\r\n    white-space: pre-wrap;\r\n    overflow: hidden;\r\n    text-overflow: ellipsis;\r\n    max-width: 100%;\r\n    line-break: anywhere;\r\n    word-break: break-all;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
