import { GAME_TYPES_CONFIG, GAME_TYPES_TYPE } from '../constants/constants';

export const findPositionByIndex = (tileIndex: number, gameType: GAME_TYPES_TYPE): { x: number, y: number } => {
  let x = tileIndex;
  let y = 0;
  for (let index = 0; index < GAME_TYPES_CONFIG[gameType].rowsCount.length; index++) {
    if (x >= GAME_TYPES_CONFIG[gameType].rowsCount[index]) {
      x = x - GAME_TYPES_CONFIG[gameType].rowsCount[index];
      y = y + 1;
    } else {

      break;
    }
  }
  return { x, y };
};
