import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const mutation = gql`
  mutation endWaitingGame($gameId: String!) {
    endWaitingGame(gameId: $gameId)
  }
`;

export interface RequestType {
  gameId: string;
}

export interface MutationType {
  endWaitingGame: boolean;
}

export function useEndWaitingGameMutation(options?: MutationHookOptions<MutationType, RequestType>) {
  return useMutation<MutationType, RequestType>(mutation, options);
}
