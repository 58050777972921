import React, { useRef } from 'react';
import { Animated, Easing, Pressable, StyleSheet, Text, TouchableOpacity, View, useWindowDimensions } from 'react-native';
import { ChevronIcon } from '../../resources/svgComponents/ChevronIcon';
import { CloseBarIcon } from '../../resources/svgComponents/CloseBarIcon';
import { WorkingBarSlider } from './WorkingBarSlider';
import { PencilIcon } from '../../resources/svgComponents/Pencilcon';
import { isMobile } from '@mightybyte/rnw.utils.device-info';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import { GAME_TYPES_CONFIG, GAME_TYPES_TYPE } from '../../constants/constants';

export const WorkingBar = React.memo((props: { gameType: GAME_TYPES_TYPE }) => {
  const moveLabelAnim = useRef(new Animated.Value(0)).current;
  const fadeLabelAnim = useRef(new Animated.Value(1)).current;
  const moveBarAnim = useRef(new Animated.Value(0)).current;
  const { width } = useWindowDimensions();

  const openAnimations = Animated.parallel([
    Animated.parallel([
      Animated.timing(fadeLabelAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true,
      }),
      Animated.timing(moveLabelAnim, {
        toValue: -width,
        duration: 500,
        useNativeDriver: true,
      }),
    ]),
    Animated.timing(moveBarAnim, {
      delay: 100,
      toValue: width,
      duration: 500,
      useNativeDriver: true,
      easing: Easing.out(Easing.ease),
    }),
  ]);

  const closeAnimation = Animated.parallel([
    Animated.timing(moveBarAnim, {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
      easing: Easing.out(Easing.ease),
    }),
    Animated.timing(moveLabelAnim, {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
    }),
    Animated.timing(fadeLabelAnim, {
      delay: 100,
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }),
  ]);

  const handleClose = () => {
    closeAnimation.start();
  };

  return (
    <>
      <View style={styles.mobileRow}>
        <Animated.View style={[styles.labelContainer, { opacity: fadeLabelAnim, transform: [{ translateX: moveLabelAnim }] }]}>
          <TouchableOpacity style={styles.labelPressable} onPress={() => openAnimations.start()}>
            <PencilIcon />
            <ChevronIcon />
          </TouchableOpacity>
        </Animated.View>
        {isMobile ?
          <Animated.View style={[styles.barContainer, { width: width, left: -width, transform: [{ translateX: moveBarAnim }] }]}>
            <View style={styles.barTextContainer}>
              <Text style={styles.barText}>Working bar</Text>
              <Pressable onPress={handleClose}>
                <CloseBarIcon />
              </Pressable>
            </View>
            <WorkingBarSlider count={GAME_TYPES_CONFIG[props.gameType].operands.length} />
          </Animated.View>
          :
          <Animated.View style={[styles.barContainer, { width: width, left: -width, transform: [{ translateX: moveBarAnim }] }]}>
            <WorkingBarSlider count={GAME_TYPES_CONFIG[props.gameType].operands.length} handleClose={handleClose} />
          </Animated.View>
        }
      </View>
    </>
  );
});

WorkingBar.displayName = 'WorkingBar';

const styles = StyleSheet.create({
  mobileRow: {
    width: '100%',
    position: 'relative',
    height: 200,
  },
  labelContainer: {
    left: -4,
    position: 'absolute',
    backgroundColor: '#FFCE86',
    height: 40,
    width: 60,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
  },
  labelPressable: {
    paddingLeft: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    width: 60,
  },
  labelText: {
    fontSize: 11,
  },
  barContainer: {
    position: 'absolute',
    marginTop: -8,
    ...mbPlatformStyle({
      mobile: {
        paddingHorizontal: 12,
        maxWidth: 400,
      },
      web: {
        justifyContent: 'center',
        alignItems: 'center',
      },
    }),
  },
  barTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...mbPlatformStyle({
      web: {
        paddingHorizontal: 12,
        width: '100%',
        maxWidth: 480,
      },
    }),
  },
  barText: {
    fontFamily: 'Inter',
    fontWeight: '400',
    color: '#000000',
    fontSize: 13,
  },
});
