import React, { useState } from 'react';
import { StyleSheet, View, Text, Pressable, Dimensions } from 'react-native';
import { WorkingBarSliderTooltip } from '../../resources/svgComponents/WorkingBarSliderTooltip';
import { isMobile } from '@mightybyte/rnw.utils.device-info';
import { CloseBarIcon } from '../../resources/svgComponents/CloseBarIcon';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';

interface IProps {
  handleClose?: () => void
  count: number
}

const WorkingBarSlider = ({ handleClose, count }: IProps) => {
  const [values, setValues] = useState([false, false, false, false, false, false, false, false, false, false, false, false]);
  const cellWidth = Math.min((Dimensions.get('window').width - 40) / 12, 30);

  const handlePress = (index: number) => {
    let array = [...values];
    array[index] = !array[index];
    setValues(array);
  };
  return (
    <View style={styles.container}>
      <View style={[styles.borders, { borderColor: '#FFCF52' }]}>
        <View style={styles.stepsContainer}>
          {values.map((el, index) => {
            if (index + 1 <= count) {
              return (
                <Pressable key={`step_${index}`} style={styles.stepContainer} onPress={() => handlePress(index)}>
                  <View style={styles.leftTooltipContainer}>
                    <WorkingBarSliderTooltip
                      selected={el}
                      colored={true}
                      cellSize={cellWidth}
                      containerStyle={[styles.leftTooltipShape]}
                    />
                    <Text maxFontSizeMultiplier={1} style={styles.leftTooltipText}>
                      {index + 1}
                    </Text>
                  </View>
                </Pressable>
              );
            }
            return null;
          })}
        </View>
      </View>
      {
        !isMobile &&
        <Pressable style={styles.barTextContainer} onPress={handleClose}>
          <CloseBarIcon />
          <Text style={styles.barText}>Close</Text>
        </Pressable>
      }
    </View>
  );
};

export { WorkingBarSlider };

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    position: 'relative',
    height: 32,
    minHeight: 32,
    zIndex: 10,
    ...mbPlatformStyle({
      web: {
        maxWidth: 480,
      },
    }),
  },
  multiSliderContainer: {
    height: 28,
  },
  stepContainer: { height: 32, justifyContent: 'center', alignItems: 'center' },
  leftTooltipPressableContainer: {
    position: 'relative',
  },
  leftTooltipContainer: {
    height: 28,
    minHeight: 28,
    zIndex: 10,
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftTooltipText: {
    fontFamily: 'Inter',
    color: 'black',
    zIndex: 10,
    fontWeight: '600',
    fontSize: 12,
    textAlign: 'center',
    width: Math.min((Dimensions.get('window').width - 40) / 12, 30),
    marginTop: 3,
    marginLeft: -0.5,
  },
  leftTooltipShape: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  stepsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1,
    width: Math.min((Dimensions.get('window').width - 24), 480),
    ...mbPlatformStyle({
      mobile: {
        marginLeft: -20,
      },
      web: {
        marginLeft: -10,
      },
    }),
  },
  steps: {
    color: '#898989',
    fontSize: 11,
    minWidth: 28,
    width: 28,
    textAlign: 'center',
  },
  borders: {
    top: 0,
    left: 20,
    position: 'absolute',
    ...mbPlatformStyle({
      mobile: {
        width: Dimensions.get('window').width - 40,
      },
      web: {
        width: 480,
      },
    }),
    height: 32,
    minHeight: 32,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 20,
  },
  barTextContainer: {
    position: 'absolute',
    right: -110,
    top: 7,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  barText: {
    paddingLeft: 8,
  },
});
