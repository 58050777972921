import React, { useContext, useState } from 'react';
import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { getRootNavRef } from '../navigators/RootNavigator';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CurrentUserType } from '../graphql/getCurrentUser';

export const signedInContextGlobalFunction: {
  signOut?: (params: ISignOut) => void;
  getSignedInStatus?: () => SIGNED_IN_STATUS,
} = {};

export interface ISignOut {
  skipSignOutRequest?: boolean;
  showExpiredError?: boolean;
  makeRefreshTokenCall?: boolean;
}

export const enum SIGNED_IN_STATUS {
  loading = 'loading',
  signedIn = 'signedIn',
  signedOut = 'signedOut',
}

export interface ISignedInContextType {
  isSignedIn: boolean;
  signedInStatus: SIGNED_IN_STATUS;
  setSignedInStatus: (
    isSignedIn: SIGNED_IN_STATUS
  ) => void;
  user: CurrentUserType | null;
  setUser: (value: CurrentUserType) => void;
  signOut: (params?: ISignOut) => Promise<void>;
}

const SignedInContext = React.createContext<ISignedInContextType>({
  isSignedIn: false,
  signedInStatus: SIGNED_IN_STATUS.loading,
  setSignedInStatus: () => { },
  signOut: async () => { },
  user: null,
  setUser: () => { },
});

const SignedInStatusProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [signedInStatus, setSignedInStatusInternal] =
    useState<SIGNED_IN_STATUS>(SIGNED_IN_STATUS.loading);
  const [user, setUser] = useState<any>();

  const signOut = async (params?: ISignOut) => {
    console.log('Params when sign out was called', params);

    if (signedInStatus !== SIGNED_IN_STATUS.signedOut) {
      setSignedInStatusInternal(SIGNED_IN_STATUS.signedOut);
      setUser(null);
      await MB_accessTokenUtils.removeAccessToken();
      await AsyncStorage.removeItem('playing-name');

      getRootNavRef()?.reset({
        index: 0,
        routes: [{ name: 'Lobby' }],
      });
    }
  };

  const setSignedInStatus = (
    isSignedIn: SIGNED_IN_STATUS
  ) => {
    setSignedInStatusInternal(isSignedIn);
  };

  signedInContextGlobalFunction.signOut = signOut;
  signedInContextGlobalFunction.getSignedInStatus = () => signedInStatus;

  return (
    <SignedInContext.Provider
      value={{
        isSignedIn: signedInStatus === SIGNED_IN_STATUS.signedIn,
        signedInStatus: signedInStatus,
        setSignedInStatus: setSignedInStatus,
        signOut,
        user,
        setUser: setUser,
      }}
    >
      {children}
    </SignedInContext.Provider>
  );
};

function useSignedInContext() {
  const context = useContext(SignedInContext);
  if (context === undefined) {
    throw new Error('useSignedInContext must be used within a SignedInStatusContextProvider');
  }

  return context;
}

export { SignedInStatusProvider, SignedInContext, useSignedInContext };
