import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Popup } from './Popup';
import { MenuButton } from './MenuButton';
import { Divider } from './Divider';
import { applyTransparency } from '../../constants/colors';
import { Input } from './Input';

interface IPopupProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  onChangePress: (hiveName: string) => void;
  currentName: string;
  loading: boolean;
}
const ChangeHiveNamePopup = ({ visible, setVisible, currentName, onChangePress, loading }: IPopupProps) => {
  const [hiveName, setHiveName] = useState('');

  useEffect(() => {
    setHiveName(currentName);
  }, [currentName, visible]);

  return (
    <Popup
      height={270}
      title="Edit Hive Name"
      headerTextStyle={{ fontSize: 18 }}
      visible={visible}
      setVisible={setVisible}
      closeOnBackdropPress={true}
    >
      <View style={styles.container}>
        <Divider empty height={32} />
        <Text style={styles.title}>Hive's Name</Text>
        <Divider empty height={12} />
        <Input
          value={hiveName}
          setValue={(value) => setHiveName(value)}
          style={{ width: 290 }}
          textStyle={{ fontSize: 18 }}
          placeholder={currentName}
          onSubmitEditing={() => onChangePress(hiveName)}
        />
        <Divider empty height={30} />
        <MenuButton
          height={58}
          width={290}
          onPress={() => onChangePress(hiveName)}
          text="Save changes"
          colors={['#90DF75', '#62B655']}
          textStyle={styles.buttonTextStyle}
          loading={loading}
          disabled={loading || hiveName.length === 0}
        />
      </View>
    </Popup>
  );
};

export { ChangeHiveNamePopup };

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    fontFamily: 'Secular One',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
    fontSize: 17,
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 20,
  },
  buttonTextStyle: {
    fontFamily: 'Secular One',
    color: '#fff',
    fontSize: 20,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
});
