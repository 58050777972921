import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const ExitIcon = React.memo(
  (props: {
    size?: number;
    color?: string;
    containerStyle?: StyleProp<ViewStyle>;
  }) => {
    return (
      <View style={props.containerStyle}>
        <Svg
          width={props.size ?? 16 }
          height={props.size ?? 16}
          viewBox="0 0 16 16"
          fill="none"
        >
            <Path
                d="M10.262 4.208v-.7A2.764 2.764 0 007.5.745H3.842A2.764 2.764 0 001.08 3.508v8.348a2.764 2.764 0 002.763 2.764h3.664a2.756 2.756 0 002.756-2.756v-.707M15.357 7.682H6.326M13.161 5.496l2.196 2.186-2.196 2.187"
                stroke={props.color ?? '#FFFFFF'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
      </View>
    );
  },
);

export default ExitIcon;
