import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const EyeOutlinedIcon = React.memo(
    (props: {
        size?: number;
        color?: string;
        containerStyle?: StyleProp<ViewStyle>;
    }) => {
        const iconColor = props.color ?? '#FFFFFF';
        return (
            <View style={props.containerStyle}>
                <Svg
                    width={props.size ?? 12 }
                    height={props.size ? props.size * (11 / 12) : 11}
                    viewBox="0 0 12 11"
                    fill="none"
                >
                    <Path
                        clipRule="evenodd"
                        d="M7.844 5.531a1.844 1.844 0 11-3.688 0 1.844 1.844 0 013.688 0z"
                        stroke={iconColor}
                        strokeWidth={0.8}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <Path
                        clipRule="evenodd"
                        d="M5.999 9.79c2.221 0 4.253-1.597 5.397-4.26C10.252 2.87 8.22 1.272 5.999 1.272H6c-2.221 0-4.253 1.598-5.397 4.26C1.748 8.193 3.78 9.79 6.001 9.79H6z"
                        stroke={iconColor}
                        strokeWidth={0.8}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </Svg>
            </View>
        );
    },
);

export default EyeOutlinedIcon;
