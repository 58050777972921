import React, { useMemo } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import {COLORS} from '../../../../constants/colors';
import DisableHeaderIcon from '../../../../resources/svgComponents/DisableHeaderIcon';
import { USER_TYPES } from '../../../../typesInterfacesEnums/enums';
import { MB_Button } from '../../../../mightyByteLibraries/MB_Button';

const DisableUser = ({id, closeAction, userRole, userName}: {
    id: string,
    closeAction: () => void,
    userRole: USER_TYPES.student | USER_TYPES.teacher,
    userName: string,
}) => {

    const disableAction = () => {
        console.log('disable user', id);
    };

    const note = useMemo(() => {
        if (userRole === USER_TYPES.student) {
            return (
                <Text style={{textAlign: 'center'}}>
                <Text style={styles.text}>{'Are you sure you want to disable the student '}</Text>
                <Text style={[styles.text, {fontWeight: '600'}]}>{userName}?</Text>
            </Text>
            );
        }
        return (
            <Text style={{textAlign: 'center'}}>
                <Text style={styles.text}>{'Are you sure you want to disable the teacher '}</Text>
                <Text style={[styles.text, {fontWeight: '600'}]}>{userName}?</Text>
                <Text style={styles.text}>{' Note that this will also disable their subscriptions.'}</Text>
            </Text>
        );
    }, [userRole, userName]);

    return (
        <View style={styles.container}>
            <DisableHeaderIcon/>
            <View style={styles.headerWrapper}>
            <Text style={styles.header}>{`Disable ${userRole === USER_TYPES.student ? 'Student' : 'Teacher'}`}</Text>
            </View>
            {note}
            <View style={styles.buttonWrapper}>
                <MB_Button title={'Cancel'}
                           onPress={closeAction}
                           style={{
                               width: 177,
                               backgroundColor: 'transparent',
                               borderColor: COLORS.pumpkinYellow,
                               borderWidth: 1,
                           }}
                           textStyle={{
                               ...styles.buttonText,
                               color: COLORS.pumpkinYellow,
                           }}/>
                <MB_Button title={'Disable'}
                            onPress={disableAction}
                            style={{
                                width: 177,
                                backgroundColor: COLORS.buttonDanger,
                            }}
                            textStyle={{
                                ...styles.buttonText,
                                color: COLORS.white,
                            }}/>
            </View>

        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: 415,
        maxHeight: 260,
        backgroundColor: COLORS.white,
        borderRadius: 10,
        padding: 24,
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    headerWrapper: {
        marginVertical: 16,
    },
    header: {
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: '600',
        color: COLORS.black,
    },
    noteWrapper: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    text: {
        fontFamily: 'Inter',
        fontSize: 13,
        fontWeight: '500',
        color: COLORS.offBlack,
    },
    buttonWrapper: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 24,
    },
    buttonText: {
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: '500',
    },
});

export { DisableUser };
