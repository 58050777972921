import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { Divider } from '../../helperComponents/Divider';
import { Input } from '../../helperComponents/Input';
import { applyTransparency } from '../../../constants/colors';
import { MenuButton } from '../../helperComponents/MenuButton';
import { BackButton } from '../../helperComponents/BackButton';
import { UniversalProps } from '../../../typesInterfacesEnums/componentProps';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import { useSignedInContext } from '../../../context/SignedInContext';
import { ErrorPopup } from '../../helperComponents/ErrorPopup';
import { ApolloError } from '@apollo/client';
import { useRequestResetPasswordCodeMutation } from './useRequestForgetPasswordCodeMutation.gql';
import { UsernameIcon } from '../../../resources/svgComponents/UsernameIcon';
import { EMAIL_PATTERN } from '../../../constants/email';
import { getErrorText } from '../../../utils/errors';

const RequestForgetPasswordCode = ({ navigation }: UniversalProps) => {
  const { setSignedInStatus } = useSignedInContext();
  const [email, setEmail] = useState('');
  const [inputError, setInputError] = useState(false);

  const [requestCode, { data: requestCodeData, loading }] = useRequestResetPasswordCodeMutation({
    variables: { email: email },
  });
  const [hasError, setHasError] = useState({
    visible: false,
    popup: false,
    text: '',
  });

  const handleRequestCodeError = (err: ApolloError) => {
    setHasError({ popup: true, visible: true, text: getErrorText(err) });
  };

  useEffect(() => {
    if (requestCodeData) {
      navigation.navigate('VerifyForgetPasswordCode', { email: email });
    }
  }, [requestCodeData, navigation, setSignedInStatus, email]);


  const handleRequestCode = () => {
    setInputError(false);
    if (email.length === 0) {
      setInputError(true);
      setHasError({ popup: true, visible: true, text: 'Email address cannot be empty' });
      return;
    }
    if (!EMAIL_PATTERN.test(email)) {
      setInputError(true);
      setHasError({ popup: true, visible: true, text: 'Email address is not valid' });
      return;
    }
    requestCode().catch((err) => handleRequestCodeError(err));
  };

  return (
    <>
      <ComponentWrapper
        hasInset
        hasScroll={false}
        scrollEnabled={false}
        style={{ paddingHorizontal: 0 }}
      >
        <View style={styles.topContainer}>
          <BackButton onPress={() => navigation.goBack()} text="Back" />
        </View>
        <Text maxFontSizeMultiplier={1} style={styles.title}>
          Forgot password
        </Text>
        <Divider empty height={12} />
        <Text maxFontSizeMultiplier={1} style={styles.subtitle}>
          Enter your email address below and a verification code will be sent to you
        </Text>
        <Divider empty height={40} />
        <Input
          value={email}
          setValue={(value) => setEmail(value)}
          style={{ width: '100%', maxWidth: 320 }}
          textStyle={{ fontSize: 18 }}
          placeholder="Email"
          leftIcon={<UsernameIcon />}
          hasError={inputError}
          onSubmitEditing={handleRequestCode}
        />
        <Divider empty height={40} />
        <MenuButton
          onPress={handleRequestCode}
          disabled={loading}
          loading={loading}
          colors={['#90DF75', '#62B655']}
          text="Send code"
          height={64}
          width={320}
        />
      </ComponentWrapper>
      <ErrorPopup
        isVisible={hasError.popup}
        setVisible={() => setHasError(v => ({ ...v, popup: false }))}
        text={hasError.text}
      />
    </>
  );
};

export { RequestForgetPasswordCode };

const styles = StyleSheet.create({
  image: { width: 180, height: 100 },
  howToPlayText: {
    fontFamily: 'Secular One',
    fontSize: 20,
    color: 'black',
    textDecorationLine: 'underline',
  },
  title: {
    fontFamily: 'Secular One',
    fontSize: 32,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
  },
  subtitle: {
    fontFamily: 'Secular One',
    fontSize: 18,
    width: 320,
    color: '#E89823',
  },
  topContainer: {
    height: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    top: 60,
    ...mbPlatformStyle({
      mobile: {
        paddingHorizontal: 24,
      },
      web: {
        paddingHorizontal: 120,
      },
    }),
  },
  beeIcon: {
    width: 25,
    height: 38,
  },
  forgetContainer: {
    width: 320,
    justifyContent: 'flex-start',
  },
  forgetText: {
    fontFamily: 'Secular One',
    fontSize: 16,
    color: '#E89823',
    textAlign: 'right',
  },
  signUpContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  signUpText: {
    fontFamily: 'Secular One',
    fontSize: 20,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
});
