import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Keyboard, Linking } from 'react-native';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { Divider } from '../../helperComponents/Divider';
import { Input } from '../../helperComponents/Input';
import { applyTransparency } from '../../../constants/colors';
import { MenuButton } from '../../helperComponents/MenuButton';
import { BackButton } from '../../helperComponents/BackButton';
import { UsernameIcon } from '../../../resources/svgComponents/UsernameIcon';
import { PasswordIcon } from '../../../resources/svgComponents/PasswordIcon';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import { ScreenProps } from '../../../typesInterfacesEnums/componentProps';
import { USER_TYPES } from '../../../typesInterfacesEnums/enums';
import { useOpenClose } from '../../../utils/hooks';
import { PasswordPopup } from '../../helperComponents/PasswordPopup';
import { useSignUpMutation } from './useSingUpMutation.gql';
import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { ApolloError } from '@apollo/client';
import { ErrorPopup } from '../../helperComponents/ErrorPopup';
import { useRequestSignUpCodeMutation } from './useRequestSignUpCodeMutation.gql';
import { SIGNED_IN_STATUS, useSignedInContext } from '../../../context/SignedInContext';
import { isMobile } from '@mightybyte/rnw.utils.device-info';
import { DismissKeyboard } from '../../helperComponents/DismissKeyboard';
import { EMAIL_PATTERN } from '../../../constants/email';
import { Checkbox } from '../../helperComponents/Checkbox';
import { envs } from '../../../../env';
import { getErrorText } from '../../../utils/errors';



enum INPUT_TYPES {
  firstName = 'firstName',
  lastName = 'lastName',
  userName = 'userName',
  password = 'password',
  hiveKey = 'hiveKey',
  confirmPassword = 'confirmPassword',
}

const SignUp = ({ navigation, route }: ScreenProps<'SignUp'>) => {
  const { setSignedInStatus } = useSignedInContext();
  const isStudent = route.params.userType === USER_TYPES.student;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userName, setUserName] = useState('');
  const [hiveKey, setHiveKey] = useState('');
  const [password, setPassword] = useState('');
  const [isShowingPassword, setShowingPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isShowingConfirmPassword, setShowingConfirmPassword] = useState(false);
  const [inputError, setInputError] = useState<INPUT_TYPES[]>([]);
  const [tosChecked, setTosChecked] = useState(false);

  const [suggestedPassword, setSuggestedPassword] = useState('');

  const [isPasswordPopupVisible, setPasswordPopupOpen, setPasswordPopupClose] = useOpenClose();
  const [signUp, { data, error, loading }] = useSignUpMutation({
    variables: { firstName: firstName, lastName: lastName, password: password, emailOrUsername: userName, userType: route.params.userType, signUpCode: '', hiveKey: hiveKey.length > 0 ? hiveKey : undefined },
  });
  const [requestCode, { data: requestCodeData, error: requestCodeError, loading: requestCodeLoading }] = useRequestSignUpCodeMutation({
    variables: { email: userName, name: firstName + ' ' + lastName },
  });

  const [hasError, setHasError] = useState({
    visible: false,
    popup: false,
    text: '',
  });

  const handleSignUpError = (err: ApolloError) => {
    setHasError({ popup: true, visible: true, text: getErrorText(err) });
  };

  const areInputsValid = () => {
    setInputError([]);
    let errors = [];
    let errorMessages = [];
    if (firstName.length === 0) {
      errors.push(INPUT_TYPES.firstName);
      errorMessages.push('Please enter First name');
    }
    if (lastName.length === 0) {
      errors.push(INPUT_TYPES.lastName);
      errorMessages.push('Please enter Last name');
    }
    if (isStudent) {
      if (hiveKey.length !== 6) {
        errors.push(INPUT_TYPES.hiveKey);
        errorMessages.push('Please enter a valid hive key');
      }
    }
    if (!isStudent) {
      if (userName.length === 0) {
        errors.push(INPUT_TYPES.userName);
        errorMessages.push('Please enter email address');
      }
      if (!EMAIL_PATTERN.test(userName)) {
        errors.push(INPUT_TYPES.userName);
        errorMessages.push('Email address is not valid');
      }
      if (password.length < 8) {
        errors.push(INPUT_TYPES.password);
        errorMessages.push('Password must be longer than 8');
      }
      if (confirmPassword.length < 8) {
        errors.push(INPUT_TYPES.confirmPassword);
        errorMessages.push('Confirm password is not valid');
      }
      if (password !== confirmPassword) {
        errors.push(INPUT_TYPES.confirmPassword);
        errorMessages.push('Confirm password is wrong');
      }
    }
    if (userName.length === 0) {
      errors.push(INPUT_TYPES.userName);
      errorMessages.push('Please enter User name');
    }
    if (errors.length === 0) {
      return true;
    }
    if (errorMessages.length === 1) {
      setHasError({ visible: true, popup: true, text: errorMessages[0] });
    } else {
      setHasError({ visible: true, popup: true, text: 'You must fill in all of the fields' });
    }
    setInputError(errors);

    return false;
  };

  const handleSignUpPress = () => {
    Keyboard.dismiss();
    if (areInputsValid()) {
      if (!isStudent) {
        requestCode().catch((err) => {
          handleSignUpError(err);
        });
      } else {
        signUp().catch((err) => {
          handleSignUpError(err);
        });
      }
    }
  };

  useEffect(() => {
    if (data) {
      if (isStudent) {
        const setAccessToken = async () => {
          let accessToken = { ...data.signup.accessToken };
          await MB_accessTokenUtils.setAccessToken({ accessToken: accessToken, refreshToken: data.signup.refreshToken });
        };
        setAccessToken();
        setSuggestedPassword(data.signup.password);
        setPasswordPopupOpen();
      }
    }
  }, [data, isStudent, setPasswordPopupOpen]);

  const handlePasswordPopupPress = () => {
    setSignedInStatus(SIGNED_IN_STATUS.signedIn);
    navigation.navigate('Lobby');
  };

  useEffect(() => {
    if (!isStudent) {
      if (requestCodeData) {
        navigation.navigate('VerifyEmail', { firstName: firstName, lastName: lastName, password: password, email: userName });
      }
    }
  }, [requestCodeData, isStudent, navigation, firstName, lastName, password, userName]);

  useEffect(() => {
    if (requestCodeError) {
      handleSignUpError(requestCodeError);
    }
    if (error) {
      handleSignUpError(error);
    }
  }, [requestCodeError, error]);

  return (
    <>
      <DismissKeyboard isScrollable>
        <ComponentWrapper
          hasInset
          hideBee
          hasBackground
          style={{ paddingHorizontal: 0 }}
        >
          <View style={styles.topContainer}>
            <BackButton onPress={() => navigation.reset({ index: 1, routes: [{ name: 'Lobby' }, { name: 'ChooseUserType', params: { userType: route.params.userType } }] })} text="Back" />
          </View>
          <Divider empty height={isMobile ? 120 : 200} />
          <Text maxFontSizeMultiplier={1} style={styles.title}>
            {!isStudent ? 'Admin Sign Up' : 'Student Sign Up'}
          </Text>
          <Divider empty height={16} />
          {isStudent &&
            <>
              <Text maxFontSizeMultiplier={1} style={styles.subTitle}>Make sure you have a Hive Key from your teacher/parent before you start</Text>
              <Divider empty height={28} />
            </>
          }
          <Text
            style={[styles.label, { color: inputError.includes(INPUT_TYPES.firstName) ? '#EB5038' : '#453B3B' }]}
          >
            First name<Text style={{ color: '#EB5038' }}> *</Text>
          </Text>
          <Divider empty height={8} />
          <Input
            value={firstName}
            setValue={(value) => setFirstName(value)}
            leftIcon={<UsernameIcon />}
            style={{ width: 320 }}
            textStyle={{ fontSize: 18 }}
            placeholder="First name"
            hasError={inputError.includes(INPUT_TYPES.firstName)}
            onSubmitEditing={() => handleSignUpPress()}
          />
          <Divider empty height={16} />
          <Text
            style={[styles.label, { color: inputError.includes(INPUT_TYPES.lastName) ? '#EB5038' : '#453B3B' }]}
          >
            Last name<Text style={{ color: '#EB5038' }}> *</Text>
          </Text>
          <Divider empty height={8} />
          <Input
            value={lastName}
            setValue={(value) => setLastName(value)}
            leftIcon={<UsernameIcon />}
            style={{ width: 320 }}
            textStyle={{ fontSize: 18 }}
            placeholder="Last name"
            hasError={inputError.includes(INPUT_TYPES.lastName)}
            onSubmitEditing={() => handleSignUpPress()}
          />
          <Divider empty height={16} />
          <Text
            style={[styles.label, { color: inputError.includes(INPUT_TYPES.userName) ? '#EB5038' : '#453B3B' }]}
          >
            {!isStudent ? 'Email' : 'Username'}<Text style={{ color: '#EB5038' }}> *</Text>
          </Text>
          <Divider empty height={8} />
          <Input
            value={userName}
            setValue={(value) => setUserName(value)}
            leftIcon={<UsernameIcon />}
            style={{ width: 320 }}
            textStyle={{ fontSize: 18 }}
            placeholder={!isStudent ? 'Email' : 'Username'}
            hasError={inputError.includes(INPUT_TYPES.userName)}
            onSubmitEditing={() => handleSignUpPress()}
          />
          {
            isStudent && <>
              <Divider empty height={16} />
              <Text
                style={[styles.label, { color: inputError.includes(INPUT_TYPES.hiveKey) ? '#EB5038' : '#453B3B' }]}
              >
                Hive key<Text style={{ color: '#EB5038' }}> *</Text>
              </Text>
              <Divider empty height={8} />
              <Input
                value={hiveKey}
                setValue={(value) => setHiveKey(value)}
                leftIcon={<UsernameIcon />}
                style={{ width: 320 }}
                textStyle={{ fontSize: 18 }}
                placeholder="Hive key"
                hasError={inputError.includes(INPUT_TYPES.hiveKey)}
                onSubmitEditing={() => handleSignUpPress()}
              />
            </>
          }
          {
            !isStudent && <>
              <Divider empty height={16} />
              <Text
                style={[styles.label, { color: inputError.includes(INPUT_TYPES.password) ? '#EB5038' : '#453B3B' }]}
              >
                Password<Text style={{ color: '#EB5038' }}> *</Text>
              </Text>
              <Divider empty height={8} />
              <Input
                showPassword={isShowingPassword}
                handleShowPassClick={() => setShowingPassword((v) => !v)}
                value={password}
                setValue={(value) => setPassword(value)}
                leftIcon={<PasswordIcon />}
                isPassword
                style={{ width: 320 }}
                textStyle={{ fontSize: 18 }}
                placeholder="Password"
                hasError={inputError.includes(INPUT_TYPES.password)}
                onSubmitEditing={() => handleSignUpPress()}
              />
              <Divider empty height={16} />
              <Text
                style={[styles.label, { color: inputError.includes(INPUT_TYPES.confirmPassword) ? '#EB5038' : '#453B3B' }]}
              >
                Confirm password<Text style={{ color: '#EB5038' }}> *</Text>
              </Text>
              <Divider empty height={8} />
              <Input
                showPassword={isShowingConfirmPassword}
                handleShowPassClick={() => setShowingConfirmPassword((v) => !v)}
                value={confirmPassword}
                setValue={(value) =>
                  setConfirmPassword(value)}
                leftIcon={<PasswordIcon />}
                isPassword
                style={{ width: 320 }}
                textStyle={{ fontSize: 18 }}
                placeholder="Confirm password"
                hasError={inputError.includes(INPUT_TYPES.confirmPassword)}
                onSubmitEditing={() => handleSignUpPress()}
              />
            </>
          }
          <Divider empty height={32} />
          {!isStudent && <>
            <View style={styles.checkBoxContainer}>
              <Checkbox
                isChecked={tosChecked}
                onPress={() => setTosChecked((v) => !v)}
                containerStyle={styles.checkboxStyle}

              />
              <Text style={styles.checkBoxLabel}>
                By signing up you are agreeing to our &nbsp;
                <Text style={styles.textPressable}
                  onPress={() => Linking.openURL(envs.PRIVACY_POLICY_LINK)}>
                  Privacy Policy
                </Text>
                &nbsp;and&nbsp;
                <Text style={styles.textPressable}
                  onPress={() => Linking.openURL(envs.TERMS_OF_SERVICE_LINK)}>
                  Terms of Use
                </Text>.
              </Text>
            </View>
            <Divider empty height={32} />
          </>}
          <MenuButton
            onPress={handleSignUpPress}
            colors={['#90DF75', '#62B655']}
            text="Sign up"
            height={64}
            width={320}
            loading={loading || requestCodeLoading}
            disabled={loading || requestCodeLoading || (!isStudent && !tosChecked)}
          />
          <Divider empty height={24} />
          <View style={styles.signUpContainer}>
            <Text style={styles.signUpText}>Already have an account?</Text>
            <TouchableOpacity onPress={() => navigation.push('SignIn')}>
              <Text style={styles.signUpText2}>Sign in</Text>
            </TouchableOpacity>
          </View>
          <Divider empty height={isMobile ? 120 : 200} />
          <PasswordPopup
            handlePress={handlePasswordPopupPress}
            visible={isPasswordPopupVisible}
            setVisible={setPasswordPopupClose}
            password={suggestedPassword}
          />
        </ComponentWrapper>
      </DismissKeyboard>
      <ErrorPopup
        isVisible={hasError.popup}
        setVisible={() => setHasError(v => ({ ...v, popup: false }))}
        text={hasError.text}
      />
    </>
  );
};

export { SignUp };

const styles = StyleSheet.create({
  image: { width: 180, height: 100 },
  howToPlayText: {
    fontFamily: 'Secular One',
    fontSize: 20,
    color: 'black',
    textDecorationLine: 'underline',
  },
  title: {
    fontFamily: 'Secular One',
    fontSize: 32,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
    marginBottom: 12,
  },
  subTitle: {
    fontFamily: 'Secular One',
    fontSize: 18,
    fontWeight: '400',
    color: '#E89823',
    paddingHorizontal: 40,
    textAlign: 'center',
  },
  topContainer: {
    height: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    ...mbPlatformStyle({
      mobile: {
        paddingHorizontal: 24,
      },
      web: {
        paddingHorizontal: 120,
      },
    }),
    position: 'absolute',
    top: 60,
  },
  beeIcon: {
    width: 25,
    height: 38,
  },
  signUpContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  signUpText: {
    fontFamily: 'Secular One',
    fontSize: 20,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
  signUpText2: {
    fontFamily: 'Secular One',
    fontSize: 20,
    marginLeft: 6,
    color: '#E89823',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 2 },
    textShadowRadius: 2,
    textDecorationStyle: 'solid',
    textDecorationLine: 'underline',
  },
  label: {
    fontFamily: 'Secular One',
    fontSize: 15,
    textAlign: 'center',
  },
  checkBoxContainer: {
    flexDirection: 'row',
    width: 320,
    paddingHorizontal: 16,

  },
  checkBoxLabel: {
    fontFamily: 'Secular One',
    fontSize: 14,
    color: '#121212',
    textAlign: 'center',
  },
  checkboxStyle: {
    marginRight: 4,
    marginTop: 4,
  },
  textPressable: {
    color: '#E89823',
    textDecorationStyle: 'solid',
    textDecorationLine: 'underline',
  },
});
