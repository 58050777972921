import { Platform } from 'react-native';
import { applyTransparency } from '../constants/colors';
import { SERVER_ERROR_CODES } from '../constants/constants';
import { IImage, ServerError } from '../typesInterfacesEnums/typesAndInterfaces';
import { MB_customWindowForWeb } from '@mightybyte/rnw.utils.custom-window-for-web';
import * as WebBrowser from 'expo-web-browser';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { envs } from '../../env';
import { ServerRedirectNavigationProp } from '../typesInterfacesEnums/componentProps';
import { serverRedirectUtils } from '../components/screens/ServerRedirect/serverRedirectUtils';
import { Buffer } from 'buffer';

export const utils = {
  getShadow: ({
    color,
    opacity,
    radius,
    offsetWidth,
    offsetHeight,
    elevation,
  }: {
    color: string;
    opacity: number;
    radius: number;
    offsetWidth: number;
    offsetHeight: number;
    elevation: number;
  }) => {
    return Platform.select({
      ios: {
        shadowColor: color,
        shadowOpacity: opacity,
        shadowRadius: radius,
        shadowOffset: {
          width: offsetWidth,
          height: offsetHeight,
        },
      },
      android: {
        elevation,
      },
      web: {
        boxShadow: `${offsetWidth}px ${offsetHeight}px ${radius}px ${applyTransparency(
          color,
          opacity,
        )}`,
      },
    });
  },
  getUrlParams: (url: string) => {
    try {
      let urlObj = new URL(url);
      return Object.fromEntries(urlObj.searchParams.entries());
    } catch (error) {
      console.error('Error: Failed to extract url params', error);
      return {};
    }
  },
  getMediumImage: <T extends { asUri?: boolean; preferLarge?: boolean } | undefined>(
    image: IImage | undefined,
    options?: T,
  ): (T extends { asUri: true } ? { uri: string } : string) | undefined => {
    if (!image) {
      return undefined;
    }

    function processReturn(retVal: string) {
      return !options?.asUri ? retVal : { uri: retVal };
    }

    if (image.medium) {
      return processReturn(image.medium) as any;
    }

    if (options?.preferLarge) {
      if (image.large !== undefined) {
        return processReturn(image.large) as any;
      } else if (image.small !== undefined) {
        return processReturn(image.small) as any;
      }
    } else {
      if (image.small !== undefined) {
        return processReturn(image.small) as any;
      } else if (image.large !== undefined) {
        return processReturn(image.large) as any;
      }
    }

    return undefined;
  },

  getLargestImage: <T extends { asUri?: boolean } | undefined>(
    image: IImage | undefined,
    options?: T,
  ): (T extends { asUri: true } ? { uri: string } : string) | undefined => {
    if (!image) {
      return undefined;
    }

    function processReturn(retVal: string) {
      return !options?.asUri ? retVal : { uri: retVal };
    }

    if (image.large !== undefined) {
      return processReturn(image.large) as any;
    } else if (image.medium !== undefined) {
      return processReturn(image.medium) as any;
    } else if (image.small !== undefined) {
      return processReturn(image.small) as any;
    }

    return undefined;
  },

  getSmallestImage: <T extends { asUri?: boolean } | undefined>(
    image: IImage | undefined,
    options?: T,
  ): (T extends { asUri: true } ? { uri: string } : string) | undefined => {
    if (!image) {
      return undefined;
    }

    function processReturn(retVal: string) {
      return !options?.asUri ? retVal : { uri: retVal };
    }

    if (image.small !== undefined) {
      return processReturn(image.small) as any;
    } else if (image.medium !== undefined) {
      return processReturn(image.medium) as any;
    } else if (image.large !== undefined) {
      return processReturn(image.large) as any;
    }

    return undefined;
  },

  isWithinRange: (size: number) => {

    const BIGGEST_TILE_SIZE = 52;
    const SMALLEST_TILE_SIZE = 35;

    if (size > BIGGEST_TILE_SIZE) {
      return BIGGEST_TILE_SIZE;
    } else if (size < SMALLEST_TILE_SIZE) {
      return SMALLEST_TILE_SIZE;
    } else {
      return Math.round(size);
    }
  },
  compareVersion: (version: string, version2: string) => {
    return version.localeCompare(version2, undefined, { numeric: true, sensitivity: 'base' });
  },
  createErrorObject: (message: string, errorCode: SERVER_ERROR_CODES | string): ServerError => {
    return {
      message,
      errorCode,
      status: 'error',
    };
  },

  openExternalWindow: (url: string, name?: string, width?: number, height?: number) => {
    if (isMobileApp) {
      return;
    }

    const w = MB_customWindowForWeb.outerWidth - (width ?? 600);
    const h = MB_customWindowForWeb.outerHeight - (height ?? 750);
    const left = Math.round(MB_customWindowForWeb.screenX + w / 2);
    const top = Math.round(MB_customWindowForWeb.screenY + h / 2.5);

    MB_customWindowForWeb.open(
      url,
      name ?? 'LogIn',
      'width=' + (width ?? 600) + ',height=' + (height ?? 750) + ',left=' + left + ',top=' + top + ',toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0',
    );
  },
  getDeepLink: (path = '') => {
    return envs.MOBILE_DEEP_LINK_FOR_SERVER_REDIRECT + path;
  },
  openExternalAuthWindowForMobile: async (urlToOpen: string, navigation: ServerRedirectNavigationProp) => {
    try {
      const redirectUrl = utils.getDeepLink();
      let response = await WebBrowser.openAuthSessionAsync(urlToOpen, redirectUrl);
      if (response.type === 'success') {
        const params = utils.getUrlParams(response.url);
        serverRedirectUtils.handleServerRedirect({ errorParamBase64: params.error, successParamsBase64: params.success, navigation });
      } else {
        if (response.type === 'dismiss') {
          return;
        }
        throw new Error(`Failed to find url in auth session.(${urlToOpen})`);
      }
    } catch (err) {
      console.log(`Error in handling mobile auth session (${urlToOpen}): - `, err);
    }
  },

  dateToDisplay: (date: string) => {
    //return date in format MM/DD/YYYY
    const d = new Date(date);
    return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
  },
  encodeParam: (value: any) => value !== undefined ? Buffer.from(JSON.stringify(value)).toString('base64') : 'n/a',
  decodeParams: (data: any) => {
    Object.keys(data ?? {}).forEach(key => {
      if (typeof data[key] === 'object') {
        utils.decodeParams(data[key]);
      } else if (typeof data[key] === 'string') {
        if (data[key] === 'n/a') {
          delete data[key];
        } else {
          try { data[key] = JSON.parse(Buffer.from(data[key], 'base64').toString('ascii')); } catch { }
        }
      }
    });
    return data;
  },
  isIosWeb: (): boolean => {
    const navigator = (global as any).navigator;
    const isSafari = /.*safari/i.test(navigator.userAgent);
    return isSafari && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;
  },
};
