import React, { useCallback, useEffect, useState } from 'react';
import { Keyboard, StyleSheet, Text } from 'react-native';
import { applyTransparency } from '../../../constants/colors';
import { ScreenProps } from '../../../typesInterfacesEnums/componentProps';
import { BackButton } from '../../helperComponents/BackButton';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { DismissKeyboard } from '../../helperComponents/DismissKeyboard';
import { Divider } from '../../helperComponents/Divider';
import { ErrorPopup } from '../../helperComponents/ErrorPopup';
import { Input } from '../../helperComponents/Input';
import { MenuButton } from '../../helperComponents/MenuButton';
import { GameMode } from '../GameScreen';
import { ApolloError } from '@apollo/client';
import { usePassAndPlayMutation } from './usePassAndPlayMutation.gql';
import { EventTypeGameEventType, useGameEventSubscription } from './useGameEventSubscription.gql';

const PassAndPlay = ({ route, navigation }: ScreenProps<'PassAndPlay'>) => {
  const [userName1, setUserName1] = useState('');
  const [userName2, setUserName2] = useState('');
  const [hasError, setHasError] = useState({
    visible: false,
    popup: false,
    text: '',
  });
  const { data: gameEventSubscription } = useGameEventSubscription();
  const [passAndPlay, { error, loading }] = usePassAndPlayMutation({
    variables: { inputs: { userName1, userName2, gameType: route.params.gameType } },
  });

  const navigateToGameScreen = useCallback(() => {
    if (gameEventSubscription?.gameEvent.game) {
      navigation.navigate('Game', {
        game: gameEventSubscription?.gameEvent.game,
        mode: GameMode.HOST_A_GAME,
      });
    }
  }, [gameEventSubscription?.gameEvent.game, navigation]);

  const handlePassAndPlayError = (err: ApolloError) => {
    let errorMessage = err.message;
    if (err.message.includes('Argument Validation Error')) {
      errorMessage = 'Please choose a more appropriate name.';
    }
    setHasError({ popup: true, visible: true, text: errorMessage });
  };

  const handlePassAndPlay = () => {
    passAndPlay().catch((err) => {
      handlePassAndPlayError(err);
    });
  };

  useEffect(() => {
    if (error) {
      handlePassAndPlayError(error);
    }
  }, [error]);

  useEffect(() => {
    if (gameEventSubscription?.gameEvent.eventType === EventTypeGameEventType.ON_GAME_START) {
      navigateToGameScreen();
    }
  }, [gameEventSubscription, navigateToGameScreen, navigation]);

  const onNext = () => {
    Keyboard.dismiss();
    handlePassAndPlay();
  };

  return (
    <>
      <DismissKeyboard>
        <ComponentWrapper hasInset hasScroll={false}>
          <Text maxFontSizeMultiplier={1} style={styles.title}>
            Play And Pass
          </Text>
          <Text maxFontSizeMultiplier={1} style={styles.subtitle}>
            Please enter two usernames to start the game
          </Text>
          <Divider empty height={36} />
          <Input
            textContentType="name"
            placeholder="UserName 1"
            value={userName1}
            setValue={setUserName1}
            onSubmitEditing={onNext}
          />
          <Divider empty height={16} />
          <Input
            textContentType="name"
            placeholder="UserName 2"
            value={userName2}
            setValue={setUserName2}
            onSubmitEditing={onNext}
          />
          <Divider empty height={28} />
          <MenuButton
            onPress={onNext}
            disabled={!userName1 || !userName2 || loading}
            colors={['#90DF75', '#62B655']}
            text="Start Game Now"
            height={64}
            width={240}
            loading={loading}
          />
          <Divider empty height={24} />
          <BackButton text="Back To Lobby" onPress={() => navigation.reset({ index: 0, routes: [{ name: 'Lobby' }] })} />
        </ComponentWrapper>
      </DismissKeyboard>
      <ErrorPopup
        isVisible={hasError.popup}
        setVisible={() => setHasError(v => ({ ...v, popup: false }))}
        text={hasError.text}
      />
    </>
  );
};

export { PassAndPlay };

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Secular One',
    fontSize: 32,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
  },
  subtitle: {
    fontFamily: 'Inter',
    fontSize: 14,
    maxWidth: 270,
    textAlign: 'center',
    color: '#3F3F3F',
  },
  waiting: {
    fontSize: 20,
    fontFamily: 'Secular One',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
  },
});
