import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const mutation = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken {
          token
          expiresIn
          expiresInMs
          expiresAt
          expiresAtMs
      }
      refreshToken
    }
  }
`;

export interface RequestType {
  email: string;
  password: string
}

export interface MutationType {
  login: LoginResponseType;
}

export interface LoginResponseType {
  accessToken: {
    token: string;
    expiresIn: string;
    expiresInMs: number;
    expiresAt: string;
    expiresAtMs: number;
    __typename?: string;
  };
  refreshToken: string;
  __typename?: 'LoginResponse';
}

export function useLoginMutation(options?: MutationHookOptions<MutationType, RequestType>) {
  return useMutation<MutationType, RequestType>(mutation, options);
}
