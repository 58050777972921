import React, { useMemo, useState } from 'react';
import { View } from 'react-native';
import { NothingToShow } from './pieces/NothingToShow';
import { createTableColumn, TabNames } from './utils';
import { AdminPageHeader } from './pieces/ScreenHeader';
import { adminPageStyles } from './adminPageStyles';
import { AdminViewTable } from './pieces/AdminViewTable';
import { COLORS } from '../../../constants/colors';
import { useGetTeachers } from '../../../graphql/admin/getTeachers';
import { RouteProp, useRoute } from '@react-navigation/native';
import { AdminNavigatorParamList } from '../../../navigators/AdminNavigator/AdminNavigator';
import { AdminNavigatorProps } from '../../../typesInterfacesEnums/componentProps';
import { utils } from '../../../utils/utils';
import { MB_Button } from '@mightybyte/rnw.components.button';
import EyeOutlinedIcon from '../../../resources/svgComponents/EyeOutlinedIcon';
// import CloseSquaredIcon from '../../../resources/svgComponents/CloseSquaredIcon';
import { MB_Modal } from '../../../mightyByteLibraries/MB_Modal/MB_Modal';
import { ViewTeacherDetails } from './ViewTeacherDetails';
import { DisableUser } from './pieces/DisableUser';
import { USER_TYPES } from '../../../typesInterfacesEnums/enums';


const Teachers = ({navigation}: AdminNavigatorProps) => {
    const pageSize = 8;

    const {page} = useRoute<RouteProp<AdminNavigatorParamList, 'Teachers'>>().params;
    const [currentPage, setCurrentPage] = useState<number>(page ? Number(page) : 1);
    const [viewUserId, setViewUserId] = useState<string | undefined>(undefined);
    const [disableUser, setDisableUser] = useState<{id: string, name: string} | undefined>(undefined);

    const {data:teachers, loading} = useGetTeachers({variables: {
        pageSize: pageSize,
        page: currentPage,
    },
    fetchPolicy: 'cache-and-network',
    });


    const dataToDisplay = useMemo(() => {
        const teachersData = teachers?.getAdminTeachers?.items || [];
        if (teachersData.length === 0 || loading) {
            return Array(8).fill(Array(5).fill(''));
        }

        return teachersData.map((teacher) => {
            return [
                teacher.name, teacher.email, teacher.numberOfHives, utils.dateToDisplay(teacher.joinedDate),
                <MB_Button title={'View'}
                           onPress={() => {
                               setViewUserId(teacher.id);
                           }}
                           style={adminPageStyles.tableTextButton}
                           textStyle={{
                               ...adminPageStyles.tableButtonBase,
                               color: COLORS.pumpkinYellow,
                           }}
                           leftElement={<EyeOutlinedIcon color={COLORS.pumpkinYellow} size={14} />}

                />,
                    // <MB_Button title={'Disable'}
                    //              onPress={() => {
                    //                  setDisableUser({id: teacher.id, name: teacher.name});
                    //              }}
                    //                 style={adminPageStyles.tableTextButton}
                    //                 textStyle={{
                    //                     ...adminPageStyles.tableButtonBase,
                    //                     color: '#EA2A21',
                    //                 }}
                    //            leftElement={<CloseSquaredIcon size={14} color={'#EA2A21'}/>}
                    // />,

            ];
        });

    }, [teachers, loading]);

    const totalPages = useMemo(() => {
        const totalItems = teachers?.getAdminTeachers?.total || 0;
        return Math.ceil(totalItems / pageSize);
    } , [teachers]);

    const tableProps = useMemo(() => {
        return {
            header: [
                createTableColumn({name: 'Name', containerStyle: {minWidth: 180, maxWidth: 240}}),
                createTableColumn({name: 'Email', containerStyle: {minWidth: 240}}),
                createTableColumn({name: 'No. of Hives In', containerStyle: {minWidth: 150, maxWidth: 150}}),
                createTableColumn({name: 'Date Joined', containerStyle: {minWidth: 100, maxWidth: 100}}),
                createTableColumn({name: '', containerStyle: {minWidth: 80, maxWidth: 80}}),
                // createTableColumn({name: '', containerStyle: {minWidth: 80, maxWidth: 80}}),
            ],
            data: dataToDisplay,

        };
    }, [dataToDisplay]);

    return (
        <View style={adminPageStyles.page}>
            { <MB_Modal isVisible={!!viewUserId}
                        hideCloseButton={true}
                        onBackdropPress={()=>setViewUserId(undefined)}
            >
                {!!viewUserId && <ViewTeacherDetails id={viewUserId} closeAction={()=>setViewUserId(undefined)}/>}
            </MB_Modal>}
            { <MB_Modal isVisible={!!disableUser}
                        hideCloseButton={true}
                        onBackdropPress={()=>setDisableUser(undefined)}
            >
                {!!disableUser && <DisableUser id={disableUser.id}
                                               userName={disableUser.name}
                                               userRole={USER_TYPES.teacher}
                                               closeAction={()=> setDisableUser(undefined)}

                />
                }
            </MB_Modal>}
            <AdminPageHeader text={TabNames.Teachers}/>
            {!loading && teachers?.getAdminTeachers.items.length === 0 ? <NothingToShow tab={TabNames.Teachers}/>
                : <AdminViewTable {...tableProps}
                                  currentPage={currentPage}
                                  setCurrentPage={setCurrentPage}
                                  navigation={navigation}
                                  totalPages={totalPages}
                                  totalItems={teachers?.getAdminTeachers?.total || 0}
                                  currentPageSize={pageSize}

            />}
        </View>
    );
};

export { Teachers };
