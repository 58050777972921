import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const VSIcon = React.memo(
  (props: {
    width?: number;
    height?: number;
    color?: string;
    containerStyle?: StyleProp<ViewStyle>;
  }) => {
    return (
      <View style={props.containerStyle}>
        <Svg
          width={props.width ?? '69px'}
          height={props.height ?? '78px'}
          viewBox="0 0 69 78"
          {...props}
        >
          <Defs>
            <LinearGradient x1="49.9999673%" y1="0%" x2="49.9999673%" y2="100%" id="b">
              <Stop stopColor="#90DF75" offset="0%" />
              <Stop stopColor="#62B655" offset="100%" />
            </LinearGradient>
          </Defs>
          <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
            <G transform="translate(10.201 10.865)">
              <Path
                fill="url(#b)"
                fillRule="nonzero"
                d="M24.1189 1.35652L47.0686 14.85652 47.0686 41.85652 24.1189 55.35652 1.16923 41.85652 1.16923 14.85652z"
              />
              <Path
                stroke="#FFF"
                strokeWidth={2.33846}
                d="M24.7117 0.34872L24.1189 3.55271368e-15 23.5261 0.34872 0.5764 13.84872 0 14.18782 0 14.85652 0 41.85652 0 42.52522 0.5764 42.86432 23.5261 56.36432 24.1189 56.71302 24.7117 56.36432 47.6614 42.86432 48.2378 42.52522 48.2378 41.85652 48.2378 14.85652 48.2378 14.18782 47.6614 13.84872z"
              />
            </G>
            <Path
              d="M0 .234h2.88l2.844 9.144L8.55.234h2.682L7.2 12.222H4.05L0 .234zm16.552 12.222a8.36 8.36 0 01-1.998-.234 6.249 6.249 0 01-1.62-.63l.216-2.412c.54.336 1.104.6 1.692.792.6.18 1.152.27 1.656.27.576 0 1.044-.12 1.404-.36.36-.24.54-.558.54-.954 0-.324-.12-.57-.36-.738-.24-.18-.618-.372-1.134-.576l-.432-.18a3.603 3.603 0 00-.198-.072 2.642 2.642 0 00-.252-.108c-.672-.276-1.212-.534-1.62-.774a3.514 3.514 0 01-1.062-1.044c-.288-.444-.432-1.02-.432-1.728 0-1.152.39-2.058 1.17-2.718.78-.66 1.866-.99 3.258-.99 1.056 0 2.136.216 3.24.648l-.396 2.376a6.377 6.377 0 00-1.44-.594 5.243 5.243 0 00-1.44-.216c-.564 0-1.002.114-1.314.342-.312.216-.468.51-.468.882 0 .324.108.576.324.756.216.18.564.372 1.044.576l.738.288a13.62 13.62 0 011.728.774 3.39 3.39 0 011.17 1.098c.324.468.486 1.08.486 1.836 0 1.164-.396 2.07-1.188 2.718-.792.648-1.896.972-3.312.972z"
              transform="translate(10.201 10.865) translate(13.799 22.135)"
              fill="#FFF"
              fillRule="nonzero"
            />
          </G>
        </Svg>
      </View>
    );
  },
);

export { VSIcon };
