import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const UserThreeIcon = React.memo(({size, containerStyle, color, filled }: {
    size?: number,
    containerStyle?: StyleProp<ViewStyle>
    color?: string,
    filled?: boolean,
}) => {
    const iconColor = color ?? '#FFFFFF';
    return (
        <View style={containerStyle}>
            <Svg
                width={size ?? 20}
                height={size ? size * (16 / 20) : 16}
                viewBox="0 0 20 16"
                fill={filled ? iconColor : 'none'}
            >
                <Path
                    d="M15.397 6.655a2.636 2.636 0 002.266-2.605 2.635 2.635 0 00-2.206-2.6M17.084 9.729c1.239.185 2.103.619 2.103 1.512 0 .615-.407 1.015-1.065 1.266"
                    stroke={iconColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <Path
                    clipRule="evenodd"
                    d="M9.896 10.108c-2.946 0-5.462.446-5.462 2.23 0 1.781 2.5 2.24 5.462 2.24 2.946 0 5.462-.441 5.462-2.225s-2.5-2.245-5.462-2.245zM9.896 7.563a3.5 3.5 0 10-3.501-3.5 3.487 3.487 0 003.475 3.5h.026z"
                    stroke={iconColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <Path
                    d="M4.394 6.655A2.635 2.635 0 012.128 4.05c0-1.309.955-2.394 2.206-2.6M2.707 9.729c-1.239.185-2.103.619-2.103 1.512 0 .615.407 1.015 1.065 1.266"
                    stroke={iconColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </Svg>
        </View>
    );
});

export default UserThreeIcon;
