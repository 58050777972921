import { gql, useMutation, MutationHookOptions } from '@apollo/client';

export const query = gql`
  mutation EditHiveUser($userId: String!, $firstName: String!, $lastName: String!) {
    editHiveUser(userId: $userId, firstName: $firstName, lastName: $lastName){
      id
      firstName
      lastName
      userName
    }
  }
`;

export interface RequestType {
  userId: string;
  firstName: string;
  lastName: string;
}

export interface MutationType {
  editHiveUser: {
    id: string,
    firstName: string,
    lastName: string,
    userName: string
  };
}

export function useEditUserFromHiveMutation(options?: MutationHookOptions<MutationType, RequestType>) {
  return useMutation<MutationType, RequestType>(query, options);
}
