import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path, Rect } from 'react-native-svg';

const DisableHeaderIcon = React.memo(
    (props: {
        size?: number;

        containerStyle?: StyleProp<ViewStyle>;
    }) => {
        return (
            <View style={props.containerStyle}>
                <Svg
                    width={57}
                    height={57}
                    viewBox="0 0 57 57"
                    fill="none"
                >
                    <Rect x={4.5} y={4} width={48} height={48} rx={24} fill="#FEE4E2" />
                    <Path
                        d="M31.395 25.595l-4.791 4.792M31.398 30.39l-4.796-4.797"
                        stroke="#D72F2F"
                        strokeWidth={1.2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <Path
                        clipRule="evenodd"
                        d="M33.335 18.75h-8.669c-3.021 0-4.915 2.14-4.915 5.166v8.168c0 3.027 1.885 5.166 4.915 5.166h8.668c3.031 0 4.917-2.139 4.917-5.166v-8.168c0-3.027-1.886-5.166-4.916-5.166z"
                        stroke="#D72F2F"
                        strokeWidth={1.2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <Rect
                        x={4.5}
                        y={4}
                        width={48}
                        height={48}
                        rx={24}
                        stroke="#FEF3F2"
                        strokeWidth={8}
                    />
                </Svg>
            </View>
        );
    },
);

export default DisableHeaderIcon;
