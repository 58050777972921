import { SubscriptionHookOptions, useSubscription } from '@apollo/client';
import gql from 'graphql-tag';
import { GAME_TYPES_TYPE, OPERAND_TYPE } from '../../../constants/constants';

const subscription = gql`
  subscription subscribeToGameEvent {
    gameEvent {
      eventType
      game {
        id
        status
        users {
          id
          name
        }
        gameState {
          gameType
          turn
          winningPlayerId
          lastPlayedTimeAt
          leftOperand {
            a
            x
            b
          }
          rightOperand {
            a
            x
            b
          }
          winningTiles
          winningDirection
          tiles {
            id
            value {
              a
              x
              b
            }
            edges
            claimedBy
          }
        }
      }
    }
  }
`;

export interface SubscriptionType {
  gameEvent: GameEventType;
  __typename?: 'Subscription';
}

export interface GameEventType {
  eventType: EventTypeGameEventType;
  game: GameType;
  __typename?: 'GameEvent';
}

export enum EventTypeGameEventType {
  ON_GAME_START = 'ON_GAME_START',
  ON_CLAIM_TILE = 'ON_CLAIM_TILE',
  ON_USER_LEFT = 'ON_USER_LEFT',
}

export interface GameType {
  id: string;
  status: GameStatus;
  users: GameUserType[];
  gameState: GameStateType;
  __typename?: 'Game';
}

export enum GameStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

export interface GameUserType {
  id: string;
  name: string;
  __typename?: 'GameUser';
}

export interface GameStateType {
  gameType: GAME_TYPES_TYPE;
  turn: string;
  winningPlayerId: string;
  winningTiles: number[];
  winningDirection: number[];
  leftOperand: OPERAND_TYPE;
  rightOperand: OPERAND_TYPE;
  tiles: GameHiveTileType[];
  lastPlayedTimeAt: string;
  __typename?: 'GameState';
}

export interface GameHiveTileType {
  id: number;
  value: OPERAND_TYPE;
  edges: number[];
  claimedBy: string;
  __typename?: 'GameHiveTile';
}

export function useGameEventSubscription(
  options?: SubscriptionHookOptions<SubscriptionType, {}>,
) {
  return useSubscription<SubscriptionType, {}>(subscription, options);
}
