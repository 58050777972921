import { HiveSize } from '../constants/constants';
import { axiosCaller } from './customAxios';

const paymentApiCalls = {
    subscribeToStripe: async (name: string, size: HiveSize): Promise<void> => {
        return await axiosCaller({
            path: '/api/subscription/stripe/subscribe',
            method: 'get',
            passToken: true,
            data: {
                name,
                size,
            },
        });
    },
};

export { paymentApiCalls };
