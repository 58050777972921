import { useEffect } from 'react';
import { useSignedInContext } from '../context/SignedInContext';
import { useCurrentUserQuery } from '../graphql/getCurrentUser';

export const useGetCurrentUserData = () => {
  const { setUser, isSignedIn } = useSignedInContext();
  const { data, loading, refetch } = useCurrentUserQuery({ fetchPolicy: 'network-only', skip: !isSignedIn });

  useEffect(() => {
    if (data) {
      setUser(data.currentUser);
    }
  }, [data, setUser]);

  return { data, loading, refetchCurrentUserData: refetch };
};
