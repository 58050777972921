import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported, Messaging } from 'firebase/messaging';
import { envs } from '../../../env';
import { customWindowForWeb } from '../../utils/customWindowForWeb/customWindowForWeb';
import { MB_EVENT_EMMITER_EVENT_TYPE, MB_EventEmmiter } from '@mightybyte/rnw.utils.event-emmiter';

const app = initializeApp(envs.FIREBASE_CONFIG);
let messaging: Messaging | undefined;

// TODO: Firefox does not have the service worker object. We need to find another way of doing this in the future.
customWindowForWeb.navigator.serviceWorker?.addEventListener(MB_EVENT_EMMITER_EVENT_TYPE.message, (event) => {
    const { type, payload } = event.data;
    switch (type) {
        case 'NOTIFICATION_RECEIVED':
            MB_EventEmmiter.emit(MB_EVENT_EMMITER_EVENT_TYPE.message, { origin: envs.WEBSITE_BASE_URL, data: { message: 'NOTIFICATION_UPDATE', payload } });
            break;
    }
});

const MB_FirebaseMessaging = {
    getToken: async (): Promise<string | undefined> => {
        const supported = await isSupported();
        messaging = supported ? getMessaging(app) : undefined;

        if (!messaging) {
            throw new Error('Unable to get permission to notify.');
        }

        const token = await getToken(messaging, { vapidKey: envs.GENERATED_MESSAGING_KEY });

        onMessage(messaging, (payload) => {
            MB_EventEmmiter.emit(MB_EVENT_EMMITER_EVENT_TYPE.message, { origin: envs.WEBSITE_BASE_URL, data: { message: 'NOTIFICATION_UPDATE', payload } });
        });

        return token;
    },
};

export { MB_FirebaseMessaging };
