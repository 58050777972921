import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const ContactUsIcon = React.memo(({size, containerStyle, color}: {
    size?: number,
    containerStyle?: StyleProp<ViewStyle>
    color?: string,
    filled?: boolean,
}) => {
    const iconColor = color ?? '#FFFFFF';
    return (
        <View style={containerStyle}>
            <Svg
                width={size ?? 18}
                height={size ? size : 18}
                viewBox="0 0 18 18"
                fill="none"
            >
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.816 16.78c0-.623-.504-1.127-1.126-1.127H8.31a1.126 1.126 0 000 2.253h1.38c.622 0 1.126-.505 1.126-1.127zm-8.448-2.905c.188.014.416.022.645.009a3.198 3.198 0 003.137 2.579h.43a1.76 1.76 0 000 .633h-.43a3.83 3.83 0 01-3.782-3.221zm-.322-.674A2.454 2.454 0 010 10.781V9.285a2.454 2.454 0 012.454-2.454h.164a6.403 6.403 0 0112.764 0h.164A2.455 2.455 0 0118 9.285v1.496a2.454 2.454 0 01-2.454 2.454h-.724a.544.544 0 01-.544-.543V7.343a5.278 5.278 0 00-10.556 0v5.347a.544.544 0 01-.298.486c-.488.17-1.244.047-1.378.024z"
                    stroke={iconColor}
                />

            </Svg>
        </View>
    );
});

export default ContactUsIcon;
