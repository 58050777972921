import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Popup } from './Popup';
import { Input } from './Input';
import { UsernameIcon } from '../../resources/svgComponents/UsernameIcon';
import { MenuButton } from './MenuButton';
import { Divider } from './Divider';
import { applyTransparency } from '../../constants/colors';

interface IPopupProps {
  visible: boolean;
  firstName: string,
  lastName: string,
  userName: string,
  loading: boolean,

  setVisible: (value: boolean) => void;
  onSavePress: (firstName: string, lastName: string) => void;
}
const EditPopup = ({ visible, setVisible, onSavePress, firstName, lastName, loading }: IPopupProps) => {
  const [innerFirstName, setInnerFirstName] = useState('');
  const [innerLastName, setInnerLastName] = useState('');

  useEffect(() => {
    setInnerFirstName(firstName);
    setInnerLastName(lastName);
  }, [firstName, lastName]);

  return (
    <Popup
      height={430}
      title="Edit Details"
      visible={visible}
      setVisible={setVisible}
      closeOnBackdropPress={true}
    >
      <View style={styles.container}>
        <Divider empty height={20} />
        <Text style={styles.title}>Edit this user’s details below:</Text>
        <Divider empty height={24} />
        <Text style={styles.label}>First name</Text>
        <Input
          style={{ width: 320 }}
          placeholder="FirstName"
          value={innerFirstName}
          setValue={(value) => setInnerFirstName(value)}
          leftIcon={<UsernameIcon />}
          textStyle={{ fontSize: 18 }}
        />
        <Divider empty height={16} />
        <Text style={styles.label}>Last name</Text>
        <Input
          style={{ width: 320 }}
          placeholder="LastName"
          value={innerLastName}
          setValue={(value) => setInnerLastName(value)}
          leftIcon={<UsernameIcon />}
          textStyle={{ fontSize: 18 }}
        />
        <Divider empty height={32} />
        <MenuButton
          height={62}
          width={320}
          onPress={() => onSavePress(innerFirstName, innerLastName)}
          text="Save changes"
          colors={['#90DF75', '#62B655']}
          textStyle={styles.buttonTextStyle}
          loading={loading}
          disabled={loading}
        />
      </View>
    </Popup>
  );
};

export { EditPopup };

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Secular One',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
    fontSize: 18,
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 20,
  },
  buttonTextStyle: {
    fontFamily: 'Secular One',
    color: '#fff',
    fontSize: 20,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
  label: {
    fontFamily: 'Secular One',
    marginBottom: 8,
    fontSize: 15,
  },
});
