import React, { useCallback, useEffect, useState } from 'react';
import { Keyboard, Pressable, Share, StyleSheet, Text, View } from 'react-native';
import { applyTransparency } from '../../../constants/colors';
import bee4Icon from '../../../resources/images/bee4.png';
import { ShareIcon } from '../../../resources/svgComponents/ShareIcon';
import { ScreenProps } from '../../../typesInterfacesEnums/componentProps';
import { utils } from '../../../utils/utils';
import { BackButton } from '../../helperComponents/BackButton';
import CircularProgress from '../../helperComponents/Circular';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { Divider } from '../../helperComponents/Divider';
import { ErrorPopup } from '../../helperComponents/ErrorPopup';
import { Input } from '../../helperComponents/Input';
import { MenuButton } from '../../helperComponents/MenuButton';
import { Popup } from '../../helperComponents/Popup';
import { GameMode } from '../GameScreen';
import { EventTypeGameEventType, GameStatus, useGameEventSubscription } from './useGameEventSubscription.gql';
import { GAME_TYPES_TYPE } from '../../../constants/constants';

let interval: any = null;

const JoinCode = ({ navigation, route }: ScreenProps<'JoinCode'>) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [hasError, setHasError] = useState({
    visible: false,
    popup: false,
    text: '',
  });
  const [isTimerEnded, setTimerEnded] = useState(false);
  const { data: gameEventSubscription } = useGameEventSubscription();
  const [timer, setTimer] = useState(0);

  const restartTimer = () => {
    setTimer(0);
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(() => {
      setTimer(value => value + 1);
    }, 1000);
  };

  const onShare = async () => {
    const result = await Share.share({
      message: `Join me in a game of number hive!\nUse this code: ${route.params?.joinCode}\n\nhttps://onelink.to/fcwykw`,
    });
    if (result.action === Share.sharedAction) {
      if (result.activityType) {
        // shared with activity type of result.activityType
      } else {
        // shared
      }
    } else if (result.action === Share.dismissedAction) {
      // dismissed
    }
  };

  const navigateToGameScreen = useCallback(() => {
    if (gameEventSubscription?.gameEvent.game) {
      setModalVisible(false);
      navigation.navigate('Game', {
        game: gameEventSubscription?.gameEvent.game,
        mode: GameMode.HOST_A_GAME,
      });
    }
  }, [gameEventSubscription?.gameEvent.game, navigation]);

  const handlePracticePress = useCallback(() => {
    setModalVisible(false);
    setTimeout(() => {
      navigation.navigate('PlayWithAI', { userName: route.params.userName, gameType: GAME_TYPES_TYPE.MULTI_1_12 });
    }, 500);
  }, [navigation, route.params.userName]);

  useEffect(() => {
    if (
      gameEventSubscription?.gameEvent.eventType === EventTypeGameEventType.ON_GAME_START &&
      gameEventSubscription.gameEvent.game.status !== GameStatus.FINISHED
    ) {
      navigateToGameScreen();
    }
    if (gameEventSubscription?.gameEvent && gameEventSubscription.gameEvent.game.status === GameStatus.FINISHED) {
      console.warn('finished game ignored!');
    }
  }, [gameEventSubscription, navigateToGameScreen, navigation]);

  useEffect(() => {
    if (timer > 59) {
      setTimerEnded(true);
      clearInterval(interval);
    }
  }, [timer]);

  useEffect(() => {
    if (isModalVisible) {
      restartTimer();
    }
  }, [isModalVisible]);

  const onNext = () => {
    Keyboard.dismiss();
    setModalVisible(true);
    setTimerEnded(false);
  };

  return (
    <>
      <ComponentWrapper hasInset hasScroll={false}>
        <Text maxFontSizeMultiplier={1} style={styles.title}>Join Code</Text>
        <Text maxFontSizeMultiplier={1} style={styles.subtitle}>Share join code with friends and ask them to join</Text>
        <Divider empty height={36} />
        <Input
          textContentType="name"
          placeholder="Name"
          value={route.params?.joinCode ?? ''}
          hasShareButton
          editable={false}
          onSharePressed={onShare}
          onSubmitEditing={onNext}
        />
        <Divider empty height={28} />
        <MenuButton
          onPress={onNext}
          colors={['#90DF75', '#62B655']}
          text="Next"
          height={64}
          width={240}
        />
        <Divider empty height={24} />
        <BackButton text="Back" onPress={() => navigation.goBack()} />
      </ComponentWrapper>
      <ErrorPopup
        isVisible={hasError.popup}
        setVisible={() => setHasError(v => ({ ...v, popup: false }))}
        text={hasError.text}
      />
      <Popup title="Waiting..." visible={isModalVisible} setVisible={setModalVisible} height={384}>
        <View style={styles.popupContainer}>
          <Divider empty height={54} />
          <View style={styles.popupInnerContainer}>
            <View style={styles.popupInnerTopContainer}>
              <Text maxFontSizeMultiplier={1} style={styles.popupInnerJoinText}>Join Code</Text>
              <View style={styles.codeContainer}>
                <Text maxFontSizeMultiplier={1} style={styles.codeText}>{route.params?.joinCode}</Text>
              </View>
              <Pressable onPress={onShare}>
                <ShareIcon width={36} height={36} />
              </Pressable>
            </View>
            <Text maxFontSizeMultiplier={1} style={styles.description}>
              Share join code with friends and ask them to join
            </Text>
          </View>
          <Divider empty height={30} />
          <CircularProgress progress={60 - timer} size={80} fontSize={20} />
          <Divider empty height={16} />
          {isTimerEnded ? (
            <MenuButton
              onPress={handlePracticePress}
              colors={['#747474', '#1F1F1F']}
              text="Practice"
              textStyle={{ fontSize: 16 }}
              image={bee4Icon}
              height={60}
              width={226}
            />
          ) : (
            <Text maxFontSizeMultiplier={1} style={styles.waiting}>Waiting for friends...</Text>
          )}
        </View>
      </Popup>
    </>
  );
};

export { JoinCode };

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Secular One',
    fontSize: 32,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
  subtitle: {
    fontFamily: 'Inter',
    fontSize: 14,
    maxWidth: 270,
    textAlign: 'center',
    color: '#3F3F3F',
  },
  popupContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    ...utils.getShadow({
      color: '#F18C8C',
      radius: 20,
      opacity: 0.2,
      offsetWidth: 0,
      offsetHeight: 2,
      elevation: 0,
    }),
  },
  popupInnerContainer: {
    backgroundColor: 'white',
    borderWidth: 2,
    borderColor: '#FF9900',
    width: '100%',
    maxWidth: 290,
    padding: 12,
    borderRadius: 8,
  },
  popupInnerTopContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  popupInnerJoinText: {
    fontSize: 18,
    fontFamily: 'Secular One',
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
  },
  codeContainer: {
    borderStyle: 'dashed',
    borderColor: '#1F1F1F',
    borderWidth: 1,
    borderRadius: 6,
    paddingHorizontal: 24,
    paddingVertical: 2,
  },
  codeText: {
    color: '#FF9252',
    fontFamily: 'Secular One',
    fontSize: 18,
  },
  waiting: {
    color: '#000000',
    fontSize: 20,
    fontFamily: 'Secular One',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
  },
  description: {
    fontFamily: 'Inter',
    marginTop: 20,
    textAlign: 'center',
    color: '#3F3F3F',
    paddingHorizontal: 10,
  },
});
