
import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path, G, Defs, ClipPath } from 'react-native-svg';

const CloseBarIcon = React.memo(
  (props: {
    width?: number;
    height?: number;
    colors?: [string, string];
    transparent?: boolean;
    containerStyle?: StyleProp<ViewStyle>;
  }) => {
    return (
      <View style={props.containerStyle}>
        <Svg
          width={props.width ?? 18}
          height={props.height ?? 18}
          viewBox="0 0 18 18"
          fill="none"
          {...props}
        >
          <G clipPath="url(#clip0_29_280)" fill="#000">
            <Path d="M12.255 6.991a.748.748 0 00-.2-1.23.75.75 0 00-.823.133L9.038 7.94 6.992 5.745a.75.75 0 00-1.097 1.023l2.046 2.194-2.195 2.046a.75.75 0 101.023 1.097l2.195-2.045 2.046 2.194a.748.748 0 001.25-.187.75.75 0 00-.154-.835l-2.045-2.195 2.194-2.046z" />
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M.75 9a8.25 8.25 0 1116.5 0A8.25 8.25 0 01.75 9zM9 15.75a6.75 6.75 0 110-13.5 6.75 6.75 0 010 13.5z"
            />
          </G>
          <Defs>
            <ClipPath id="clip0_29_280">
              <Path fill="#fff" d="M0 0H18V18H0z" />
            </ClipPath>
          </Defs>
        </Svg>
      </View>
    );
  },
);

export { CloseBarIcon };
