export const RANDOM_NAMES = [
  'Chris',
  'Dan',
  'Allan',
  'Travis',
  'Jon',
  'Peter',
  'Kym',
  'Andy',
  'Renee',
  'Albert',
  'Sophie',
  'Grace',
  'Mary',
  'Rhonda',
  'Nathan',
];
