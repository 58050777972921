import { axiosCaller } from './customAxios';
import { z } from 'zod';

const utilApiCalls = {
    getMinimumMobileJSVersion: async (): Promise<string> => {
        return await axiosCaller({
            path: '/getMinimumMobileJSVersion',
            method: 'get',
            responseDataKey: 'version',
            responseFormatValidatorMessage: 'Data mismatch for version data response',
            responseValidatorObj: z.string(),
        });
    },
    getBackendVersion: async (): Promise<string> => {
        return await axiosCaller({
            path: '/getVersion',
            method: 'get',
            responseDataKey: 'version',
            responseFormatValidatorMessage: 'Data mismatch for version data response',
            responseValidatorObj: z.string(),
        });
    },
};

export { utilApiCalls };
