import { nanoid } from 'nanoid';
import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { G, Path, Defs, LinearGradient, Stop, Rect } from 'react-native-svg';

const InviteIcon = React.memo(
  (props: {
    width?: number;
    height?: number;
    color?: string;
    containerStyle?: StyleProp<ViewStyle>;
  }) => {
    const gradientId = nanoid();

    return (
      <View style={props.containerStyle}>

        <Svg
          width="31px"
          height="31px"
          viewBox="0 0 31 31"
          {...props}
        >
          <Defs>
            <LinearGradient
              x1="50.0001652%"
              y1="0%"
              x2="50.0001652%"
              y2="100%"
              id={gradientId}
            >
              <Stop stopColor="#FFCF53" offset="0%" />
              <Stop stopColor="#F90" offset="100%" />
            </LinearGradient>
          </Defs>
          <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
            <Rect
              fill={`url(#${gradientId})`}
              fillRule="nonzero"
              x={0}
              y={0}
              width={30.2619}
              height={30.2619}
              rx={15.13095}
            />
            <Rect
              strokeOpacity={0.4}
              stroke="#FFF"
              strokeWidth={2.24162}
              x={1.1208}
              y={1.12081}
              width={28.0203}
              height={28.0203}
              rx={14.0101}
            />
            <G transform="translate(9 9)" fill="#FFF" fillRule="nonzero">
              <Path d="M12.76 4.923l-.984-.394V.38a.38.38 0 00-.38-.381H1.604a.38.38 0 00-.381.38V4.53l-.985.394A.38.38 0 000 5.276v7.207l5.726-5.48-3.74-1.495V.762h9.028v4.745l-3.74 1.497L13 12.484V5.275a.38.38 0 00-.24-.353z" />
              <Path d="M6.5013 6.7754L0.543 12.4581 12.4596 12.4581z" />
              <Path d="M5.566 2.238c-.293-.168-.53-.03-.53.307v2.492c0 .338.237.475.53.307l2.178-1.249c.293-.168.293-.44 0-.608l-2.178-1.25z" />
            </G>
          </G>
        </Svg>
      </View>
    );
  },
);

export { InviteIcon };
