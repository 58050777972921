
import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const CopyIcon = React.memo(
  (props: {
    size?: number;
    colors?: [string, string];
    transparent?: boolean;
    containerStyle?: StyleProp<ViewStyle>;
  }) => {
    return (
      <View style={props.containerStyle}>
        <Svg
          width={props.size ?? 21}
          height={props.size ?? 21}
          viewBox="0 0 21 21"
          fill="none"
          {...props}
        >
          <Path
            d="M16.734 19.688H7.547a2.953 2.953 0 01-2.953-2.954V7.547a2.953 2.953 0 012.953-2.953h9.187a2.953 2.953 0 012.953 2.953v9.187a2.953 2.953 0 01-2.953 2.953z"
            fill="#FFB600"
          />
          <Path
            d="M6.563 3.281h9.674a2.958 2.958 0 00-2.784-1.969H4.266a2.953 2.953 0 00-2.954 2.954v9.187a2.958 2.958 0 001.97 2.784V6.563a3.281 3.281 0 013.28-3.282z"
            fill="#FFB600"
          />
        </Svg>
      </View>
    );
  },
);

export { CopyIcon };
