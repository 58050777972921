import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function ClockIcon({ size = 22 }: { size?: number }) {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 25 22"
      fill="none"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.003 20.165A9.164 9.164 0 01.836 11c0-5.06 4.107-9.167 9.167-9.167a9.164 9.164 0 019.166 9.167 9.158 9.158 0 01-9.166 9.166zm2.922-5.765a.68.68 0 00.944-.238.69.69 0 00-.239-.945l-3.263-1.943V7.04a.687.687 0 10-1.375 0v4.63c0 .238.129.458.34.586l3.593 2.145z"
        fill="#E89823"
      />
    </Svg>
  );
}

export { ClockIcon };
