import { gql, useMutation, MutationHookOptions } from '@apollo/client';

export const query = gql`
  mutation RemoveUserFromHive($hiveId: String!, $userId: String!) {
    removeUserFromHive(hiveId: $hiveId, userId: $userId)
  }
`;

export interface RequestType {
  userId: string;
  hiveId: string;
}

export interface MutationType {
  removeUserFromHive: boolean;
}

export function useRemoveUserFromHiveMutation(options?: MutationHookOptions<MutationType, RequestType>) {
  return useMutation<MutationType, RequestType>(query, options);
}
