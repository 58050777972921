import React, { ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';
import { BigRadio } from './BigRadio';
import { Divider } from './Divider';

interface IGameTypeCardProps {
  title: string,
  isActive: boolean,
  onPress: () => void,
  disabled: boolean;
  operandIcon?: ReactNode,
  isLocked?: boolean
}
const GameTypeCard = ({ title, isActive, onPress, disabled, operandIcon, isLocked }: IGameTypeCardProps) => {
  return (
    <>
      <View style={styles.container}>
        <BigRadio
          title={title}
          onPress={onPress}
          isActive={isActive}
          disabled={disabled}
          textBackground={isActive ? '#FFFFFF' : '#E4E4E4'}
          additionalIcon={operandIcon}
          isLocked={isLocked}
        />
      </View>
      <Divider empty height={12} />
    </>
  );
};

export { GameTypeCard };

const styles = StyleSheet.create({
  container: { position: 'relative', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
});
