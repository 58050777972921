import React, { useEffect, useState } from 'react';
import { Image, Pressable, StyleSheet, Text, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { Divider } from './Divider';

import { Popup } from './Popup';

const firstGif = require('../../resources/images/gif1.gif');
const secondGif = require('../../resources/images/gif2.gif');
const thirdGif = require('../../resources/images/gif3.gif');

interface IPopupProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}
const HowToPlay = ({ visible, setVisible }: IPopupProps) => {
  const [level, setLevel] = useState(1);
  useEffect(() => {
    if (visible) {
      setLevel(1);
    }
  }, [visible]);
  return (
    <Popup
      height={400}
      hasHeader={true}
      title="How to Play?"
      visible={visible}
      setVisible={setVisible}
      closeOnBackdropPress={true}
    >
      <View style={styles.container}>
        <Divider empty height={30} />
        <Image
          source={level === 1 ? firstGif : level === 2 ? secondGif : thirdGif}
          style={level === 1 ? styles.firstGif : level === 2 ? styles.secondGif : styles.thirdGif}
          resizeMode="contain"
        />
        <Text maxFontSizeMultiplier={1} style={styles.text}>
          {level === 1
            ?
            'Claim 4 cells in a row to win!'
            :
            'Claim a cell using your multiplication skills. Change one factor by TAPPING on one of the number pads'
          }
        </Text>
        <View style={styles.buttonContainer}>
          {level === 1 ? null : (
            <Pressable onPress={() => setLevel(value => value - 1)}>
              <LinearGradient style={styles.button} colors={['#FFF', '#FFF']}>
                <Text maxFontSizeMultiplier={1} style={styles.buttonText}>
                  Previous
                </Text>
              </LinearGradient>
            </Pressable>
          )}
          {level === 2 ? (
            <Pressable onPress={() => setVisible(false)}>
              <LinearGradient style={styles.button} colors={['#FFCF53', '#FF9900']}>
                <Text maxFontSizeMultiplier={1} style={styles.buttonText}>
                  Exit
                </Text>
              </LinearGradient>
            </Pressable>
          ) : (
            <Pressable onPress={() => setLevel(value => value + 1)}>
              <LinearGradient style={styles.button} colors={['#FFCF53', '#FF9900']}>
                <Text maxFontSizeMultiplier={1} style={styles.buttonText}>
                  Next
                </Text>
              </LinearGradient>
            </Pressable>
          )}
        </View>
      </View>
    </Popup>
  );
};

export { HowToPlay };

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 20,
  },
  firstGif: {
    width: '100%',
    height: 230,
  },
  secondGif: {
    width: '100%',
    height: 140,
  },
  thirdGif: {
    width: '100%',
    height: 230,
  },
  text: {
    fontFamily: 'Secular One',
    fontSize: 18,
    paddingHorizontal: 30,
    textAlign: 'center',
    color: '#121212',
  },
  buttonContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingHorizontal: 30,
  },
  button: {
    height: 40,
    width: 130,
    borderColor: '#000',
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontFamily: 'Secular One',
    fontSize: 18,
    color: '#121212',
  },
});
