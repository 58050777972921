import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path, Defs, G, ClipPath } from 'react-native-svg';

const ConfettiCannon = React.memo(({containerStyle}: {
    containerStyle?: StyleProp<ViewStyle>
}) => {
    return (
        <View style={containerStyle}>
            <Svg
                width={91}
                height={91}
                viewBox="0 0 91 91"
                fill="none"
            >
                <G clipPath="url(#clip0_2287_6941)">
                    <Path
                        d="M31.023.668a10.614 10.614 0 006.258 6.231 10.615 10.615 0 00-6.258 6.231 10.613 10.613 0 00-6.257-6.231A10.614 10.614 0 0031.023.668zM45.664 41.531a10.614 10.614 0 006.257 6.231 10.614 10.614 0 00-6.257 6.231 10.613 10.613 0 00-6.258-6.23 10.614 10.614 0 006.258-6.232zM64.71 55.082a10.614 10.614 0 006.258 6.231 10.615 10.615 0 00-6.257 6.231 10.614 10.614 0 00-6.258-6.231 10.614 10.614 0 006.258-6.231zM7.593 37.086a10.614 10.614 0 006.258 6.231 10.614 10.614 0 00-6.258 6.231 10.614 10.614 0 00-6.257-6.231 10.614 10.614 0 006.257-6.231z"
                        fill="#FEE903"
                    />
                    <Path
                        d="M1.333 63.174c4.974-.654 9.785 1.002 13.49 4.348a20.26 20.26 0 014.067 5.037c.473.835.845 1.616 1.133 2.44.139.398.26.802.36 1.212.117.481.17.791.191 1.038.03.35.033.694.023 1.044-.006.222-.039.353-.016.231a6.593 6.593 0 01-.125.542c-.035.126-.16.4-.033.131-.066.141-.143.271-.22.406-.16.28.066-.043-.065.09-.152.154-.318.287-.166.181-1.535 1.067-3.395.611-4.535-.673-.486-.547-.782-1.292-.766-1.967.015-.648.476-1.373 1.213-1.87 2.1-1.416 4.777-1.147 7.005-.2 2.87 1.221 5.313 3.441 7.242 5.853 1.927 2.409 3.457 5.277 3.94 8.348.112.71 1.003 1.11 1.64.931.758-.212 1.044-.923.93-1.64-.51-3.245-2.14-6.294-4.12-8.877-2.065-2.695-4.651-5.138-7.72-6.65-2.845-1.402-6.081-1.962-9.074-.68-2.585 1.105-4.433 3.637-3.453 6.502.835 2.44 3.3 4.222 5.89 4.185 2.191-.031 4.213-1.222 4.863-3.362.551-1.815.145-3.884-.483-5.623-.85-2.355-2.18-4.489-3.79-6.397-4.265-5.052-10.763-8.122-17.421-7.246C.619 60.602 0 61.053 0 61.84c0 .647.614 1.427 1.333 1.333zM89.66 60.508c-6.004-.79-11.877 1.621-16.119 5.834-1.805 1.794-3.37 3.914-4.438 6.23-.843 1.828-1.535 3.896-1.357 5.939.179 2.048 1.249 3.578 3.203 4.289 2.568.933 5.436-.174 6.994-2.34 1.554-2.161 1.328-5.095-.742-6.84-2.433-2.05-5.867-2.318-8.815-1.386-3.558 1.124-6.646 3.615-9.026 6.433-2.396 2.837-4.424 6.275-5.009 9.99-.112.716.184 1.426.931 1.64.632.18 1.528-.221 1.64-.931.853-5.419 4.886-10.6 9.546-13.355 2.04-1.206 4.52-2.07 6.89-1.425 1.006.273 2.093.788 2.66 1.683.415.656.42 1.316.078 2.113-.595 1.388-2.39 2.415-4.011 1.983-.374-.1-.704-.251-1.027-.463-.252-.166.158.144.02.012-.054-.052-.111-.1-.162-.155-.233-.254.056.153-.126-.155a5.906 5.906 0 01-.2-.364c.06.12.002.042-.053-.174-.037-.146-.073-.292-.106-.44-.018-.078-.044-.32-.008-.008a3.972 3.972 0 01-.027-.325c-.029-.906.013-1.228.198-2.016.198-.837.497-1.68.81-2.36.89-1.927 2.111-3.69 3.57-5.227 3.835-4.042 9.1-6.25 14.686-5.515.715.094 1.333-.68 1.333-1.333 0-.8-.616-1.24-1.333-1.334z"
                        fill="#BBEC6C"
                    />
                    <Path
                        d="M27.876 68.268a3.728 3.728 0 003.736-3.72 3.728 3.728 0 00-3.736-3.72 3.728 3.728 0 00-3.735 3.72 3.728 3.728 0 003.735 3.72z"
                        fill="#AF66DA"
                    />
                    <Path
                        d="M27.879 60.828a3.73 3.73 0 00-1.777.447 3.717 3.717 0 011.958 3.273 3.717 3.717 0 01-1.958 3.272 3.73 3.73 0 001.777.448 3.728 3.728 0 003.736-3.72 3.728 3.728 0 00-3.736-3.72z"
                        fill="#925CB4"
                    />
                    <Path
                        d="M56.939 30.87a3.728 3.728 0 003.736-3.72 3.728 3.728 0 00-3.736-3.72 3.728 3.728 0 00-3.736 3.72 3.728 3.728 0 003.736 3.72z"
                        fill="#FE5694"
                    />
                    <Path
                        d="M56.934 23.43c-.644 0-1.25.162-1.778.447a3.717 3.717 0 011.959 3.273 3.717 3.717 0 01-1.959 3.272 3.731 3.731 0 001.778.448 3.728 3.728 0 003.735-3.72 3.728 3.728 0 00-3.735-3.72z"
                        fill="#FE2AA8"
                    />
                    <Path
                        d="M85.939 43.33a3.728 3.728 0 003.736-3.72 3.728 3.728 0 00-3.736-3.72 3.728 3.728 0 00-3.736 3.72 3.728 3.728 0 003.736 3.72z"
                        fill="#FE5694"
                    />
                    <Path
                        d="M85.934 35.89c-.644 0-1.25.163-1.778.448a3.717 3.717 0 011.959 3.273 3.717 3.717 0 01-1.959 3.272 3.731 3.731 0 001.778.447 3.728 3.728 0 003.735-3.72 3.728 3.728 0 00-3.735-3.72z"
                        fill="#FE2AA8"
                    />
                    <Path
                        d="M24.15 54.706a3.728 3.728 0 003.736-3.72 3.728 3.728 0 00-3.736-3.72 3.728 3.728 0 00-3.736 3.72 3.728 3.728 0 003.736 3.72z"
                        fill="#FE5694"
                    />
                    <Path
                        d="M24.137 47.266a3.73 3.73 0 00-1.778.447 3.717 3.717 0 011.959 3.273 3.717 3.717 0 01-1.959 3.272 3.73 3.73 0 001.778.448 3.728 3.728 0 003.735-3.72 3.728 3.728 0 00-3.735-3.72z"
                        fill="#FE2AA8"
                    />
                    <Path
                        d="M82.205 25.405a3.728 3.728 0 003.735-3.72 3.728 3.728 0 00-3.735-3.72 3.728 3.728 0 00-3.736 3.72 3.728 3.728 0 003.736 3.72z"
                        fill="#AF66DA"
                    />
                    <Path
                        d="M82.207 17.965a3.73 3.73 0 00-1.777.447 3.717 3.717 0 011.958 3.273 3.717 3.717 0 01-1.958 3.272 3.73 3.73 0 001.777.448 3.728 3.728 0 003.736-3.72 3.728 3.728 0 00-3.736-3.72z"
                        fill="#925CB4"
                    />
                    <Path
                        d="M41.114 29.73c-3.17 0-5.733-2.553-5.733-5.708l-5.99-.19c0 6.557 5.328 11.864 11.913 11.864l-.19-5.965z"
                        fill="#C4E2FF"
                    />
                    <Path
                        d="M31.826 24.022c0 3.155 2.564 5.709 5.733 5.709l.173 5.422c1.128.352 2.328.543 3.572.543l-.19-5.965c-3.17 0-5.733-2.554-5.733-5.709l-5.99-.19c0 .038.002.075.003.113l2.432.077z"
                        fill="#B3DAFE"
                    />
                    <Path
                        d="M71.414 49.35c3.165.157 5.6 2.834 5.444 5.985l5.973.486c.324-6.55-4.735-12.112-11.312-12.438l-.105 5.967z"
                        fill="#C4E2FF"
                    />
                    <Path
                        d="M71.52 43.383l-.012.718c4.67 1.716 7.91 6.241 7.777 11.432l3.548.288c.324-6.55-4.735-12.112-11.313-12.438z"
                        fill="#B3DAFE"
                    />
                    <Path
                        d="M51.72 5.613c0 3.169-2.554 5.733-5.71 5.733l-.19 5.99c6.558 0 11.864-5.329 11.864-11.914l-5.965.19z"
                        fill="#C4E2FF"
                    />
                    <Path
                        d="M54.126 5.536a11.903 11.903 0 01-8.288 11.25l-.018.55c6.558 0 11.864-5.329 11.864-11.914l-3.558.114z"
                        fill="#B3DAFE"
                    />
                    <Path
                        d="M43.28 76.81C41.496 64.108 38.11 51.116 31.1 40.221a50.148 50.148 0 00-4.02-5.445c-2.412-2.823-5.495-5.547-9.322-6.013-1.617-.197-3.527-.007-4.656 1.309-1.288 1.502-.751 3.648.706 4.81 3.116 2.483 7.133-.36 8.33-3.476 1.662-4.322-.452-9.429-2.524-13.239-2.058-3.786-4.891-7.065-8.302-9.688-.575-.443-1.487-.084-1.824.478-.409.683-.089 1.387.479 1.824 2.578 1.982 4.768 4.481 6.479 7.24 1.772 2.86 3.47 6.325 3.584 9.745.05 1.528-.258 3.118-1.349 4.255-.748.78-2.062 1.555-3.117.861-.341-.223-.713-.69-.436-1.088.298-.427 1.13-.433 1.587-.435 1.498-.004 2.897.553 4.15 1.34 2.38 1.491 4.425 3.947 5.922 5.958A53.445 53.445 0 0129.69 43c2.381 3.956 4.291 8.179 5.837 12.526 2.529 7.112 4.133 14.526 5.18 21.992.1.711 1.011 1.107 1.64.93.767-.214 1.032-.92.931-1.639zM50.828 77.517c1.815-11.602 4.752-23.465 10.323-33.886 2.136-3.997 4.641-7.937 8.396-10.556 1.175-.819 2.496-1.501 3.937-1.677.558-.068 2.743-.204 2.437.881-.335 1.186-1.982.91-2.75.451-1.227-.733-1.919-2.057-2.142-3.436-.579-3.575 1.315-7.526 3.065-10.534 1.778-3.057 4.114-5.82 6.922-7.98.575-.442.867-1.141.479-1.823-.322-.565-1.255-.917-1.824-.479a30.759 30.759 0 00-7.435 8.197c-2.003 3.234-3.76 7.083-3.94 10.933-.161 3.411 1.625 7.493 5.363 8.113 1.688.28 3.472-.329 4.414-1.82 1.016-1.609.533-3.64-1.119-4.59-2.983-1.72-6.988.09-9.42 1.957-2.442 1.875-4.467 4.306-6.19 6.847-2.899 4.274-5.019 9.087-6.817 13.91-2.982 7.999-4.951 16.358-6.27 24.783-.112.716.184 1.426.931 1.64.632.18 1.529-.22 1.64-.931z"
                        fill="#BBEC6C"
                    />
                    <Path
                        d="M67.733 10.271c1.715 0 1.718-2.666 0-2.666-1.716 0-1.718 2.666 0 2.666zM37.163 17.553c1.715 0 1.718-2.666 0-2.666-1.716 0-1.719 2.666 0 2.666zM46.053 25.557c1.716 0 1.719-2.666 0-2.666-1.715 0-1.718 2.666 0 2.666zM52.624 39.947c1.715 0 1.718-2.666 0-2.666-1.716 0-1.719 2.666 0 2.666zM65.6 47.057c1.716 0 1.719-2.666 0-2.666-1.715 0-1.718 2.666 0 2.666zM86.749 73.006c1.715 0 1.718-2.666 0-2.666-1.716 0-1.719 2.666 0 2.666zM65.42 87.936c1.716 0 1.719-2.667 0-2.667-1.715 0-1.718 2.666 0 2.666zM45.694 87.58c1.715 0 1.718-2.666 0-2.666-1.716 0-1.718 2.666 0 2.666zM34.14 75.322c1.715 0 1.718-2.666 0-2.666-1.716 0-1.72 2.666 0 2.666zM14.061 58.787c1.716 0 1.719-2.666 0-2.666-1.715 0-1.718 2.666 0 2.666zM8.014 23.596c1.716 0 1.719-2.666 0-2.666-1.715 0-1.718 2.666 0 2.666zM88.17 31.416c1.716 0 1.719-2.666 0-2.666-1.715 0-1.718 2.666 0 2.666zM25.249 88.475c1.715 0 1.718-2.666 0-2.666-1.716 0-1.719 2.666 0 2.666zM3.21 73.361c1.715 0 1.718-2.666 0-2.666-1.716 0-1.719 2.666 0 2.666z"
                        fill="#FFC344"
                    />
                </G>
                <Defs>
                    <ClipPath id="clip0_2287_6941">
                        <Path fill="#fff" d="M0 0H91V91H0z" />
                    </ClipPath>
                </Defs>
            </Svg>
        </View>
    );
});

export default ConfettiCannon;
