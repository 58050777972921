import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, Image, View, Platform } from 'react-native';
import { ScreenProps } from '../../../typesInterfacesEnums/componentProps';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { Divider } from '../../helperComponents/Divider';
import { ErrorPopup } from '../../helperComponents/ErrorPopup';
import { applyTransparency } from '../../../constants/colors';
import { useSignedInContext } from '../../../context/SignedInContext';
import { DropdownMenu } from '../../helperComponents/DropdownMenu/DropdownMenu';
import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { USER_TYPES } from '../../../typesInterfacesEnums/enums';
import AsyncStorage from '@react-native-async-storage/async-storage';
import LinearGradient from 'react-native-linear-gradient';
import { MainLobby } from './MainLobby';
import { PlayAFriendLobby } from './PlayAFriendLobby';
import { BackButton } from '../../helperComponents/BackButton';
import { YourPoints } from '../../helperComponents/YourPoints';
import { textStyles } from '../../../constants/textStyles';
import { getVersionNumber } from '../../../utils/getVersionNumber/getVersionNumber';
import { useGetBackendVersion } from '../../../hooks/serverUtilHooks';
import { envs } from '../../../../env';

const versions = getVersionNumber();

const hiveIcon = require('../../../resources/images/hiveIcon.png');

const Lobby = ({ navigation, route }: ScreenProps<'Lobby'>) => {
  const { isSignedIn, user } = useSignedInContext();
  const [playingName, setPlayingName] = useState<string | undefined>(undefined);

  const { data: serverVersion } = useGetBackendVersion();

  const routeParams = route.params;

  useEffect(() => {
    if (isSignedIn) {
      if (user) {
        if (user.userType === USER_TYPES.teacher) {
          setPlayingName(user.firstName);
        } else {
          setPlayingName(user.email);
        }
      }
    } else {
      AsyncStorage.getItem('playing-name').then((res) => {
        if (res) {
          setPlayingName(res);
        }
        else {
          setPlayingName(undefined);
        }
      });
    }
  }, [isSignedIn, user]);


  const [hasError, setHasError] = useState({
    visible: false,
    popup: false,
    text: '',
  });

  return (
    <>
      <ComponentWrapper hasInset hasScroll={false}>
        {/*<ResultPopup visible={true} setVisible={()=>{}} onPressAction={()=>{}} isWinner={false} isDraw={false} isPassAndPlay={false}*/}
        {/*             handlePlayAgainPress={()=>{}} anotherPlayerLeft={false} winnerName={undefined}/>*/}

        {routeParams?.playAFriend && <Divider empty height={40} />}
        {user ? <YourPoints points={user?.points} /> : null}
        <Divider empty height={40} />
        <Image style={styles.image} source={hiveIcon} resizeMode="contain" />
        <Divider empty height={40} />

        {routeParams?.playAFriend ? <PlayAFriendLobby navigation={navigation} playingName={playingName} /> :
          <MainLobby navigation={navigation} playingName={playingName} />}

        <View style={{ position: 'absolute', right: 0, bottom: 0, paddingEnd: 8, paddingBottom: 4, alignItems: 'flex-start', justifyContent: 'flex-start' }}>
          <Text style={styles.versionInfoText}>JS Version: {versions.jsVersion}({envs.ENV.charAt(0).toUpperCase()})</Text>
          {Platform.OS !== 'web' &&
            <Text style={styles.versionInfoText}>App Version: {`${versions.appVersion}(${versions.buildVersion})`}</Text>
          }
          <Text style={styles.versionInfoText}>Server Version: {serverVersion}</Text>
        </View>
      </ComponentWrapper>
      {
        isSignedIn ?
          <>
            <DropdownMenu backButton={
              routeParams?.playAFriend ? <BackButton onPress={() => navigation.navigate('Lobby')} text="Back" /> : undefined}
            />
            <TouchableOpacity style={[styles.myHivesContainer, {
              top: routeParams?.playAFriend ? 112 : 60,
            }]}
              onPress={() => navigation.navigate('MyHives')}>
              <LinearGradient style={styles.nameContainer} colors={['#FFCF53', '#FF9900']}>
                <Text maxFontSizeMultiplier={1} style={styles.myHivesText}>
                  My Hives
                </Text>
              </LinearGradient>
            </TouchableOpacity>

          </>
          :
          <View style={styles.loginContainer}>
            {routeParams?.playAFriend && <>

              <BackButton onPress={() => navigation.navigate('Lobby')} text="Back" />
              <Divider empty height={20} />
            </>}
            <TouchableOpacity onPress={() => navigation.navigate('SignIn')}>
              <LinearGradient style={styles.nameContainer} colors={['#FFCF53', '#FF9900']}>
                <Text maxFontSizeMultiplier={1} style={styles.loginText}>
                  Login
                </Text>
              </LinearGradient>
            </TouchableOpacity>

          </View>

      }
      <ErrorPopup
        isVisible={hasError.popup}
        setVisible={() => setHasError(v => ({ ...v, popup: false }))}
        text={hasError.text}
      />
    </>
  );
};

export { Lobby };

const styles = StyleSheet.create({
  image: { width: 180, height: 100 },
  howToPlayText: {
    fontFamily: 'Secular One',
    fontSize: 20,
    color: 'black',
    textDecorationLine: 'underline',
  },
  loginContainer: {
    alignItems: 'center',
    ...mbPlatformStyle({
      mobile: {
        left: 20,
      },
      web: {
        left: 60,
      },
    }),
    position: 'absolute',
    top: 60,
  },
  loginText: {
    fontFamily: 'Secular One',
    marginLeft: 6,
    fontSize: 20,
    marginTop: 4,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
  },
  myHivesContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...mbPlatformStyle({
      mobile: {
        right: 20,
      },
      web: {
        right: 60,
      },
    }),
    position: 'absolute',
    top: 60,
  },
  myHivesText: {
    fontFamily: 'Secular One',
    marginRight: 6,
    fontSize: 20,
    marginTop: 4,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
  },
  nameContainer: {
    borderRadius: 12,
    width: 140,
    height: 40,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  name: {
    fontFamily: 'Secular One',
    fontSize: 18,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
  },
  logoutText: {
    marginLeft: 4,
    fontFamily: 'Secular One',
    fontSize: 20,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
  },
  versionInfoText: {
    ...mbTextStyles({
      ...textStyles.smallerText,
      alignSelf: 'flex-start',
      color: 'black',
      fontSize: 10,
      lineHeight: 12,
    }, { allowLineHeight: true }),
  },
});
