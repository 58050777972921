import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const mutation = gql`
  mutation signup($firstName: String!, $lastName: String!, $userType: String!, $emailOrUsername: String!, $password: String!, $signUpCode: String!, $hiveKey: String) {
    signup(firstName: $firstName, lastName: $lastName, userType: $userType, emailOrUsername: $emailOrUsername, password: $password, signUpCode: $signUpCode, hiveKey: $hiveKey) {
      accessToken {
          token
          expiresIn
          expiresInMs
          expiresAt
          expiresAtMs
      }
      refreshToken
      password
    }
  }
`;

export interface RequestType {
  firstName: string;
  lastName: string;
  userType: string;
  emailOrUsername: string;
  password: string;
  signUpCode: string;
  hiveKey?: string;
}

export interface MutationType {
  signup: SignupResponseType;
}

export interface SignupResponseType {
  accessToken: {
    token: string;
    expiresIn: string;
    expiresInMs: number;
    expiresAt: string;
    expiresAtMs: number;
    __typename?: string;
  };
  refreshToken: string;
  password: string;
  __typename?: 'SignupResponse';
}

export function useSignUpMutation(options?: MutationHookOptions<MutationType, RequestType>) {
  return useMutation<MutationType, RequestType>(mutation, options);
}
