import React, { useMemo } from 'react';
import { StyleProp, StyleSheet, Text, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { HiveSubscriptionType } from '../../../constants/constants';
import LinearGradient from 'react-native-linear-gradient';
import { applyTransparency } from '../../../constants/colors';
import { CheckMarkIcon } from '../../../resources/svgComponents/CheckMarkIcon';
import { SaveTwentyPercent } from '../../../resources/svgComponents/SaveTwentyPercent';

type HivePurchaseCard = {
    colors: [string, string] | string[];
    name: string;
    description: string;
    prices: Record<HiveSubscriptionType, number | string>;
    subscriptionType: HiveSubscriptionType | undefined;
    features: string[];
    onPress?: () => void
    style?: StyleProp<ViewStyle>
    headerStyle?: StyleProp<ViewStyle>,
    titleTextStyle?: StyleProp<TextStyle>,
    descriptionTextStyle?: StyleProp<TextStyle>,
    priceTextStyle?: StyleProp<TextStyle>,
    priceSuffixTextStyle?: StyleProp<TextStyle>,
    featureText?: StyleProp<TextStyle>,
    checkMarkSize?: number,
    hideBuyButton?: boolean,
}

const HivePurchaseCard = ({
    colors,
    name,
    description,
    prices,
    subscriptionType,
    features,
    onPress,
    style,
    headerStyle,
    titleTextStyle,
    descriptionTextStyle,
    priceTextStyle,
    priceSuffixTextStyle,
    featureText,
    checkMarkSize,
    hideBuyButton,
}: HivePurchaseCard) => {

    const subscriptions = useMemo(() => subscriptionType ? [subscriptionType] : Object.values(HiveSubscriptionType), [subscriptionType]);

    return (
        <View style={[styles.container, { borderColor: colors[0] }, style]}>
            <LinearGradient colors={colors} style={[styles.header, headerStyle]}>
                <Text style={[styles.nameText, titleTextStyle]}>{name}</Text>
                <Text style={[styles.descriptionText, descriptionTextStyle]}>{description}</Text>
                {subscriptions.map(subscription => (
                    <View key={subscription} style={styles.subscription}>
                        <Text style={[styles.priceText, priceTextStyle]}>${prices[subscription]}<Text style={[styles.priceNoteText, priceSuffixTextStyle]}>/{subscription === HiveSubscriptionType.month ? 'monthly' : 'yearly'}</Text></Text>
                        {subscription === HiveSubscriptionType.year ? <SaveTwentyPercent /> : subscriptions.length === 1 && <View style={styles.emptyIcon} />}
                    </View>)
                )}
            </LinearGradient>
            <View style={styles.body}>
                <View style={styles.features}>
                    {features.map((feature, index) => (
                        <View key={`${feature}-${index}`} style={styles.feature}>
                            <CheckMarkIcon size={checkMarkSize} colors={colors as [string, string]} />
                            <Text style={[styles.featureText, featureText]}>{feature}</Text>
                        </View>
                    ))}
                </View>
                <View style={styles.spacer} />
                {!hideBuyButton &&
                    <TouchableOpacity onPress={onPress}>
                        <LinearGradient style={styles.buyNowBtn} colors={['#FFCF53', '#FF9900']}>
                            <Text maxFontSizeMultiplier={1} style={styles.buyNowBtnText}>
                                Buy Now
                            </Text>
                        </LinearGradient>
                    </TouchableOpacity>
                }
            </View>
        </View>
    );
};

export { HivePurchaseCard };

const styles = StyleSheet.create({
    container: {
        borderWidth: 1.28,
        width: 243,
        height: 358,
        borderRadius: 16,
        overflow: 'hidden',
    },
    header: {
        height: 139,
        justifyContent: 'center',
        paddingHorizontal: 13.4,
    },
    nameText: {
        fontFamily: 'Secular One',
        fontSize: 17,
        textShadowColor: applyTransparency('#000000', 0.15),
        textShadowOffset: { width: 0, height: 1 },
        textShadowRadius: 0,
        color: '#000000',
        fontWeight: '400',
    },
    descriptionText: {
        fontFamily: 'Secular One',
        fontSize: 10,
        color: '#000000',
        fontWeight: '500',
        marginRight: 20,
    },
    priceText: {
        fontFamily: 'Secular One',
        fontSize: 16,
        textShadowColor: applyTransparency('#000000', 0.15),
        textShadowOffset: { width: 0, height: 1 },
        textShadowRadius: 0,
        color: '#000000',
        fontWeight: '400',
        marginEnd: 4,
    },
    priceNoteText: {
        fontFamily: 'Secular One',
        fontSize: 9,
        color: '#000000',
        fontWeight: '500',
    },
    body: {
        flex: 1,
        paddingTop: 8,
        paddingBottom: 20,
    },
    features: {
        paddingLeft: 13.4,
    },
    feature: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 12,
    },
    featureText: {
        fontFamily: 'Secular One',
        fontSize: 9,
        color: '#000000',
        fontWeight: '500',
        marginStart: 7,
    },
    spacer: {
        flex: 1,
    },
    buyNowBtn: {
        alignSelf: 'center',
        height: 34,
        width: 216,
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 2.54,
        borderColor: '#FFDB84',
        borderRadius: 9,
    },
    buyNowBtnText: {
        fontFamily: 'Secular One',
        fontWeight: '500',
        fontSize: 13,
        color: '#000000',
    },
    subscription: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    emptyIcon: {
        height: 32,
        width: 32,
    },
});
