import React, { useCallback, useEffect, useState } from 'react';
import { Image, Platform, StyleSheet, Text, View } from 'react-native';
import { applyTransparency } from '../../../constants/colors';
import { VSIcon } from '../../../resources/svgComponents/VsIcon';
import { ScreenProps } from '../../../typesInterfacesEnums/componentProps';
import { utils } from '../../../utils/utils';
import { BackButton } from '../../helperComponents/BackButton';
import CircularProgress from '../../helperComponents/Circular';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { DismissKeyboard } from '../../helperComponents/DismissKeyboard';
import { Divider } from '../../helperComponents/Divider';
import { ErrorPopup } from '../../helperComponents/ErrorPopup';
import { MenuButton } from '../../helperComponents/MenuButton';
import { Popup } from '../../helperComponents/Popup';
import { ProfileName } from '../../helperComponents/ProfileName';
import { GameMode } from '../GameScreen';
import { useGameEventSubscription } from '../JoinCode/useGameEventSubscription.gql';
import { EventTypeGameEventType } from './useGameEventSubscription.gql';
import { DropdownMenu } from '../../helperComponents/DropdownMenu/DropdownMenu';
import { useInviteToGameMutation } from './useInviteToGameMutation.gql';
import { ApolloError } from '@apollo/client';
import { useOpenClose } from '../../../utils/hooks';
import { useEndWaitingGameMutation } from './useEndWaitingMutation.gql';
import { getErrorText } from '../../../utils/errors';

const vsImage = require('../../../resources/images/vsImage.png');
let interval: any = null;

const WaitingForHiveMate = ({ navigation, route }: ScreenProps<'WaitingForHiveMate'>) => {
  const { userName, gameType, inviteeUserId, inviteeUsername, hiveDetail } = route.params;
  const [inviteToGame] = useInviteToGameMutation();
  const [endWaitingGame] = useEndWaitingGameMutation();
  const [isPopupVisible, setPopupOpen, setPopupClose] = useOpenClose();
  const [isTimerEnded, setTimerEnded] = useState(false);
  const { data: gameEventSubscription } = useGameEventSubscription();
  const [timer, setTimer] = useState(0);
  const [gameId, setGameId] = useState<string | undefined>(undefined);
  const [isDeclined, setIsDeclined] = useState(false);

  const [hasError, setHasError] = useState({
    visible: false,
    popup: false,
    text: '',
  });

  const restartTimer = () => {
    setTimer(0);
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(() => {
      setTimer(value => value + 1);
    }, 1000);
  };

  const navigateToGameScreen = useCallback(() => {
    if (gameEventSubscription) {
      setPopupClose();
      navigation.navigate('Game', {
        game: gameEventSubscription.gameEvent.game,
        mode: GameMode.HOST_A_GAME,
      });
    }
  }, [gameEventSubscription, navigation, setPopupClose]);

  const handleInviteError = (err: ApolloError) => {
    setHasError({ popup: true, visible: true, text: getErrorText(err) });
  };

  const handleEndEndWaiting = () => {
    if (gameId) {
      endWaitingGame({ variables: { gameId: gameId } });
    }
    setPopupClose();
    navigation.reset({ index: 2, routes: [{ name: 'Lobby' }, { name: 'MyHives' }, { name: 'MyHiveDataStudent', params: hiveDetail }] });
  };
  useEffect(() => {
    if (
      gameEventSubscription?.gameEvent.game.id === gameId &&
      gameEventSubscription?.gameEvent.eventType === EventTypeGameEventType.ON_GAME_START
    ) {
      clearInterval(interval);
      setTimeout(navigateToGameScreen, 1000);
    }
    if (
      gameEventSubscription?.gameEvent.game.id === gameId &&
      gameEventSubscription?.gameEvent.eventType === EventTypeGameEventType.ON_USER_LEFT
    ) {
      clearInterval(interval);
      setIsDeclined(true);
    }
    if (gameId && gameEventSubscription?.gameEvent && gameEventSubscription?.gameEvent.game.id !== gameId) {
      console.warn('ignore events from another game', gameEventSubscription?.gameEvent.eventType, gameEventSubscription?.gameEvent.game.id, gameId);
    }
  }, [gameEventSubscription, gameId, hiveDetail, navigateToGameScreen, navigation, setPopupClose]);


  useEffect(() => {
    if (timer > 59) {
      setTimerEnded(true);
      clearInterval(interval);
      if (gameId) {
        endWaitingGame({ variables: { gameId: gameId } });
      }
    }
  }, [timer, endWaitingGame, gameId]);

  useEffect(() => {
    if (isPopupVisible) {
      restartTimer();
      setIsDeclined(false);
    }
  }, [isPopupVisible]);


  useEffect(() => {
    inviteToGame({ variables: { gameType, inviteeUserId, userName, hiveName: hiveDetail.name } }).then((res) => {
      if (res.data) {
        setGameId(res.data.inviteToGame.game.id);
      }
      setPopupOpen();
    }).catch((error: ApolloError) => {
      setPopupClose();
      handleInviteError(error);
      setTimeout(() => {
        navigation.reset({ index: 2, routes: [{ name: 'Lobby' }, { name: 'MyHives' }, { name: 'MyHiveDataStudent', params: hiveDetail }] });
      }, 1000);
    });
  }, [gameType, inviteToGame, inviteeUserId, setPopupOpen, userName, navigation, setPopupClose, hiveDetail.name, hiveDetail]);

  return (
    <>
      <DismissKeyboard>
        <ComponentWrapper hasInset hasScroll={false} style={{ paddingHorizontal: 0 }}>
          <DropdownMenu
            backButton={
              <BackButton
                text="Back"
                onPress={() => navigation.reset({ index: 1, routes: [{ name: 'Lobby' }, { name: 'MyHives' }] })}
              />
            }
          />
        </ComponentWrapper>
      </DismissKeyboard>
      <Popup title="Waiting..." visible={isPopupVisible} setVisible={handleEndEndWaiting} height={384} closeOnBackdropPress={false}>
        <View style={styles.popupContainer}>
          <Divider empty height={32} />
          <View style={styles.popupInnerContainer}>
            <ProfileName gradientColors={['#FFCF53', '#FF9900']} name={userName} />
            {Platform.OS === 'web' ? (
              <Image
                source={vsImage}
                style={{ width: 54, height: 54, marginTop: 20 }}
                resizeMode="contain"
              />
            ) : (
              <VSIcon containerStyle={styles.vsIcon} />
            )}
            <ProfileName gradientColors={['#FF9252', '#FF3F15']} name={inviteeUsername} />
          </View>
          <Divider empty height={30} />
          {
            isDeclined &&
            <>
              <Text style={styles.popupInnerJoinText}>
                User declined joining your game
              </Text>
              <Divider empty height={30} />
            </>
          }
          {isTimerEnded || isDeclined ? (
            <MenuButton
              onPress={() => navigation.reset({ index: 2, routes: [{ name: 'Lobby' }, { name: 'MyHives' }, { name: 'MyHiveDataStudent', params: hiveDetail }] })}
              colors={['#747474', '#1F1F1F']}
              text="Back to hive"
              textStyle={{ fontSize: 16 }}
              height={60}
              width={226}
            />
          ) : (
            <>
              <CircularProgress progress={60 - timer} size={80} fontSize={20} />
              <Divider empty height={16} />
              <Text maxFontSizeMultiplier={1} style={styles.waiting}>Waiting for friends...</Text>
            </>
          )}
        </View>
      </Popup>
      <ErrorPopup
        isVisible={hasError.popup}
        setVisible={() => setHasError(v => ({ ...v, popup: false }))}
        text={hasError.text}
      />
    </>
  );
};

export { WaitingForHiveMate };

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Secular One',
    fontSize: 32,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
  },
  popupContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    ...utils.getShadow({
      color: '#F18C8C',
      radius: 20,
      opacity: 0.2,
      offsetWidth: 0,
      offsetHeight: 2,
      elevation: 0,
    }),
  },
  popupInnerContainer: {
    width: '100%',
    // maxWidth: 290,
    padding: 12,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
  },
  popupInnerTopContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  popupInnerJoinText: {
    fontSize: 18,
    fontFamily: 'Secular One',
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
  },
  waiting: {
    fontSize: 20,
    fontFamily: 'Secular One',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
    color: '#000000',
  },
  vsIcon: {
    ...utils.getShadow({
      color: '#000000',
      offsetHeight: 2,
      offsetWidth: 0,
      radius: 4,
      opacity: 0.2,
      elevation: 0,
    }),
  },
});
