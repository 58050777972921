
import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';

const InfoIcon = React.memo(
  (props: {
    width?: number;
    height?: number;
    colors?: [string, string];
    transparent?: boolean;
    containerStyle?: StyleProp<ViewStyle>;
  }) => {
    return (
      <View style={props.containerStyle}>
        <Svg
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
        >
          <G stroke="#FF9700" strokeWidth={1.125} fill="none" fillRule="evenodd">
            <Path
              d="M8.25 8.25a3 3 0 114 2.829 1.5 1.5 0 00-1 1.415V13.5M11.25 16.5a.375.375 0 100 .75.375.375 0 000-.75z"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform="translate(.75 .75)"
            />
            <Path
              d="M0 11.25a11.25 11.25 0 1022.5 0 11.25 11.25 0 00-22.5 0h0z"
              transform="translate(.75 .75)"
            />
          </G>
        </Svg>
      </View>
    );
  },
);

export { InfoIcon };
