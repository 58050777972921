import { QueryHookOptions, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const query = gql`
  query CurrentUser {
    currentUser {
      id
      firstName
      lastName
      userType
      email
      userPassword
      customerId
      displayName
        points
    }
  }
`;

export interface CurrentUserType {
  id: string;
  firstName: string;
  lastName: string;
  userType: string;
  email: string;
  userPassword: string;
  customerId?: string;
  points?: number;
  displayName?: string;
}

export interface QueryType {
  currentUser: CurrentUserType;
}

export function useCurrentUserQuery(options?: QueryHookOptions<QueryType>) {
  return useQuery<QueryType>(query, options);
}
