import React from 'react';
import { StyleSheet, View } from 'react-native';
import { HiveSize, HiveSubscriptionType } from '../../constants/constants';
import { HivePurchaseCard } from '../screens/CreateHive/HivePurchaseCard';

export const HIVES = {
  [HiveSize.SingleUser]: {
    colors: ['#FFCF53', '#FF9900'],
    name: 'Single Hive',
    description: 'Perfect for playing online or inviting a friend',
    prices: {
      [HiveSubscriptionType.month]: '1.99',
      [HiveSubscriptionType.year]: '19.10',
    },
    features: ['Single player', 'Access all game boards'],
    capacity: 1,
  },
  [HiveSize.FamilyHive]: {
    colors: ['#FF9252', '#FF3F15'],
    name: 'Group hive',
    description: 'Perfect for families, homeschoolers, tutoring or small class groups',
    prices: {
      [HiveSubscriptionType.month]: '2.99',
      [HiveSubscriptionType.year]: '28.70',
    },
    features: ['Up to 7 players', 'Access all game boards', 'Hive leaderboard', 'User analytics'],
    capacity: 7,
  },
  [HiveSize.ClassHive]: {
    colors: ['#90DF75', '#62B655'],
    name: 'Class hive',
    description: 'Perfect for primary and high school teachers',
    prices: {
      [HiveSubscriptionType.month]: '5.99',
      [HiveSubscriptionType.year]: '57.50',
    },
    features: ['Up to 40 players', 'Access all game boards', 'Hive leaderboard', 'User analytics'],
    capacity: 40,
  },
  // [HiveSize.SchoolHive]: {
  //   name: 'School hive',
  //   capacity: '5000',
  //   price: 19.99,
  // },
};

type PremiumPlansProps = {
  subscriptionType?: HiveSubscriptionType
  onSelectHive?: (hiveSize: HiveSize) => void
};

const PremiumPlans = ({ subscriptionType, onSelectHive }: PremiumPlansProps) => {

  return (
    <View style={styles.premiumPlansRow}>
      <HivePurchaseCard
        colors={HIVES.SingleUser.colors}
        name={HIVES.SingleUser.name}
        description={HIVES.SingleUser.description}
        prices={HIVES.SingleUser.prices}
        subscriptionType={subscriptionType}
        features={HIVES.SingleUser.features}
        onPress={() => onSelectHive?.(HiveSize.SingleUser)}
      />
      <HivePurchaseCard
        colors={HIVES.FamilyHive.colors}
        name={HIVES.FamilyHive.name}
        description={HIVES.FamilyHive.description}
        prices={HIVES.FamilyHive.prices}
        subscriptionType={subscriptionType}
        features={HIVES.FamilyHive.features}
        onPress={() => onSelectHive?.(HiveSize.FamilyHive)}
        style={styles.centeredCard}
      />
      <HivePurchaseCard
        colors={HIVES.ClassHive.colors}
        name={HIVES.ClassHive.name}
        description={HIVES.ClassHive.description}
        prices={HIVES.ClassHive.prices}
        subscriptionType={subscriptionType}
        features={HIVES.ClassHive.features}
        onPress={() => onSelectHive?.(HiveSize.ClassHive)}
      />
    </View>
  );
};

export { PremiumPlans };

const styles = StyleSheet.create({
  premiumPlansRow: {
    flexDirection: 'row',
  },
  responsivePremiumColumn: {
    flexDirection: 'column',
  },
  centeredCard: {
    marginHorizontal: 17,
  },
  middleCard: {
    marginVertical: 17,
  },
});
