import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Image } from 'react-native';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { Divider } from '../../helperComponents/Divider';
import { applyTransparency } from '../../../constants/colors';
import { MenuButton } from '../../helperComponents/MenuButton';
import { BackButton } from '../../helperComponents/BackButton';
import { ScreenProps } from '../../../typesInterfacesEnums/componentProps';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import { USER_TYPES } from '../../../typesInterfacesEnums/enums';

const studentIcon = require('../../../resources/images/student.png');
const teacherIcon = require('../../../resources/images/teacher.png');
const studentNotActiveIcon = require('../../../resources/images/student-not-active.png');
const teacherNotActiveIcon = require('../../../resources/images/teacher-not-active.png');

const ChooseUserType = ({ route, navigation }: ScreenProps<'ChooseUserType'>) => {
  const [userType, setUserType] = useState<USER_TYPES>(USER_TYPES.student);

  useEffect(() => {
    if (route.params?.userType) {
      setUserType(route.params?.userType);
    }
  }, [route.params?.userType]);

  return (
    <ComponentWrapper
      hasInset
      hasScroll={false}
      scrollEnabled={false}
      style={{ paddingHorizontal: 0 }}
    >
      <View style={styles.topContainer}>
        <BackButton onPress={() => navigation.goBack()} text="Back" />
      </View>
      <Text maxFontSizeMultiplier={1} style={styles.title}>
        Choose User Type
      </Text>
      <Divider empty height={20} />
      <Text maxFontSizeMultiplier={1} style={styles.subtitle}>
        Who are you signing up as?
      </Text>
      <Divider empty height={40} />
      <View style={styles.row}>
        <TouchableOpacity style={styles.card} onPress={() => setUserType(USER_TYPES.student)}>
          <Image source={userType === USER_TYPES.student ? studentIcon : studentNotActiveIcon} style={styles.bee} />
          <Text style={[styles.cardText, userType === USER_TYPES.student ? styles.active : null]}>Student</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.card} onPress={() => setUserType(USER_TYPES.teacher)}>
          <Image source={userType === USER_TYPES.teacher ? teacherIcon : teacherNotActiveIcon} style={styles.bee} />
          <Text style={[styles.cardText, userType === USER_TYPES.teacher ? styles.active : null]}>Teacher/Admin</Text>
        </TouchableOpacity>
      </View>
      <Divider empty height={48} />
      <MenuButton
        onPress={() => navigation.push('SignUp', { userType: userType })}
        colors={['#90DF75', '#62B655']}
        text="Next"
        height={64}
        width={200}
      />
      <Divider empty height={24} />
      <View style={styles.signInContainer}>
        <Text style={styles.signInText}>Already have an account?</Text>
        <TouchableOpacity onPress={() => navigation.push('SignIn')}>
          <Text style={styles.signInText2}>Sign in</Text>
        </TouchableOpacity>
      </View>
    </ComponentWrapper>
  );
};

export { ChooseUserType };

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Secular One',
    fontSize: 32,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
    marginBottom: 12,
  },
  subtitle: {
    fontFamily: 'Secular One',
    fontSize: 18,
    color: '#E89823',
    textAlign: 'center',
  },
  topContainer: {
    height: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    top: 60,
    ...mbPlatformStyle({
      mobile: {
        paddingHorizontal: 24,
      },
      web: {
        paddingHorizontal: 120,
      },
    }),
  },
  beeIcon: {
    width: 25,
    height: 38,
  },
  forgetContainer: {
    width: 320,
    justifyContent: 'flex-start',
  },
  forgetText: {
    fontFamily: 'Secular One',
    fontSize: 16,
    color: '#E89823',
    textAlign: 'right',
  },
  signInContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  signInText: {
    fontFamily: 'Secular One',
    fontSize: 20,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
  signInText2: {
    fontFamily: 'Secular One',
    fontSize: 20,
    color: '#E89823',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 2 },
    marginLeft: 6,
    textShadowRadius: 2,
    textDecorationStyle: 'solid',
    textDecorationLine: 'underline',
  },
  row: {
    width: 240,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bee: {
    width: 90,
    height: 90,
  },
  card: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardText: {
    fontFamily: 'Secular One',
    marginTop: 12,
    fontSize: 14,
    color: '#555555',
  },
  active: {
    color: '#E89823',
  },
});
