import * as React from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';

const LockIcon = (props: { size?: number, containerStyle?: StyleProp<ViewStyle> }) => {
    return (
        <View style={props.containerStyle}>
            <Svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                {...props}
            >
                <G clipPath="url(#clip0_1532_1747)">
                    <Path
                        d="M12.004 24c4.612 0 8.364-3.752 8.364-8.364 0-2.165-.827-4.14-2.182-5.627V6.182A6.189 6.189 0 0012.004 0a6.189 6.189 0 00-6.181 6.182v3.827a8.332 8.332 0 00-2.182 5.627C3.64 20.248 7.393 24 12.004 24zm1.091-8.001v1.455a1.091 1.091 0 01-2.182 0V16a1.816 1.816 0 011.091-3.272A1.816 1.816 0 0113.094 16zm-5.09-9.817c0-2.206 1.794-4 4-4 2.205 0 4 1.794 4 4v2.111a8.312 8.312 0 00-4-1.02c-1.449 0-2.812.37-4 1.02V6.182z"
                        fill="#919191"
                    />
                </G>
                <Defs>
                    <ClipPath id="clip0_1532_1747">
                        <Path fill="#fff" d="M0 0H24V24H0z" />
                    </ClipPath>
                </Defs>
            </Svg>
        </View>
    );
};

export { LockIcon };
