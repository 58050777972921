export enum UTIL_QUERY_KEYS {
  serverVersion = 'serverVersion',
  minimumMobileJSVersion = 'minimumMobileJSVersion',
}
export enum GAME_TYPES_TYPE {
  MULTI_1_12 = 'MULTI_1_12',
  MULTI_1_10 = 'MULTI_1_10',
  MULTI_MINUS_6_6 = 'MULTI_MINUS_6_6',
  MULTI_ALGEBRA = 'MULTI_ALGEBRA',
  ADD_1_12 = 'ADD_1_12',
  ADD_MINUS_6_6 = 'ADD_MINUS_6_6',
}

// We are trying to get any ax + b in the operands, and x value is the power of x in the equation.
export interface OPERAND_TYPE {
  a: number;
  x: number;
  b: number;
}

interface IConfig {
  maxInARow: number,
  rowsCount: number[],
  operands: OPERAND_TYPE[],
  hasDuplicate: boolean,
  hasHint: boolean,
  howToWin: string,
  hasWorkingBar: boolean,
  name: string
}

export const GAME_TYPES_CONFIG: Record<GAME_TYPES_TYPE, IConfig> = {
  [GAME_TYPES_TYPE.MULTI_1_12]: {
    maxInARow: 8,
    rowsCount: [5, 6, 7, 8, 7, 8, 7, 6, 5],
    operands: [
      { a: 1, x: 0, b: 0 },
      { a: 2, x: 0, b: 0 },
      { a: 3, x: 0, b: 0 },
      { a: 4, x: 0, b: 0 },
      { a: 5, x: 0, b: 0 },
      { a: 6, x: 0, b: 0 },
      { a: 7, x: 0, b: 0 },
      { a: 8, x: 0, b: 0 },
      { a: 9, x: 0, b: 0 },
      { a: 10, x: 0, b: 0 },
      { a: 11, x: 0, b: 0 },
      { a: 12, x: 0, b: 0 },
    ],
    hasDuplicate: false,
    hasHint: true,
    howToWin: 'Get 4 in a row by multiplying',
    hasWorkingBar: true,
    name: 'Multiplication (1 to 12)',
  },
  [GAME_TYPES_TYPE.MULTI_1_10]: {
    maxInARow: 7,
    rowsCount: [2, 3, 4, 5, 6, 7, 6, 5, 4],
    operands: [
      { a: 1, x: 0, b: 0 },
      { a: 2, x: 0, b: 0 },
      { a: 3, x: 0, b: 0 },
      { a: 4, x: 0, b: 0 },
      { a: 5, x: 0, b: 0 },
      { a: 6, x: 0, b: 0 },
      { a: 7, x: 0, b: 0 },
      { a: 8, x: 0, b: 0 },
      { a: 9, x: 0, b: 0 },
      { a: 10, x: 0, b: 0 },
    ],
    hasDuplicate: false,
    hasHint: true,
    howToWin: 'Get 4 in a row by multiplying',
    hasWorkingBar: true,
    name: 'Multiplication (1 to 10)',
  },
  [GAME_TYPES_TYPE.ADD_1_12]: {
    maxInARow: 8,
    rowsCount: [3, 4, 5, 6, 7, 8, 7, 6],
    operands: [
      { a: 1, x: 0, b: 0 },
      { a: 2, x: 0, b: 0 },
      { a: 3, x: 0, b: 0 },
      { a: 4, x: 0, b: 0 },
      { a: 5, x: 0, b: 0 },
      { a: 6, x: 0, b: 0 },
      { a: 7, x: 0, b: 0 },
      { a: 8, x: 0, b: 0 },
      { a: 9, x: 0, b: 0 },
      { a: 10, x: 0, b: 0 },
      { a: 11, x: 0, b: 0 },
      { a: 12, x: 0, b: 0 },
    ],
    hasDuplicate: true,
    hasHint: false,
    howToWin: 'Get 4 in a row by adding',
    hasWorkingBar: true,
    name: 'Addition (1 to 12)',
  },
  [GAME_TYPES_TYPE.ADD_MINUS_6_6]: {
    maxInARow: 7,
    rowsCount: [5, 6, 7, 6, 7, 6, 7, 6],
    operands: [
      { a: -6, x: 0, b: 0 },
      { a: -5, x: 0, b: 0 },
      { a: -4, x: 0, b: 0 },
      { a: -3, x: 0, b: 0 },
      { a: -2, x: 0, b: 0 },
      { a: -1, x: 0, b: 0 },
      { a: 1, x: 0, b: 0 },
      { a: 2, x: 0, b: 0 },
      { a: 3, x: 0, b: 0 },
      { a: 4, x: 0, b: 0 },
      { a: 5, x: 0, b: 0 },
      { a: 6, x: 0, b: 0 },
    ],
    hasDuplicate: true,
    hasHint: false,
    howToWin: 'Get 4 in a row by adding',
    hasWorkingBar: false,
    name: 'Addition (+6 to -6)',
  },
  [GAME_TYPES_TYPE.MULTI_MINUS_6_6]: {
    maxInARow: 7,
    rowsCount: [3, 4, 5, 6, 7, 6, 5],
    operands: [
      { a: -6, x: 0, b: 0 },
      { a: -5, x: 0, b: 0 },
      { a: -4, x: 0, b: 0 },
      { a: -3, x: 0, b: 0 },
      { a: -2, x: 0, b: 0 },
      { a: -1, x: 0, b: 0 },
      { a: 1, x: 0, b: 0 },
      { a: 2, x: 0, b: 0 },
      { a: 3, x: 0, b: 0 },
      { a: 4, x: 0, b: 0 },
      { a: 5, x: 0, b: 0 },
      { a: 6, x: 0, b: 0 },
    ],
    hasDuplicate: false,
    hasHint: true,
    howToWin: 'Get 4 in a row by multiplying',
    hasWorkingBar: false,
    name: 'Multiplication (+6 to -6)',
  },
  [GAME_TYPES_TYPE.MULTI_ALGEBRA]: {
    maxInARow: 8,
    rowsCount: [5, 6, 7, 8, 7, 8, 7, 6],
    operands: [
      { a: 1, x: 0, b: 0 },
      { a: 2, x: 0, b: 0 },
      { a: 3, x: 0, b: 0 },
      { a: 4, x: 0, b: 0 },
      { a: 5, x: 0, b: 0 },
      { a: 6, x: 0, b: 0 },
      { a: 1, x: 1, b: 0 },
      { a: 2, x: 1, b: 0 },
      { a: 3, x: 1, b: 0 },
      { a: 4, x: 1, b: 0 },
      { a: 5, x: 1, b: 0 },
      { a: 6, x: 1, b: 0 },
    ],
    hasDuplicate: false,
    hasHint: true,
    howToWin: 'Get 4 in a row by multiplying',
    hasWorkingBar: false,
    name: 'Algebra',
  },
};

export const enum SERVER_ERROR_CODES {
  EMAIL_ALREADY_IN_USE = 'EMAIL_ALREADY_IN_USE',
  SIGN_UP_USER_TYPE_REQUIRED = 'USER_TYPE_REQUIRED',
  INVALID_ACCESS_CODE = 'INVALID_ACCESS_CODE',
  CLIENT_DATA_MISMATCH_ERROR = 'CLIENT_DATA_MISMATCH_ERROR',
  INVALID_ACCESS_TOKEN = 'INVALID_ACCESS_TOKEN',
  INVALID_REFRESH_TOKEN = 'INVALID_REFRESH_TOKEN',
  EXPIRED_ACCESS_TOKEN = 'EXPIRED_ACCESS_TOKEN',
  EXPIRED_PASSWORD_RECOVERY_TOKEN = 'EXPIRED_PASSWORD_RECOVERY_TOKEN',
  INVALID_ACCESS_TOKEN_REDIRECT = 'INVALID_ACCESS_TOKEN_REDIRECT',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  GENERAL_ERROR = 'GENERAL_ERROR',
  GOOGLE_LOGIN_FAILED = 'GOOGLE_LOGIN_FAILED',
  APPLE_LOGIN_FAILED = 'APPLE_LOGIN_FAILED',
  INVALID_SIGN_UP_CODE = 'INVALID_SIGN_UP_CODE',
  SIGN_UP_CODE_EXPIRED = 'SIGN_UP_CODE_EXPIRED',
  NOT_FOUND = 'NOT_FOUND',
  INVALID_PARAMETER = 'INVALID_PARAMETER',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  NETWORK_ERROR = 'NETWORK_ERROR',
  LIMIT_FILE_SIZE = 'LIMIT_FILE_SIZE',
  IMAGE_WITH_GIVEN_NAME_ALREADY_EXISTS = 'IMAGE_WITH_GIVEN_NAME_ALREADY_EXISTS',
  CATEGORY_WITH_GIVEN_NAME_ALREADY_EXISTS = 'CATEGORY_WITH_GIVEN_NAME_ALREADY_EXISTS',
  QUESTION_NOT_READY_ENABLE = 'QUESTION_NOT_READY_ENABLE',
  DUPLICATE_ANSWER_NAME = 'DUPLICATE_ANSWER_NAME',
  VIDEO_IS_PROCESSING = 'VIDEO_IS_PROCESSING',
  IMAGE_USED_ON_ANSWER = 'IMAGE_USED_ON_ANSWER',
  VIDEO_USED_ON_ANSWER = 'VIDEO_USED_ON_ANSWER',
  CANNOT_ENABLE_CATEGORY = 'CANNOT_ENABLE_CATEGORY',
}

export enum USER_QUERY_KEYS {
  getCurrentUsertData = 'getCurrentUserData',
  getCategoryForHomePage = 'getCategoryForHomePage',
}

export enum PaymentStatus {
  NotPaid = 'NotPaid',
  Pending = 'Pending',
  Active = 'Active',
  Canceled = 'Canceled',
  PaymentFailed = 'PaymentFailed',
}

export enum HiveSize {
  SingleUser = 'SingleUser',
  FamilyHive = 'FamilyHive',
  ClassHive = 'ClassHive',
  // SchoolHive = 'SchoolHive',
}

export enum HiveSubscriptionType {
  month = 'month',
  year = 'year'
}

export const sizeTitle = {
  [HiveSize.ClassHive]: 'Class Hive',
  [HiveSize.FamilyHive]: 'Group Hive',
  [HiveSize.SingleUser]: 'Single User',
  // [HiveSize.SchoolHive]: 'School Hive',
};
