import React, { useEffect, useState } from 'react';
import { Keyboard, StyleSheet, Text } from 'react-native';
import { applyTransparency } from '../../../constants/colors';
import { ScreenProps } from '../../../typesInterfacesEnums/componentProps';
import { BackButton } from '../../helperComponents/BackButton';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { DismissKeyboard } from '../../helperComponents/DismissKeyboard';
import { Divider } from '../../helperComponents/Divider';
import { ErrorPopup } from '../../helperComponents/ErrorPopup';
import { Input } from '../../helperComponents/Input';
import { MenuButton } from '../../helperComponents/MenuButton';
import { useHostGameMutation } from './useHostGameMutation.gql';
import { ApolloError } from '@apollo/client';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getErrorText } from '../../../utils/errors';

const HostGame = ({ route, navigation }: ScreenProps<'HostGame'>) => {
  const gameType = route.params.gameType;
  const [name, setName] = useState(route.params?.userName || '');
  const [hasError, setHasError] = useState({
    visible: false,
    popup: false,
    text: '',
  });

  const [hostGame, { loading, data, error }] = useHostGameMutation({
    variables: { userName: name, gameType: gameType },
  });

  const handleHostGameError = (err: ApolloError) => {
    setHasError({ popup: true, visible: true, text: getErrorText(err) });
  };

  const handleHostGame = () => {
    AsyncStorage.setItem('playing-name', name);
    hostGame().catch((err) => {
      handleHostGameError(err);
    });
  };

  useEffect(() => {
    if (error) {
      handleHostGameError(error);
    }
  }, [error]);

  useEffect(() => {
    if (data?.hostGame.game.joinCode) {
      navigation.navigate('JoinCode', { joinCode: data.hostGame.game.joinCode, userName: name });
    }
  }, [data, name, navigation]);

  const onNext = () => {
    Keyboard.dismiss();
    handleHostGame();
  };

  return (
    <>
      <DismissKeyboard>
        <ComponentWrapper hasInset hasScroll={false}>
          <Text maxFontSizeMultiplier={1} style={styles.title}>
            Host A Game
          </Text>
          <Divider empty height={36} />
          <Input textContentType="name" placeholder="Name" value={name} setValue={setName} onSubmitEditing={onNext} />
          <Divider empty height={28} />
          <MenuButton
            onPress={onNext}
            disabled={!name || loading}
            colors={['#90DF75', '#62B655']}
            text="Next"
            height={64}
            width={240}
            loading={loading}
          />
          <Divider empty height={24} />
          <BackButton text="Back  To Lobby" onPress={() => navigation.reset({ index: 0, routes: [{ name: 'Lobby' }] })} />
        </ComponentWrapper>
      </DismissKeyboard>
      <ErrorPopup
        isVisible={hasError.popup}
        setVisible={() => setHasError(v => ({ ...v, popup: false }))}
        text={hasError.text}
      />
    </>
  );
};

export { HostGame };

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Secular One',
    fontSize: 32,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
  },
  spinnerContainer: {
    paddingRight: 8,
  },
});
