import React from 'react';
import {
  View,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  Platform,
  Keyboard,
  ScrollView,
} from 'react-native';

interface IDismissKeyboardProps {
  children: React.ReactNode;
  isScrollable?: boolean;
}
const DismissKeyboard = (props: IDismissKeyboardProps) => {
  if (Platform.OS === 'web') {
    return <>{props.children}</>;
  }
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={{ flex: 1 }}
    >
      <TouchableWithoutFeedback
        style={{ width: '100%', height: '100%', zIndex: 10 }}
        onPress={Keyboard.dismiss}
        accessible={false}
      >
        {
          props.isScrollable ?
            <ScrollView contentContainerStyle={{ flex: 1 }}>{props.children}</ScrollView>
            :
            <View style={{ flex: 1 }}>{props.children}</View>
        }
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  );
};

export { DismissKeyboard };
