import React, { ReactNode } from 'react';
import { StyleSheet, Text, View, Pressable } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { HexagonIcon } from '../../resources/svgComponents/HexagonIcon';
import { HexagonWithTickIcon } from '../../resources/svgComponents/HexagonWithTickIcon';
import { DisabledHexagonIcon } from '../../resources/svgComponents/DisabledHexagonIcon';
import { LockIcon } from '../../resources/svgComponents/LockIcon';

interface IBigRadioProps {
  title: string;
  onPress: () => void;
  isActive: boolean;
  disabled?: boolean;
  additionalIcon?: ReactNode;
  textBackground?: string;
  isLocked?: boolean
}

const BigRadio = (props: IBigRadioProps) => {
  return (
    <Pressable
      disabled={props.disabled}
      onPress={props.onPress}
      style={[styles.btnContainer, props.isActive || props.disabled ? null : styles.notActive]}
    >
      {props.disabled ? <DisabledHexagonIcon width={62} height={62 * (59 / 51)} /> : props.isActive ? <HexagonWithTickIcon size={62} /> : <HexagonIcon containerStyle={{ opacity: 0.4 }} width={62} height={62 * (59 / 51)} />}
      {props.disabled ?
        <View style={styles.disabledTextContainer}>
          <Text maxFontSizeMultiplier={1} style={styles.disabledText}>{props.title}</Text>
          <View pointerEvents="none" style={styles.additionalIcon}>{props.additionalIcon}</View>
          <View style={styles.lockIcon}>
            <LockIcon />
          </View>
        </View>
        : props.isActive ? (
          <LinearGradient colors={['#FFCF53', '#FF9900']} style={styles.activeTextContainer}>
            <Text maxFontSizeMultiplier={1} style={[styles.activeText, { backgroundColor: props.textBackground }]}>{props.title}</Text>
            <View pointerEvents="none" style={styles.additionalIcon}>{props.additionalIcon}</View>
            {props.isLocked &&
              <View style={styles.lockIcon}>
                <LockIcon />
              </View>
            }
          </LinearGradient>
        ) : (
          <View style={styles.deActiveTextContainer}>
            <Text maxFontSizeMultiplier={1} style={[styles.deActiveText, { backgroundColor: props.textBackground }]}>{props.title}</Text>
            <View pointerEvents="none" style={styles.additionalIcon}>{props.additionalIcon}</View>
            {props.isLocked &&
              <View style={styles.lockIcon}>
                <LockIcon />
              </View>
            }
          </View>
        )}
    </Pressable>
  );
};

export { BigRadio };

const styles = StyleSheet.create({
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 16,
  },
  notActive: {
    // opacity: 0.4,
  },
  activeTextContainer: {
    height: 48,
    width: 260,
    borderWidth: 2,
    display: 'flex',
    borderColor: '#1F1F1F',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 8,
  },
  activeText: {
    fontFamily: 'Secular One',
    fontSize: 18,
    textAlign: 'center',
    color: 'black',
    paddingVertical: 3,
    paddingHorizontal: 12,
  },
  deActiveTextContainer: {
    height: 48,
    width: 260,
    borderWidth: 2,
    borderColor: '#bcbcbc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 8,
  },
  disabledTextContainer: {
    height: 48,
    width: 260,
    borderWidth: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 8,
    borderColor: '#C4C4C4',
    backgroundColor: '#ECECEC',
  },
  deActiveText: {
    fontFamily: 'Secular One',
    fontSize: 18,
    textAlign: 'center',
    color: 'black',
    paddingVertical: 3,
    paddingHorizontal: 12,
  },
  disabledText: {
    fontFamily: 'Secular One',
    fontSize: 18,
    textAlign: 'center',
    color: '#979797',
    backgroundColor: '#E4E4E4',
    paddingVertical: 3,
    paddingHorizontal: 12,
  },
  additionalIcon: { position: 'absolute', left: 12 },
  lockIcon: { position: 'absolute', right: 8 },
});
