import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const mutation = gql`
  mutation verifyResetPasswordCode($email: String!, $resetPasswordCode: String!) {
    verifyResetPasswordCode(email: $email, resetPasswordCode: $resetPasswordCode)
  }
`;

export interface RequestType {
  email: string;
  resetPasswordCode: string;
}

export interface MutationType {
  verifyResetPasswordCode: boolean;
}

export function useVerifyForgetPasswordCodeMutation(options?: MutationHookOptions<MutationType, RequestType>) {
  return useMutation<MutationType, RequestType>(mutation, options);
}
