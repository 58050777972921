import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path, G, ClipPath, Defs, LinearGradient, Stop } from 'react-native-svg';

const FirstPlaceIcon = React.memo(({size, containerStyle}: {
    size?: number,
    containerStyle?: StyleProp<ViewStyle>
}) => {
    return (
        <View style={containerStyle}>
            <Svg
                width={size ?? 28}
                height={size ? size * 32 / 28 : 32}
                viewBox="0 0 28 32"
                fill="none"
            >
                <G clipPath="url(#clip0_2243_5770)">
                    <Path
                        d="M28 19.926l-1.914-1.262v1.44l1.914.534v-.712zM0 19.926l1.914-1.262v1.44L0 20.639v-.712z"
                        fill="#A50E0E"
                    />
                    <Path
                        d="M1.914 7.748V23.25L14 31.002l12.086-7.752V7.748L14-.004 1.914 7.748z"
                        fill="#ECB20C"
                    />
                    <Path
                        d="M9.612 12.823L14 3.339V-.004L1.914 7.748l7.698 5.075z"
                        fill="url(#paint0_linear_2243_5770)"
                    />
                    <Path
                        d="M19.718 18.664L14 27.657V31l12.086-7.751-2.606-1.672-3.762-2.913z"
                        fill="url(#paint1_linear_2243_5770)"
                    />
                    <Path
                        d="M13.791 23.688l-9.27-2.11-2.607 1.672L14 31v-3.343l-.209-3.97z"
                        fill="url(#paint2_linear_2243_5770)"
                    />
                    <Path
                        d="M14.207 7.45l9.273 1.97 2.606-1.672L14-.004V3.34l.207 4.112z"
                        fill="url(#paint3_linear_2243_5770)"
                    />
                    <Path
                        d="M10.03 18.254L4.912 9.691 1.914 7.746V23.25l2.606-1.672 5.51-3.323z"
                        fill="url(#paint4_linear_2243_5770)"
                    />
                    <Path
                        d="M17.96 18.254l5.12-8.563 2.997-1.945V23.25l-2.607-1.672-5.51-3.323z"
                        fill="url(#paint5_linear_2243_5770)"
                    />
                    <Path
                        d="M4.523 9.42v12.16l9.48 6.08 9.48-6.08V9.42l-9.48-6.08-9.48 6.08z"
                        fill="url(#paint6_linear_2243_5770)"
                    />
                    <Path
                        d="M9.067 24.107H1.204c-.665 0-1.204-.54-1.204-1.204V19.84h7.765c.719 0 1.302.582 1.302 1.301v2.966z"
                        fill="url(#paint7_linear_2243_5770)"
                    />
                    <Path
                        d="M26.8 24.372h-7.863v-2.965c0-.719.583-1.302 1.302-1.302h7.765v3.063c0 .665-.539 1.204-1.204 1.204z"
                        fill="url(#paint8_linear_2243_5770)"
                    />
                    <Path
                        d="M21.346 26.507H6.654A.654.654 0 016 25.853v-4.558c0-.362.293-.654.654-.654h14.692c.361 0 .654.293.654.654v4.558a.654.654 0 01-.654.654z"
                        fill="url(#paint9_linear_2243_5770)"
                    />
                    <Path
                        d="M13.993 27.842a4.267 4.267 0 100-8.533 4.267 4.267 0 000 8.533z"
                        fill="#F6F6F8"
                    />
                    <Path
                        d="M13.99 27.035a3.459 3.459 0 100-6.918 3.459 3.459 0 000 6.918z"
                        fill="#FFE066"
                    />
                    <Path
                        d="M13.998 20.992l.839 1.701 1.877.273-1.358 1.324.32 1.869-1.678-.883-1.68.883.322-1.87-1.359-1.323 1.877-.273.84-1.7z"
                        fill="#FCC11A"
                    />
                    <Path
                        d="M13.998 20.992l.109 2.529.73-.828.939.136.938.137-2.607.555 1.249.769.16.934.16.935-1.57-2.638-.108 1.755-.84.442-.84.441 1.789-2.638-1.467.769-.68-.662-.679-.662 2.826.555-.949-.828.42-.85.42-.85z"
                        fill="#F38B00"
                    />
                    <Path
                        d="M11.313 15.59h1.8v-4.82h-1.561V9.518c.911-.17 1.504-.398 2.096-.763h1.493v6.836h1.527v1.641h-5.355v-1.64z"
                        fill="#F28500"
                    />
                    <Path
                        d="M7.133 9.461l.228-.915a.667.667 0 01.486-.485l.915-.23c.025-.005.025-.04 0-.046l-.915-.23a.668.668 0 01-.486-.485l-.228-.915c-.007-.024-.042-.024-.048 0l-.228.915a.668.668 0 01-.486.486l-.915.229c-.025.006-.025.04 0 .047l.915.229a.668.668 0 01.486.485l.228.915c.007.025.042.025.048 0zM23.42 19.49l.171-.686a.5.5 0 01.365-.365l.686-.171c.018-.005.018-.031 0-.036l-.686-.171a.502.502 0 01-.365-.365l-.171-.686c-.005-.019-.031-.019-.036 0l-.171.686a.501.501 0 01-.365.365l-.686.171c-.018.005-.018.031 0 .036l.686.171a.5.5 0 01.365.365l.171.686c.005.018.031.018.036 0z"
                        fill="#fff"
                    />
                </G>
                <Defs>
                    <LinearGradient
                        id="paint0_linear_2243_5770"
                        x1={7.99527}
                        y1={3.91908}
                        x2={9.79704}
                        y2={6.14536}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#FFE622" />
                        <Stop offset={1} stopColor="#FCC523" />
                    </LinearGradient>
                    <LinearGradient
                        id="paint1_linear_2243_5770"
                        x1={19.6696}
                        y1={26.5913}
                        x2={18.3683}
                        y2={24.593}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#FFE622" />
                        <Stop offset={1} stopColor="#FCC523" />
                    </LinearGradient>
                    <LinearGradient
                        id="paint2_linear_2243_5770"
                        x1={7.79327}
                        y1={27.2983}
                        x2={9.96095}
                        y2={25.0119}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#F38600" />
                        <Stop offset={1} stopColor="#EDA416" />
                    </LinearGradient>
                    <LinearGradient
                        id="paint3_linear_2243_5770"
                        x1={20.1584}
                        y1={3.75586}
                        x2={17.9904}
                        y2={6.07032}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#F38600" />
                        <Stop offset={1} stopColor="#EDA416" />
                    </LinearGradient>
                    <LinearGradient
                        id="paint4_linear_2243_5770"
                        x1={1.914}
                        y1={15.4976}
                        x2={4.89968}
                        y2={15.4976}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#EDB516" />
                        <Stop offset={1} stopColor="#FCC616" />
                    </LinearGradient>
                    <LinearGradient
                        id="paint5_linear_2243_5770"
                        x1={26.0769}
                        y1={15.4976}
                        x2={23.0912}
                        y2={15.4976}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#EDB516" />
                        <Stop offset={1} stopColor="#FCC616" />
                    </LinearGradient>
                    <LinearGradient
                        id="paint6_linear_2243_5770"
                        x1={14.0031}
                        y1={27.6592}
                        x2={14.0031}
                        y2={3.33989}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#FFD12D" />
                        <Stop offset={1} stopColor="#FCED6F" />
                    </LinearGradient>
                    <LinearGradient
                        id="paint7_linear_2243_5770"
                        x1={0}
                        y1={21.9732}
                        x2={9.06667}
                        y2={21.9732}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#BE4244" />
                        <Stop offset={1} stopColor="#D33730" />
                    </LinearGradient>
                    <LinearGradient
                        id="paint8_linear_2243_5770"
                        x1={18.9376}
                        y1={22.2389}
                        x2={28.0043}
                        y2={22.2389}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#BE4244" />
                        <Stop offset={1} stopColor="#D33730" />
                    </LinearGradient>
                    <LinearGradient
                        id="paint9_linear_2243_5770"
                        x1={13.9999}
                        y1={26.5073}
                        x2={13.9999}
                        y2={20.6406}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#FF544A" />
                        <Stop offset={1} stopColor="#F7726E" />
                    </LinearGradient>
                    <ClipPath id="clip0_2243_5770">
                        <Path
                            fill="#fff"
                            transform="translate(0 -.004)"
                            d="M0 0H28V31.0059H0z"
                        />
                    </ClipPath>
                </Defs>
            </Svg>
        </View>
    );
});

export default FirstPlaceIcon;
