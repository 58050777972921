import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function ControllerIcon({ size = 22 }: { size?: number }) {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
    >
      <Path
        d="M20.758 10.544c-.911-4.12-2.283-6.32-4.317-6.92a4.603 4.603 0 00-1.317-.186c-.59 0-1.103.143-1.645.295-.654.184-1.397.392-2.48.392-1.084 0-1.827-.208-2.482-.391-.543-.153-1.056-.296-1.643-.296-.461-.002-.92.06-1.364.185-2.024.598-3.394 2.797-4.32 6.918-.993 4.435-.504 7.236 1.373 7.885.258.091.528.138.801.139 1.286 0 2.318-1.072 3.022-1.949.796-.992 1.728-1.496 4.612-1.496 2.577 0 3.643.35 4.563 1.496.58.721 1.126 1.226 1.672 1.544.726.421 1.45.515 2.155.273 1.11-.378 1.746-1.38 1.89-2.975.111-1.224-.059-2.831-.52-4.914zm-11.822-.232H7.561v1.376a.687.687 0 11-1.375 0v-1.376H4.811a.688.688 0 110-1.374h1.375V7.563a.687.687 0 111.375 0v1.375h1.375a.688.688 0 110 1.374zm3.61.172a.86.86 0 110-1.718.86.86 0 010 1.718zm1.89 1.891a.86.86 0 110-1.719.86.86 0 010 1.719zm0-3.781a.86.86 0 110-1.719.86.86 0 010 1.719zm1.89 1.89a.86.86 0 110-1.719.86.86 0 010 1.72z"
        fill="#E89823"
      />
    </Svg>
  );
}

export { ControllerIcon };
