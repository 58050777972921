import React, { useCallback, useMemo } from 'react';
import { isMobile } from '@mightybyte/rnw.utils.device-info';
import { Image, StyleSheet, Text, TouchableOpacity, View, useWindowDimensions } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { applyTransparency } from '../constants/colors';
import { RootStackParamList } from './RootNavigator';
import { Route, RouteProp, useNavigation } from '@react-navigation/native';
import { useSignedInContext } from '../context/SignedInContext';
import { USER_TYPES } from '../typesInterfacesEnums/enums';
import { useGetHivesQuery } from '../components/screens/MyHives/useGetHivesQuery.gql';
import { PaymentStatus } from '../constants/constants';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

const diamondImage = require('../resources/images/diamond.png');

type HeaderProps = {
    route: Route<string>
}

const Header = ({ route }: HeaderProps) => {

    const { user: currentUser, isSignedIn } = useSignedInContext();
    const { data: getHivesData } = useGetHivesQuery({ skip: !isSignedIn });
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
    const { width } = useWindowDimensions();

    const shouldHideBanner = useMemo(() => {
        if (isMobile) {
            return true;
        }

        const isLobbyPage = (route as RouteProp<RootStackParamList>).name === 'Lobby';

        if (!isLobbyPage) {
            return true;
        } else if (isSignedIn) {
            if (currentUser?.userType !== USER_TYPES.teacher) {
                return true;
            } else {
                return getHivesData ? getHivesData.hives.some(hive => hive.paymentStatus === PaymentStatus.Active) : true;
            }
        }
        return false;
    }, [currentUser?.userType, getHivesData, isSignedIn, route]);

    const onLearnMore = useCallback(() => {
        if (!isSignedIn) {
            navigation.navigate('Subscriptions');
        } else {
            navigation.navigate('SelectHiveSize');
        }
    }, [isSignedIn, navigation]);

    if (shouldHideBanner) {
        return null;
    }

    return (
        <View style={[styles.header, { marginLeft: (width - 806) / 2 + 15 }, width <= 1250 && styles.stickHeader]}>
            <View style={[styles.banner, width <= 1250 && styles.stickBanner]}>
                <LinearGradient style={styles.gradienContainer} colors={['#747474', '#1F1F1F']}>
                    <Image resizeMode="cover" style={styles.diamondImage} source={diamondImage} />
                    <Text style={styles.bannerText}><Text style={styles.highlightedText}>Go Premium! </Text>Unlock game boards and create your own small group or class 'Hive'</Text>
                    <TouchableOpacity onPress={onLearnMore}>
                        <LinearGradient style={styles.button} colors={['#FFCF53', '#FF9900']}>
                            <Text maxFontSizeMultiplier={1} style={styles.learnMoreText}>
                                Learn more
                            </Text>
                        </LinearGradient>
                    </TouchableOpacity>
                </LinearGradient>
            </View>
        </View>
    );
};

export { Header };

const styles = StyleSheet.create({
    header: {
        position: 'absolute',
        alignItems: 'center',
        marginTop: 15,
    },
    stickHeader: {
        position: 'relative',
        marginTop: 0,
        alignItems: 'stretch',
        marginLeft: 0,
    },
    banner: {
        borderRadius: 50,
        borderWidth: 4,
        borderColor: '#9C9C9C',
        overflow: 'hidden',
    },
    stickBanner: {
        borderRadius: 0,
        borderWidth: 0,
    },
    gradienContainer: {
        paddingHorizontal: 20,
        paddingVertical: 11,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    bannerText: {
        fontFamily: 'Secular One',
        fontSize: 15,
        color: '#FFFFFF',
        fontWeight: '700',
        marginEnd: 14,
    },
    highlightedText: {
        fontWeight: '400',
        fontSize: 15,
    },
    diamondImage: {
        width: 38,
        height: 38,
        marginEnd: 14,
    },
    button: {
        borderRadius: 50,
        paddingHorizontal: 18,
        paddingVertical: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    learnMoreText: {
        fontFamily: 'Secular One',
        fontSize: 15,
        color: '#453B3B',
        textShadowColor: applyTransparency('#000000', 0.15),
        textShadowOffset: { width: 0, height: 3 },
        textShadowRadius: 2,
    },
});

