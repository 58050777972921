import {
  ApolloCache,
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
  TypePolicies,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient as createWSClient } from 'graphql-ws';
import { nanoid } from 'nanoid';
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { envs } from '../../env';
import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import NetInfo from '@react-native-community/netinfo';
import { signedInContextGlobalFunction } from '../context/SignedInContext';

let timeout: any;

interface GraphQLContextType {
  client?: ApolloClient<any>;
  cache?: ApolloCache<any>;
  currentUserId: string;
  isOnline: boolean;
}

const GraphQLContext = createContext<GraphQLContextType>({} as any);

interface Props {
  children: ReactNode;
  typePolicies?: TypePolicies;
}

const getAccessToken = async () => {
  const token = await MB_accessTokenUtils.getAccessToken();
  return token;
};

export function GraphQLProvider({ children, typePolicies }: Props) {
  const currentUserId = useMemo(() => nanoid(), []); // enables anonymous user
  const [isOnline, setOnline] = useState(true);
  const [isOffline, setOffline] = useState(true);
  const [hasInternet, setHasInternet] = useState(true);

  const cache = useMemo(() => new InMemoryCache({ typePolicies, addTypename: false }), [typePolicies]);

  const client = useMemo(() => {
    console.log({ useruuid: currentUserId });

    const getHeaders = async () => {
      const token = await getAccessToken();
      return {
        useruuid: currentUserId,
        authorization: token ? token : '',
      };
    };

    const authLink = setContext(async (_, { headers }) => {
      return {
        headers: { ...headers, ...(await getHeaders()) },
      };
    });

    // Log any GraphQL errors or network error that occurred
    const errorLink = onError(({ graphQLErrors, networkError, response }) => {
      if (graphQLErrors) {
        graphQLErrors.map(async ({ message, locations, path }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );

          if (message.includes('The provided access token is invalid.') || message.includes('INVALID_ACCESS_TOKEN')) {
            // Clear the stored access token
            await MB_accessTokenUtils.removeAccessToken();
            // Call the sign out function and don't let the user see any of private route.
            if (signedInContextGlobalFunction) {
              signedInContextGlobalFunction.signOut?.({});
            }
          }
        });
      }

      if (networkError) {
        console.log(`[Network error]: ${networkError}`, response);
      }
    });

    const httpLink = new HttpLink({ uri: envs.SERVER_URL });

    const wsClient = createWSClient({
      url: envs.SERVER_WEB_SOCKET_URL,
      connectionParams: getHeaders,
      retryAttempts: 70,
      retryWait: async () => {
        await new Promise(resolve => setTimeout(resolve, 1000));
      },
      shouldRetry: () => {
        // Always attempt to reconnect if the connection was closed
        return true;
      },
    });

    wsClient.on('closed', () => {
      setOnline(false);
    });

    wsClient.on('connected', () => {
      setOnline(true);
    });

    const wsLink = new GraphQLWsLink(wsClient);

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      wsLink,
      httpLink,
    );

    return new ApolloClient({
      uri: envs.SERVER_URL,
      cache,
      link: ApolloLink.from([authLink, errorLink, splitLink]),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          nextFetchPolicy: 'cache-first',
          returnPartialData: true,
        },
        query: {
          fetchPolicy: 'cache-first',
          returnPartialData: true,
        },
      },
      connectToDevTools: envs.ENV === 'development',
    });
  }, [cache, currentUserId]);

  const memoizedValue = useMemo(() => ({ client, cache, currentUserId }), [client, cache, currentUserId]);
  const value = { ...memoizedValue, isOnline: !isOffline && hasInternet };

  useEffect(() => {
    const netInfoSubscription = NetInfo.addEventListener((state) => setHasInternet((state.isConnected && state.isInternetReachable) ?? false));
    return () => {
      netInfoSubscription && netInfoSubscription();
    };
  }, []);

  useEffect(() => {
    if (!isOnline) {
      timeout = setTimeout(() => {
        setOffline(true);
      }, 3000);
    } else {
      setOffline(false);
      clearTimeout(timeout);
    }
  }, [isOnline]);

  return (
    <GraphQLContext.Provider value={value}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </GraphQLContext.Provider>
  );
}

export function useGraphQL() {
  return useContext(GraphQLContext);
}
