import React from 'react';
import { View} from 'react-native';
import { NothingToShow} from './pieces/NothingToShow';
import { TabNames } from './utils';
import { adminPageStyles } from './adminPageStyles';
import { AdminPageHeader } from './pieces/ScreenHeader';

const Analytics = () => {
    return (
        <View style={adminPageStyles.page}>
            <AdminPageHeader text={TabNames.Analytics}/>
            <NothingToShow tab={TabNames.Analytics}/>
        </View>
    );
};

export { Analytics };

