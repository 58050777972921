import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { InfoIcon } from '../../resources/svgComponents/InfoIcon';
import { SCREEN_SIZES, useScreenSize } from '../../utils/dimsHooks';
import { BackButton } from './BackButton';
import CircularProgress from './Circular';
import { Divider } from './Divider';
import { ProfileName } from './ProfileName';
import { GAME_TYPES_CONFIG, GAME_TYPES_TYPE } from '../../constants/constants';
import { isMobile } from '@mightybyte/rnw.utils.device-info';

interface ITurnProps {
  players: any;
  turn: string;
  isPassAndPlay: boolean;
  userNameTurn?: string;
  currentUserId: string;
  currentPlayerIndex: number;
  currentPlayerUserName: string;
  otherPlayerIndex: number;
  time: number;
  hideTimer?: boolean;
  isPractice: boolean;
  gameType: GAME_TYPES_TYPE;
  setExitPopupOpen: () => void;
  setHintPopupOpen: () => void;
}

export const Turn = (props: ITurnProps) => {
  const screenSize = useScreenSize();
  const isLarge = screenSize === SCREEN_SIZES.LARGE;
  const isSmall = screenSize === SCREEN_SIZES.SMALL;

  const isPlayer1Turn = props.isPassAndPlay ? props.userNameTurn === props.currentPlayerUserName : props.turn === props.currentUserId;
  const isPlayer2Turn = props.isPassAndPlay ? props.userNameTurn !== props.currentPlayerUserName : props.turn !== props.currentUserId;

  if (isLarge) {
    return (
      <View style={[styles.container]}>
        <View style={{ width: 120 }}>
          <BackButton text="Exit" onPress={props.setExitPopupOpen} />
        </View>
        <View style={styles.row}>
          <ProfileName
            isHorizontal
            gradientColors={['#FFCF53', '#FF9900']}
            name={props.players[props.currentPlayerIndex].name}
            color={'#FFCF53'}
            active={isPlayer1Turn}
            isLarge
          />
          <View style={{ position: 'relative' }}>
            <Text maxFontSizeMultiplier={1} style={styles.largeTitle}>
              {isPlayer1Turn
                ? (props.isPassAndPlay ? `${props.currentPlayerUserName} Turn` : 'Your Turn')
                : `${props.players[props.otherPlayerIndex].name} Turn`}
            </Text>
          </View>
          <ProfileName
            isHorizontal
            gradientColors={['#FF9252', '#FF3F15']}
            name={props.players[props.otherPlayerIndex].name}
            color={'#FF9252'}
            active={isPlayer2Turn}
            reverse
            isLarge
          />
        </View>
        <View style={{ width: 120 }}>
          {
            props.isPractice && GAME_TYPES_CONFIG[props.gameType].hasHint &&
            <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center' }} onPress={props.setHintPopupOpen}>
              <InfoIcon />
              <Text style={styles.hintText}>Hint</Text>
            </TouchableOpacity>
          }
        </View>
      </View>
    );
  }
  return (
    <View style={{ width: '100%', position: 'relative' }}>
      <View style={styles.titleContainer}>
        <View style={styles.innerTitle}>
          <Text numberOfLines={1} maxFontSizeMultiplier={1} style={styles.title}>
            {
              isPlayer1Turn
                ? (props.isPassAndPlay ? props.currentPlayerUserName : 'Your')
                : props.players[props.otherPlayerIndex].name
            }
          </Text>
        </View>
        <Text style={[styles.title, { marginLeft: 4 }]}>
          Turn
        </Text>
      </View>
      <Divider empty height={8} />
      <View style={{ position: 'absolute' }}>
        <BackButton text="Exit" onPress={props.setExitPopupOpen} />
      </View>
      {
        props.isPractice && GAME_TYPES_CONFIG[props.gameType].hasHint &&
        <View style={{ position: 'absolute', right: 0 }}>
          <TouchableOpacity onPress={props.setHintPopupOpen}>
            <InfoIcon />
          </TouchableOpacity>
        </View>
      }
      <View style={styles.profileContainer}>
        <ProfileName
          isHorizontal
          gradientColors={['#FFCF53', '#FF9900']}
          name={props.players[props.currentPlayerIndex].name}
          color={'#FFCF53'}
          active={isPlayer1Turn}
        />
        {props.hideTimer ? (
          <Text style={styles.vsText} />
        ) : (
          <View style={{ paddingTop: 8, marginTop: !isSmall && !isMobile ? -20 : 0 }}>
            <CircularProgress progress={props.time} size={30} />
          </View>
        )}
        <ProfileName
          isHorizontal
          gradientColors={['#FF9252', '#FF3F15']}
          name={props.players[props.otherPlayerIndex].name}
          color={'#FF9252'}
          active={isPlayer2Turn}
          reverse
        />
      </View>
    </View >
  );
};

const styles = StyleSheet.create({
  titleContainer: {
    position: 'relative',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerTitle: {
    maxWidth: 140,
  },
  title: {
    fontFamily: 'Secular One',
    fontSize: 20,
    lineHeight: 30,
    color: 'black',
    textAlign: 'center',
  },
  largeTitle: {
    fontFamily: 'Secular One',
    fontSize: 24,
    lineHeight: 30,
    color: 'black',
    textAlign: 'center',
  },
  largeTurnIcon: {
    position: 'absolute',
    left: -140,
    top: -10,
  },
  largeTurnIconRight: {
    left: 'auto',
    right: -120,
    top: -10,
    transform: [{ rotateY: '180deg' }],
  },
  turnIcon: {
    position: 'absolute',
    left: 64,
    top: 4,
  },
  turnIconRight: {
    left: 'auto',
    right: 40,
    top: 4,
    transform: [{ rotateY: '180deg' }],
  },
  profileContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    maxWidth: 680,
    alignSelf: 'center',
  },
  container: {
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 24,
  },
  mediumContainer: {
    paddingTop: 12,
    paddingBottom: 8,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: 800,
  },
  vsText: {
    fontFamily: 'Secular One',
    color: '#FF713A',
    fontSize: 20,
    marginTop: 10,
  },

  hintText: {
    fontFamily: 'Inter',
    fontSize: 14,
    color: '#FC9500',
    textAlign: 'center',
    paddingLeft: 6,
  },
});
