import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const LeaderBoardIcon = React.memo(({size, containerStyle, color}: {
    size?: number,
    containerStyle?: StyleProp<ViewStyle>
    color?: string,
    filled?: boolean,
}) => {
    const iconColor = color ?? '#FFFFFF';
    return (
        <View style={containerStyle}>
            <Svg
                width={size ?? 28}
                height={size ? size * (27 / 28) : 27}
                viewBox="0 0 28 27"
                fill="none"
            >
                <Path
                    d="M9.277 22.5c-1.39 0-2.576-.492-3.559-1.476-.984-.983-1.476-2.17-1.476-3.558 0-1.367.478-2.54 1.433-3.517.956-.977 2.117-1.48 3.485-1.51.144 0 .288.01.433.032.144.02.288.046.432.075L6.984 6.413c-.16-.303-.152-.597.02-.881.173-.284.431-.427.776-.427h2.785c.345 0 .662.092.95.274.29.181.516.424.679.727l2.173 4.365 2.171-4.365c.164-.303.39-.546.68-.727.289-.182.606-.274.95-.274h2.766c.345 0 .607.143.786.428.18.283.19.577.03.88l-3.012 6.056a3.881 3.881 0 01.821-.088c1.378.035 2.544.541 3.5 1.519.955.977 1.433 2.156 1.433 3.538 0 1.408-.492 2.603-1.476 3.586-.983.984-2.179 1.476-3.586 1.476a6.03 6.03 0 01-.65-.036 2.91 2.91 0 01-.63-.135c.888-.386 1.51-1.042 1.868-1.969a8.048 8.048 0 00.537-2.922c0-1.727-.6-3.19-1.8-4.389-1.2-1.2-2.662-1.799-4.388-1.799s-3.19.6-4.388 1.8c-1.2 1.2-1.8 2.662-1.8 4.388 0 1.03.166 2.02.496 2.972.33.953.963 1.592 1.9 1.92a3.293 3.293 0 01-.638.134 5.916 5.916 0 01-.66.036zm5.09 0c-1.406 0-2.602-.492-3.586-1.477-.985-.985-1.477-2.18-1.476-3.585 0-1.406.492-2.602 1.477-3.587.985-.985 2.18-1.477 3.585-1.476 1.406 0 2.602.493 3.587 1.477.984.985 1.476 2.18 1.476 3.586 0 1.406-.493 2.602-1.477 3.586-.985.985-2.18 1.477-3.586 1.476zm0-3.825l1.205.912c.098.064.188.065.272.003.084-.063.108-.146.07-.248l-.448-1.5 1.184-.854c.097-.066.127-.148.09-.248a.214.214 0 00-.216-.15h-1.47l-.47-1.569c-.038-.103-.11-.154-.217-.154-.106 0-.178.051-.216.154l-.47 1.569h-1.47c-.108 0-.18.05-.218.15-.037.1-.007.183.092.248l1.183.855-.448 1.498c-.037.103-.014.185.07.248.083.063.174.062.272-.002l1.205-.912z"
                    fill={iconColor}
                />
            </Svg>
        </View>
    );
});

export default LeaderBoardIcon;
