import { gql, useQuery, QueryHookOptions } from '@apollo/client';

export const query = gql`
  query getHiveAnalytics($hiveId: String!) {
    getHiveAnalytics(hiveId: $hiveId) {
      numberOfGames
      totalTime
    }
  }
`;

interface HiveAnalytics {
  numberOfGames: number;
  totalTime: number;
}

export interface QueryType {
  getHiveAnalytics: HiveAnalytics
}

export function useGetHiveAnalyticsQuery(hiveId: string, options?: QueryHookOptions<QueryType>) {
  return useQuery<QueryType>(query, {
    variables: { hiveId },
    ...options,
  });
}
