import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { GAME_TYPES_TYPE, OPERAND_TYPE } from '../../../constants/constants';

const mutation = gql`
  mutation passAndPlay($inputs: PassAndPlayInput!) {
    passAndPlay(inputs: $inputs) {
      game {
        id
        status
        users {
          id
          name
        }
        gameState {
          gameType
          turn
          userNameTurn
          winningPlayerId
          winningPlayerUserName
          lastPlayedTimeAt
          leftOperand{
            a
            x
            b
          }
          rightOperand{
            a
            x
            b
          }
          tiles {
            id
            value {
              a
              x
              b
            }
            edges
            claimedBy
            claimedByUserName
          }
        }
      }
    }
  }
`;

export interface RequestType {
  inputs: PassAndPlayInputType;
}

export interface PassAndPlayInputType {
  userName1: string;
  userName2: string;
  gameType: GAME_TYPES_TYPE;
  __typename?: 'PassAndPlayInput';
}

export interface MutationType {
  passAndPlay:  PassAndPlayResponseType;
}

export interface  PassAndPlayResponseType {
  game: GameType;
  __typename?: ' PassAndPlayResponse';
}

export interface GameType {
  id: string;
  status: GameStatus;
  users: GameUserType[];
  gameState: GameStateType;
  __typename?: 'Game';
}

export enum GameStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

export interface GameUserType {
  id: string;
  name: string;
  __typename?: 'GameUser';
}

export interface GameStateType {
  gameType: string;
  turn: string;
  winningPlayerId: string;
  leftOperand: OPERAND_TYPE;
  rightOperand: OPERAND_TYPE;
  tiles: GameHiveTileType[];
  lastPlayedTimeAt: string;
  __typename?: 'GameState';
}

export interface GameHiveTileType {
  id: number;
  value: OPERAND_TYPE;
  edges: number[];
  claimedBy: string;
  __typename?: 'GameHiveTile';
}

export function usePassAndPlayMutation(
  options?: MutationHookOptions<MutationType, RequestType>,
) {
  return useMutation<MutationType, RequestType>(mutation, options);
}
