import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path} from 'react-native-svg';

const NoStudentsIcon = React.memo(({size, containerStyle, color }: {
    size?: number,
    containerStyle?: StyleProp<ViewStyle>
    color?: string,
}) => {
    const iconColor = color ?? '#FFFFFF';
    return (
        <View style={containerStyle}>
            <Svg
                width={size ?? 84}
                height={size ? size * (61 / 84) : 61}
                viewBox="0 0 84 61"
            >
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M51.123 20.292c0 10.998-9 19.811-20.23 19.811-11.226 0-20.23-8.813-20.23-19.811S19.667.48 30.893.48c11.23 0 20.23 8.814 20.23 19.812zM.32 62.536c0-10.18 14.084-12.728 30.573-12.728 16.58 0 30.574 2.635 30.574 12.824 0 10.18-14.084 12.728-30.574 12.728C14.314 75.36.32 72.725.32 62.536zm58.963-41.885a26.49 26.49 0 01-4.73 15.168c-.313.446-.037 1.048.509 1.143.757.124 1.534.2 2.328.216 7.897.203 14.984-4.776 16.941-12.274C77.234 13.778 68.718 3.79 57.871 3.79c-1.177 0-2.304.12-3.404.339-.15.032-.313.103-.394.231-.106.163-.029.375.077.514a26.68 26.68 0 015.133 15.778zM72.36 45c5.308 1.017 8.796 3.086 10.242 6.103a8.763 8.763 0 010 7.813c-2.21 4.672-9.342 6.175-12.114 6.562-.574.08-1.034-.399-.973-.965 1.416-12.947-9.847-19.086-12.76-20.497-.123-.068-.152-.164-.14-.227.009-.04.062-.104.155-.116 6.306-.12 13.087.73 15.59 1.328z"
                    fill={iconColor}
                />
            </Svg>
        </View>
    );
});

export default NoStudentsIcon;
