import React from 'react';
import { Image, StyleSheet, View, SafeAreaView } from 'react-native';
import {
    DrawerContentComponentProps,
    DrawerContentScrollView,
    DrawerItemList,
} from '@react-navigation/drawer';
import { COLORS } from '../../constants/colors';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { useSignedInContext } from '../../context/SignedInContext';
import { useSignOutMutation } from '../../components/helperComponents/DropdownMenu/useSignoutMutation.gql';
import hiveIcon from '../../resources/images/hiveIcon.png';
import ExitIcon  from '../../resources/svgComponents/ExitIcon';


function AdminNavigatorDrawer(props: DrawerContentComponentProps) {
    const { signOut } = useSignedInContext();
    const [signOutRequest] = useSignOutMutation();

    const handleSignOut = () => {
        signOutRequest().then(() => {
            signOut();
        }).catch((err) => {
            console.log(err);
        });
    };

    return (
      <SafeAreaView style={styles.wrapper}>
        <DrawerContentScrollView {...props} style={styles.drawerContainerStyle}
                                 contentContainerStyle={styles.drawerContentContainer}
        >
            <View style={styles.imageWrapper}>
                <Image style={styles.image} source={hiveIcon}/>
            </View>
                <DrawerItemList {...props} />
        </DrawerContentScrollView>
          <MB_Button
              title="Log out"
              style={styles.logoutButton}
              leftElement={<ExitIcon color={COLORS.offWhite} containerStyle={{width: 18}}/>}
              textStyle={styles.buttonText}
              onPress={() => handleSignOut()}

          />
      </SafeAreaView>
    );
}

export { AdminNavigatorDrawer };

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        backgroundColor: COLORS.primaryBlack,
        paddingHorizontal: 20,
        maxWidth: 265,
    },
    drawerContainerStyle: {
        paddingTop: 26,
        paddingHorizontal: 20,
    },
    drawerContentContainer: {
        flex: 1,
        justifyContent: 'flex-start',
    },
    imageWrapper: {
        alignItems: 'center',
        paddingBottom: 45,
    },
    image: {
        width: 110,
        height: 62,
    },
    logoutButton: {
        backgroundColor: COLORS.primaryBlack,
        justifyContent: 'flex-start',
        paddingLeft: 34,

    },
    buttonText: {
        fontFamily: 'Inter',
        fontWeight: '400',
        fontSize: 13,
        color: COLORS.offWhite,
    },

});
