import * as React from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const LogoutIcon = (props: { size?: number, containerStyle?: StyleProp<ViewStyle> }) => {
    return (
        <View style={props.containerStyle}>
            <Svg
                width={18}
                height={18}
                viewBox="0 0 18 18"
                fill="none"
                {...props}
            >
                <Path
                    d="M12.636 5.734v-.66a2.61 2.61 0 00-2.61-2.61H6.571a2.61 2.61 0 00-2.61 2.61v7.883a2.61 2.61 0 002.61 2.61h3.46a2.603 2.603 0 002.604-2.602v-.668M17.447 9.015H8.918M15.373 6.95l2.074 2.065-2.074 2.066"
                    stroke="#000"
                    strokeOpacity={0.85}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </Svg>
        </View>
    );
};

export { LogoutIcon };
