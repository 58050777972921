import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const mutation = gql`
  mutation requestSignUpCode($email: String! $name: String!) {
    requestSignUpCode(email: $email, name: $name)
  }
`;

export interface RequestType {
  email: string;
  name: string;
}

export interface MutationType {
  requestSignUpCode: boolean;
}

export function useRequestSignUpCodeMutation(options?: MutationHookOptions<MutationType, RequestType>) {
  return useMutation<MutationType, RequestType>(mutation, options);
}
