import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Clipboard } from 'react-native';
import { Divider } from './Divider';
import { Popup } from './Popup';
import { Input } from './Input';
import { MenuButton } from './MenuButton';
import { CopyIcon } from '../../resources/svgComponents/CopyIcon';

interface IPopupProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  handlePress: () => void;
  password: string;
}
const PasswordPopup = ({ visible, setVisible, password, handlePress }: IPopupProps) => {
  return (
    <Popup
      height={400}
      title="Your Password"
      hasHeader={true}
      visible={visible}
      setVisible={setVisible}
      closeOnBackdropPress={false}
      hideCloseButton
    >
      <View style={styles.container}>
        <Divider empty height={12} />
        <Text maxFontSizeMultiplier={1} style={styles.title}>Please make sure to copy and securely save this password, as you will need it to sign in to your account in the future</Text>
        <Divider empty height={32} />
        <View>
          <TouchableOpacity onPress={() => Clipboard.setString(password)} style={styles.copyIcon}>
            <CopyIcon />
          </TouchableOpacity>
          <Input editable={false} style={{ width: 320 }} placeholder="" value={password} />
        </View>
        <Divider empty height={32} />
        <MenuButton
          onPress={() => { setVisible(false); handlePress(); }}
          colors={['#90DF75', '#62B655']}
          text="Got it!"
          height={64}
          width={320}
        />
      </View>
    </Popup>
  );
};

export { PasswordPopup };

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  confetti: {
    position: 'absolute',
    width: 180,
    left: '50%',
    top: -50,
    transform: [{ translateX: -90 }],
  },
  title: {
    fontFamily: 'Secular One',
    fontWeight: '400',
    color: '#000000',
    fontSize: 18,
    width: 330,
    textAlign: 'center',
  },
  copyIcon: {
    position: 'absolute',
    right: 20,
    top: 20,
    zIndex: 10,
  },
});
