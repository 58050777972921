import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Defs, LinearGradient, Path, Stop, G } from 'react-native-svg';

interface Props {
  colored?: boolean;
  selected?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  cellSize: number;
}

const WorkingBarSliderTooltip = React.memo(({ cellSize, selected, colored, containerStyle }: Props) => {
  return (
    <View
      style={[
        containerStyle,
        ]}
    >
      {selected ? (
        <Svg width={cellSize} height="30px" viewBox="0 0 24 27">
          <Defs>
            <LinearGradient x1="49.9998419%" y1="100%" x2="50.0001933%" y2="0%" id="a">
              <Stop stopColor={colored ? '#FFCF53' : '#bababa'} offset="0%" />
              <Stop stopColor={colored ? '#F90' : '#bababa'} offset="100%" />
            </LinearGradient>
          </Defs>
          <G
            transform="translate(1 1)"
            stroke="none"
            strokeWidth={1}
            fill="none"
            fillRule="evenodd"
          >
            <Path
              fill="url(#a)"
              fillRule="nonzero"
              d="M11.04664 25.076642L0.37633 18.916142 0.37633 6.595082 11.04674 0.434542 21.71704 6.595082 21.71704 18.916142z"
            />
            <Path
              stroke="#BDBDBD"
              strokeWidth={0.752655}
              d="M10.85854 25.402542L11.04664 25.511242 11.23484 25.402542 21.90524 19.242042 22.09334 19.133442 22.09334 18.916142 22.09334 6.595082 22.09334 6.377812 21.90524 6.269172 11.23484 0.108637 11.04674 0 10.85854 0.108636 0.18817 6.269172 0 6.377802 0 6.595082 0 18.916142 0 19.133442 0.18816 19.242042z"
            />
          </G>
        </Svg>
      ) : (
        <Svg width={cellSize} height="30px" viewBox="0 0 24 27">
          <Path
            transform="translate(1 1)"
            fill="#FFF"
            fillRule="nonzero"
            stroke="#BDBDBD"
            strokeWidth={0.752655}
            d="M10.858524 25.402542L11.046724 25.511242 11.234824 25.402542 21.905224 19.242042 22.093424 19.133442 22.093424 18.916142 22.093424 6.595082 22.093424 6.377812 21.905224 6.269172 11.234824 0.108637 11.046724 3.33066907e-16 10.858524 0.108636 0.188164 6.269172 9.99999999e-7 6.377802 9.99999999e-7 6.595082 -8.8817842e-16 18.916142 -8.8817842e-16 19.133442 0.188164 19.242042z"
          />
        </Svg>
      )}
    </View>
  );
});

export { WorkingBarSliderTooltip };
