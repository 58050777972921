import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { envs } from '../../env';
import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { signedInContextGlobalFunction } from '../context/SignedInContext';

export async function getNewToken(accessToken: string, refreshToken: string) {
  const client = new ApolloClient({
    uri: envs.SERVER_URL,
    cache: new InMemoryCache(),
    headers: {
      authorization: accessToken ? 'Bearer ' + accessToken : '',
    },
  });

  try {
    const response = await client.mutate({
      mutation: gql`
        mutation refreshAccessToken($refreshToken: String!) {
          refreshAccessToken(refreshToken: $refreshToken) {
            accessToken {
              token
              expiresIn
              expiresInMs
              expiresAt
              expiresAtMs
            }
            refreshToken
          }
        }
      `,
      variables: {
        refreshToken: refreshToken.split(' ')[1],
      },
    });

    let refreshAccessToken = { ...response.data.refreshAccessToken };
    delete refreshAccessToken.accessToken.__typename;

    return refreshAccessToken;

  } catch (error) {
    await MB_accessTokenUtils.removeAccessToken();
    signedInContextGlobalFunction?.signOut?.({});
  }
}
