import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const mutation = gql`
  mutation requestResetPasswordCode($email: String!) {
    requestResetPasswordCode(email: $email)
  }
`;

export interface RequestType {
  email: string;
}

export interface MutationType {
  requestResetPasswordCode: boolean;
}

export function useRequestResetPasswordCodeMutation(options?: MutationHookOptions<MutationType, RequestType>) {
  return useMutation<MutationType, RequestType>(mutation, options);
}
