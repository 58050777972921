import React, { useCallback, useEffect, useState } from 'react';
import { Keyboard, StyleSheet, Text } from 'react-native';
import { applyTransparency } from '../../../constants/colors';
import { ScreenProps } from '../../../typesInterfacesEnums/componentProps';
import { BackButton } from '../../helperComponents/BackButton';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { DismissKeyboard } from '../../helperComponents/DismissKeyboard';
import { Divider } from '../../helperComponents/Divider';
import { ErrorPopup } from '../../helperComponents/ErrorPopup';
import { Input } from '../../helperComponents/Input';
import { MenuButton } from '../../helperComponents/MenuButton';
import { GameMode } from '../GameScreen';
import { GameStatus, useJoinGameByCodeMutation } from './useJoinGameByCodeMutation.gql';
import { ApolloError } from '@apollo/client';
import { EventTypeGameEventType, useGameEventSubscription } from './useGameEventSubscription.gql';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getErrorText } from '../../../utils/errors';

const JOIN_CODE_LENGTH = 6;

const JoinGame = ({ route, navigation }: ScreenProps<'JoinGame'>) => {
  const [name, setName] = useState(route.params?.userName || '');
  const [joinCode, setJoinCode] = useState('');
  const [hasError, setHasError] = useState({
    visible: false,
    popup: false,
    text: '',
  });
  const { data: gameEventSubscription } = useGameEventSubscription();
  const [joinGame, { error, loading }] = useJoinGameByCodeMutation({
    variables: { inputs: { joinCode, userName: name } },
  });

  const navigateToGameScreen = useCallback(() => {
    if (gameEventSubscription?.gameEvent.game) {
      navigation.navigate('Game', {
        game: gameEventSubscription?.gameEvent.game,
        mode: GameMode.JOIN_A_GAME,
      });
    }
  }, [gameEventSubscription?.gameEvent.game, navigation]);

  const handleJoinGameError = (err: ApolloError) => {
    setHasError({ popup: true, visible: true, text: getErrorText(err) });
  };

  const handleJoinGame = () => {
    AsyncStorage.setItem('playing-name', name);
    joinGame().catch((err) => {
      handleJoinGameError(err);
    });
  };

  useEffect(() => {
    if (error) {
      handleJoinGameError(error);
    }
  }, [error]);

  useEffect(() => {
    if (
      gameEventSubscription?.gameEvent.eventType === EventTypeGameEventType.ON_GAME_START &&
      gameEventSubscription.gameEvent.game.status !== GameStatus.FINISHED
    ) {
      navigateToGameScreen();
    }
    if (gameEventSubscription?.gameEvent && gameEventSubscription.gameEvent.game.status === GameStatus.FINISHED) {
      console.warn('finished game ignored!');
    }
  }, [gameEventSubscription, navigateToGameScreen, navigation]);

  const onNext = () => {
    Keyboard.dismiss();
    handleJoinGame();
  };

  return (
    <>
      <DismissKeyboard>
        <ComponentWrapper hasInset hasScroll={false}>
          <Text maxFontSizeMultiplier={1} style={styles.title}>
            Join A Game
          </Text>
          <Text maxFontSizeMultiplier={1} style={styles.subtitle}>
            Please enter your name and Invitation code to join the game
          </Text>
          <Divider empty height={36} />
          <Input textContentType="name" placeholder="Name" value={name} setValue={setName} onSubmitEditing={onNext} />
          <Divider empty height={16} />
          <Input
            placeholder="Invitation Code"
            value={joinCode}
            maxLength={JOIN_CODE_LENGTH}
            setValue={setJoinCode}
            onSubmitEditing={onNext}
          />
          <Divider empty height={28} />
          <MenuButton
            onPress={onNext}
            disabled={!name || !joinCode || loading}
            colors={['#90DF75', '#62B655']}
            text="Join Game Now"
            height={64}
            width={240}
            loading={loading}
          />
          <Divider empty height={24} />
          <BackButton text="Back To Lobby" onPress={() => navigation.reset({ index: 0, routes: [{ name: 'Lobby' }] })} />
        </ComponentWrapper>
      </DismissKeyboard>
      <ErrorPopup
        isVisible={hasError.popup}
        setVisible={() => setHasError(v => ({ ...v, popup: false }))}
        text={hasError.text}
      />
    </>
  );
};

export { JoinGame };

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Secular One',
    fontSize: 32,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
  },
  subtitle: {
    fontFamily: 'Inter',
    fontSize: 14,
    maxWidth: 270,
    textAlign: 'center',
    color: '#3F3F3F',
  },
  waiting: {
    fontSize: 20,
    fontFamily: 'Secular One',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
  },
});
