import React, { ReactChild, useCallback, useEffect, useState, useMemo } from 'react';
import { ActivityIndicator, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { ScreenProps } from '../../../typesInterfacesEnums/componentProps';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { Divider } from '../../helperComponents/Divider';
import { applyTransparency } from '../../../constants/colors';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import { BackButton } from '../../helperComponents/BackButton';
import { useOpenClose } from '../../../utils/hooks';
import { EditPopup } from '../../helperComponents/EditPopup';
import { RemovePopup } from '../../helperComponents/RemovePopup';
import { HiveUserType, useHiveUsersQuery } from './useHiveUsersQuery.gql';
import { useRemoveUserFromHiveMutation } from './useRemoveUserFromHiveMutation';
import { useEditUserFromHiveMutation } from './useEditUserFromHiveMutation.gql';
import { ApolloError } from '@apollo/client';
import { ErrorPopup } from '../../helperComponents/ErrorPopup';
import { isMobile, isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { utils } from '../../../utils/utils';
import { envs } from '../../../../env';
import { MenuButton } from '../../helperComponents/MenuButton';
import { WEB_POST_MESSAGE_OBJECT_TYPES } from '../ServerRedirect/serverRedirectUtils';
import { MB_EVENT_EMMITER_EVENT_TYPE, MB_EventEmmiter, MB_EventObject } from '@mightybyte/rnw.utils.event-emmiter';
import { HiveSize, PaymentStatus, sizeTitle } from '../../../constants/constants';
import { useGetHiveByIdQuery } from './useGetHiveByIdQuery.gql';
import { PaymentPopup } from '../../helperComponents/PaymentPopup';
import { RefreshButton } from '../../helperComponents/RefreshButton';
import { ChangeNameIcon } from '../../../resources/svgComponents/ChangeNameIcon';
import { ChangeHiveNamePopup } from '../../helperComponents/ChangeHiveNamePopup';
import { useChangeHiveNameMutation } from './useChangeHiveNameMutation';
import { LeaderBoardButton, createLeaderboardData, LeaderBoard } from '../../helperComponents/LeaderBoard';
import { getErrorText } from '../../../utils/errors';
import { SeatIcon } from '../../../resources/svgComponents/SeatIcon';
import { SeatXIcon } from '../../../resources/svgComponents/SeatXIcon';
import { ClockIcon } from '../../../resources/svgComponents/ClockIcon';
import { ControllerIcon } from '../../../resources/svgComponents/ControllerIcon';
import { useGetHiveAnalyticsQuery } from './useGetHiveAnalyticsQuery.gql';
import { UserItem } from './UserItem/UserItem';
import { OnBoardPopup } from '../../helperComponents/OnBoardPopup';

const InfoCard = ({ icon, title, value }: { icon: ReactChild, title: string, value: string }) => {
  return (
    <View style={styles.infoCard}>
      <View style={styles.infoCardHeader}>
        {icon}
        <Text style={styles.infoKey}>{title}</Text>
      </View>
      <Text style={styles.infoValue} numberOfLines={1}>{value}</Text>
    </View>
  );
};

const MyHiveData = ({ navigation, route }: ScreenProps<'MyHiveData'>) => {
  const { id } = route.params;

  // TODO: We might need to paginate this in the future.
  const { error: getHiveUsersError, data: { hiveUsers: users } = {}, refetch: refetchHiveUsers, loading: hiveUsersLoading } = useHiveUsersQuery({ hiveId: id, page: 1, pageSize: 2000 });
  const { error: getHiveDataError, data: { hive } = {}, refetch: refetchHiveData, loading: hiveDataLoading } = useGetHiveByIdQuery(id);
  const { error: getHiveAnalyticsDataError, data: { getHiveAnalytics: hiveAnalytics } = {}, refetch: refetchHiveAnalyticsData, loading: hiveAnalyticsDataLoading } = useGetHiveAnalyticsQuery(id);

  const [removeUser, { loading: removeUserFromHiveLoading }] = useRemoveUserFromHiveMutation();
  const [editUser, { loading: editUserFromHiveLoading }] = useEditUserFromHiveMutation();
  const [changeHiveName, { loading: changeHiveNameLoading }] = useChangeHiveNameMutation();

  const [selectedUser, setSelectedUser] = useState<HiveUserType | undefined>(undefined);

  const [isChangeNamePopupVisible, setChangeNamePopupOpen, setChangeNamePopupClose] = useOpenClose();
  const [isPaymentPopupVisible, setPaymentPopupOpen, setPaymentPopupClose] = useOpenClose();
  const [isRemovePopupVisible, setRemovePopupOpen, setRemovePopupClose] = useOpenClose();
  const [isEditPopupVisible, setEditPopupOpen, setEditPopupClose] = useOpenClose();

  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [isOnBoardVisible, setIsOnBoardVisible] = useState(false);

  const [hasError, setHasError] = useState({
    visible: false,
    popup: false,
    text: '',
  });

  const handlePay = async () => {
    if (!isMobileApp) {
      let token = await MB_accessTokenUtils.getAccessToken();
      token = token?.replace('Bearer ', '');
      utils.openExternalWindow(envs.API_URL + `/api/subscription/stripe/checkout?hiveId=${id}&accessToken=${token}`, 'Checkout');
    }
  };

  const stripeRedirectCallback = useCallback((event: MB_EventObject) => {
    try {
      if (event.origin !== envs.WEBSITE_BASE_URL || (event.data.type !== WEB_POST_MESSAGE_OBJECT_TYPES.STRIPE_PAYMENT && event.data.type !== WEB_POST_MESSAGE_OBJECT_TYPES.GENERAL_ERROR)) {
        return;
      }
      if (event.data.success && event.data.type !== WEB_POST_MESSAGE_OBJECT_TYPES.GENERAL_ERROR) {
        refetchHiveData();
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
      setHasError({ popup: true, visible: true, text: 'Something went wrong' });
    }
  }, [refetchHiveData]);

  const handleEditPress = (user: any) => {
    setSelectedUser(user);
    setEditPopupOpen();
  };
  const handleRemovePress = (user: any) => {
    setSelectedUser(user);
    setRemovePopupOpen();
  };

  const hidePopups = useCallback(() => {
    setRemovePopupClose();
    setEditPopupClose();
    setChangeNamePopupClose();
  }, [setChangeNamePopupClose, setEditPopupClose, setRemovePopupClose]);

  const handleError = useCallback((err: ApolloError) => {
    hidePopups();
    setHasError({ popup: true, visible: true, text: getErrorText(err) });
  }, [hidePopups]);

  const onActionDone = useCallback(() => {
    // hide modals
    hidePopups();
    // reset selected user
    setSelectedUser(undefined);
    // get the new data
    refetchHiveUsers();
    refetchHiveData();
  }, [hidePopups, refetchHiveData, refetchHiveUsers]);

  const handleRemoveSubmitPress = () => {
    if (selectedUser) {
      removeUser({ variables: { userId: selectedUser.id, hiveId: id } })
        .then(onActionDone)
        .catch(handleError);
    }
  };

  const handleEditSubmitPress = (firstName: string, lastName: string) => {
    if (selectedUser) {
      editUser({ variables: { userId: selectedUser.id, firstName: firstName, lastName: lastName } })
        .then(onActionDone)
        .catch(handleError);
    }
  };
  const handleChangeName = (name: string) => {
    if (hive) {
      changeHiveName({ variables: { hiveId: hive.id, name: name } })
        .then(onActionDone)
        .catch(handleError);
    }
  };

  const getStatusText = () => {
    if (hive?.paymentStatus === PaymentStatus.Active) {
      return <Text style={{ fontWeight: '700', color: '#409853' }}>Active</Text>;
    }
    if (isMobileApp) {
      return <Text style={{ fontWeight: '700', color: '#E02E2E' }}>Inactive</Text>;
    }
    if (hive?.paymentStatus === PaymentStatus.Pending) {
      return <Text style={{ fontWeight: '700', color: '#E89823' }}>Pending</Text>;
    }
    return <Text style={{ fontWeight: '700', color: '#E02E2E' }}>Not paid</Text>;
  };

  useEffect(() => {
    const eventSub = MB_EventEmmiter.addListener(MB_EVENT_EMMITER_EVENT_TYPE.message, stripeRedirectCallback);

    return () => {
      eventSub.remove();
    };
  }, [stripeRedirectCallback]);

  useEffect(() => {
    if (getHiveUsersError) {
      handleError(getHiveUsersError);
    } else if (getHiveDataError) {
      handleError(getHiveDataError);
    } else if (getHiveAnalyticsDataError) {
      handleError(getHiveAnalyticsDataError);
    }
  }, [getHiveUsersError, getHiveDataError, getHiveAnalyticsDataError, handleError]);

  useEffect(() => {
    if ((hive && hive.paymentStatus !== PaymentStatus.Active) && !isMobile) {
      setPaymentPopupOpen();
    }
  }, [hive, setPaymentPopupOpen]);

  const leaderBoardData = useMemo(() => {
    return createLeaderboardData({
      users: users ?? [],
    });
  }, [users]);

  if (!hive || hiveUsersLoading || hiveDataLoading || hiveAnalyticsDataLoading) {
    return (
      <ComponentWrapper hideBee hasInset>
        <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
          <ActivityIndicator size={'large'} color={'E89823'} />
        </View>
      </ComponentWrapper>
    );
  }

  return (
    <>
      <ComponentWrapper hideBee hasInset scrollEnabled hasScroll={true} style={{ paddingHorizontal: 0 }}>
        <LeaderBoard data={leaderBoardData}
          isVisible={showLeaderboard}
          onClose={() => { setShowLeaderboard(false); }}
        />
        <View style={styles.topContainer}>
          <BackButton onPress={() => navigation.reset({ index: 1, routes: [{ name: 'Lobby' }, { name: 'MyHives' }] })} text="Back" />
          <View style={styles.topRightContainer}>
            {(isMobile && hive.size !== HiveSize.SingleUser) &&
              <LeaderBoardButton round
                action={() => setShowLeaderboard(true)}
                loading={hiveUsersLoading}
                size={40}
                noMargin
                containerStyle={{ marginRight: 12 }}
              />
            }
            <View>
              <RefreshButton
                onPress={() => {
                  refetchHiveData();
                  refetchHiveUsers();
                  refetchHiveAnalyticsData();
                }}
              />
              {!isMobile && <Text style={styles.onBoardText} onPress={() => setIsOnBoardVisible(true)}>How to onboard users?</Text>}
            </View>
          </View>
        </View>
        {isMobile && <Text style={[styles.onBoardText, styles.mobileOnBoardText]} onPress={() => setIsOnBoardVisible(true)}>How to onboard users?</Text>}
        <Divider empty height={20} />
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Text style={styles.title}>{hive.name}</Text>
            <TouchableOpacity onPress={setChangeNamePopupOpen}>
              <ChangeNameIcon />
            </TouchableOpacity>
          </View>
          <Divider empty height={16} />
          <View>
            <Text style={styles.statusText}>
              Status: {getStatusText()}
            </Text>
          </View>
          {
            !isMobileApp && hive.paymentStatus !== PaymentStatus.Active ?
              <>
                <Divider empty height={24} />
                <MenuButton
                  onPress={() => handlePay()}
                  colors={['#90DF75', '#62B655']}
                  text="Pay now"
                  textStyle={{ fontSize: 16, fontWeight: '400' }}
                  height={50}
                  width={160}
                />
              </>
              : null
          }
          <Divider empty height={24} />
          <View style={{ flexDirection: 'row' }}>
            <View style={styles.chips}><Text style={styles.chipsText}>Size: <Text style={styles.bold}>{sizeTitle[hive.size]}</Text></Text></View>
            <View style={styles.chips}><Text style={styles.chipsText}>Hive Key: <Text style={styles.bold}>{hive.code}</Text></Text></View>
          </View>
        </View>
        <View style={[styles.infoContainer, hive.size === HiveSize.SingleUser && styles.infoContainerCenter]}>
          <InfoCard
            icon={<SeatIcon />}
            title="Seats Taken"
            value={`${hive.seatTaken}`}
          />
          <InfoCard
            icon={<SeatXIcon />}
            title="Seats Available"
            value={`${hive.numberOfSeats - hive.seatTaken}`}
          />
          {hive.size !== HiveSize.SingleUser &&
            <>
              <InfoCard
                icon={<ClockIcon />}
                title="Total Hive Time"
                value={`${((hiveAnalytics?.totalTime || 0) / 60000).toFixed(3)} minutes`}
              />
              <InfoCard
                icon={<ControllerIcon />}
                title="Total Hive Games"
                value={`${hiveAnalytics?.numberOfGames || 0}`}
              />
            </>
          }
          {(!isMobile && hive.size !== HiveSize.SingleUser) &&
            <View style={{ height: 150, justifyContent: 'center' }}>
              <LeaderBoardButton noMargin action={() => setShowLeaderboard(true)} loading={hiveUsersLoading} />
            </View>
          }
        </View>
        <Divider empty height={40} />
        <View style={styles.container}>
          {(!isMobile && (users?.length || 0) >= 1) &&
            <View style={styles.header}>
              {['LastName', 'FirstName', 'UserName', 'Password', 'Total Games', 'Total Time (mins)', 'Points'].map((column) => (
                <View style={styles.headerView} key={column}>
                  <Text style={styles.headerText}>{column}</Text>
                </View>
              ))}
              <View style={styles.headerViewLast} />
              <Text />
            </View>
          }
          {users?.map((user, index: number) => {
            return (
              <UserItem
                key={user.id}
                user={user}
                index={index}
                editAction={() => handleEditPress(user)}
                removeAction={() => handleRemovePress(user)}
              />);
          })}
        </View>
        <Divider empty height={40} />
      </ComponentWrapper>
      <PaymentPopup visible={isPaymentPopupVisible} setVisible={setPaymentPopupClose} onPayClick={handlePay} />
      <EditPopup
        firstName={selectedUser ? selectedUser.firstName : ''}
        lastName={selectedUser ? selectedUser.lastName : ''}
        userName={selectedUser ? selectedUser.userName : ''}
        visible={isEditPopupVisible}
        setVisible={setEditPopupClose}
        loading={editUserFromHiveLoading}
        onSavePress={handleEditSubmitPress}
      />
      <RemovePopup
        firstName={selectedUser ? selectedUser.firstName : ''}
        lastName={selectedUser ? selectedUser.lastName : ''}
        visible={isRemovePopupVisible}
        setVisible={setRemovePopupClose}
        loading={removeUserFromHiveLoading}
        onRemovePress={handleRemoveSubmitPress}
      />
      <ChangeHiveNamePopup
        currentName={hive.name}
        visible={isChangeNamePopupVisible}
        setVisible={setChangeNamePopupClose}
        loading={changeHiveNameLoading}
        onChangePress={handleChangeName}
      />
      <ErrorPopup
        isVisible={hasError.popup}
        setVisible={() => setHasError(v => ({ ...v, popup: false }))}
        text={hasError.text}
      />
      <OnBoardPopup
        visible={isOnBoardVisible}
        setVisible={setIsOnBoardVisible}
      />
    </>
  );
};

export { MyHiveData };

const styles = StyleSheet.create({
  topContainer: {
    zIndex: 100,
    height: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    ...mbPlatformStyle({
      mobile: {
        paddingHorizontal: 20,
        marginTop: 0,
      },
      web: {
        paddingHorizontal: 60,
        marginTop: 60,
      },
    }),
  },
  topRightContainer: {
    flexDirection: 'row',
  },
  onBoardText: {
    fontFamily: 'Secular One',
    fontSize: 15,
    fontWeight: '400',
    marginTop: 4,
    color: '#E89823',
  },
  mobileOnBoardText: {
    alignSelf: 'flex-end',
    marginRight: 5,
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loginText: {
    fontFamily: 'Secular One',
    marginLeft: 6,
    fontSize: 20,
    marginTop: 4,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
  },
  nameContainer: {
    borderRadius: 12,
    width: 180,
    height: 40,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -20,
  },
  name: {
    fontFamily: 'Secular One',
    fontSize: 18,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
  },
  statusText: {
    fontFamily: 'Inter',
    fontSize: 15,
    fontWeight: '500',
  },
  title: {
    fontFamily: 'Secular One',
    fontSize: 32,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
    marginRight: 16,
    maxWidth: '85%',
  },
  hivesContainer: {
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hiveType: {
    fontFamily: 'Secular One',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
    position: 'absolute',
    top: 30,
    left: -100,
    textAlign: 'right',
    width: 80,
    letterSpacing: 2,
  },
  container: {
    width: '100%',
    ...mbPlatformStyle({
      mobile: {
        paddingHorizontal: 12,
      },
      web: {
        paddingHorizontal: 54,
      },
    }),
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
  },
  headerText: {
    fontFamily: 'Inter',
    fontWeight: '600',
    fontSize: 14,
  },
  headerView: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerViewLast: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',

    ...mbPlatformStyle({
      web: {
        paddingHorizontal: 54,
      },
    }),

  },
  infoContainer: {
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    ...mbPlatformStyle({
      mobile: {
        paddingHorizontal: 20,
        paddingRight: 10,
        justifyContent: 'center',
      },
      web: {
        paddingHorizontal: 54,
        paddingRight: 34,
      },
    }),
  },
  infoContainerCenter: {
    justifyContent: 'center',
  },
  infoCard: {
    backgroundColor: '#FFF4E3',
    borderRadius: 10,
    paddingTop: 17,
    paddingLeft: 16,
    paddingBottom: 23,
    marginTop: 20,
    ...mbPlatformStyle({
      mobile: {
        width: 164,
        marginRight: 10,
      },
      web: {
        width: 198,
        marginRight: 29,
      },
    }),
  },
  infoCardHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 17,
  },
  infoKey: {
    color: '#453B3B',
    fontSize: 13,
    marginLeft: 2,
  },
  infoValue: {
    fontFamily: 'Secular One',
    fontWeight: '400',
    color: '#333333',
    textShadowColor: applyTransparency('#000000', 0.26),
    textShadowOffset: { width: 0, height: 2 },
    textShadowRadius: 2,
    ...mbPlatformStyle({
      mobile: {
        fontSize: 18,
      },
      web: {
        fontSize: 21,
      },
    }),
  },
  chips: {
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFEED6',
    marginHorizontal: 8,
    borderRadius: 20,

    ...mbPlatformStyle({
      mobile: {
        width: 160,
      },
      web: {
        width: 160,
      },
    }),
  },
  chipsText: {
    fontFamily: 'Inter',
    color: '#E89823',
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '500',
  },
  bold: {
    fontWeight: '700',
  },
});
