import {
  useMutation,
} from '@tanstack/react-query';
import { ServerError } from '../typesInterfacesEnums/typesAndInterfaces';
import { paymentApiCalls } from '../apiCalls/paymentApiCalls';
import { HiveSize } from '../constants/constants';

export const useSubscribeToStripe = () => {
  return useMutation<void, ServerError, { name: string, size: HiveSize }, unknown>(
    async (data) => paymentApiCalls.subscribeToStripe(data.name, data.size)
  );
};
