import React, { useState } from 'react';
import { MenuButton } from '../../helperComponents/MenuButton';
import { GAME_TYPES_TYPE } from '../../../constants/constants';

import { UniversalProps } from '../../../typesInterfacesEnums/componentProps';
import { lobbyButtonSharedProps, ButtonRowDivider} from './utils';
import { Divider } from '../../helperComponents/Divider';
import { Pressable, StyleSheet, Text } from 'react-native';
import { HowToPlay } from '../../helperComponents/HowToPlay';

const bee1Icon = require('../../../resources/images/bee1.png');
const bee2Icon = require('../../../resources/images/bee2.png');
const bee4Icon = require('../../../resources/images/bee3.png');

const MainLobby = ({navigation, playingName}: {
    navigation: UniversalProps['navigation'],
    playingName: string | undefined
})=> {

    const [isHowToPlayOpen, setHowToPlayOpen] = useState(false);

    return (
        <>
            <MenuButton
                onPress={() => navigation.navigate('PlayOnline', { gameType: GAME_TYPES_TYPE.MULTI_1_12, userName: playingName })}
                colors={['#FF9252', '#FF3F15']}
                text="Play Online"
                image={bee1Icon}
                {...lobbyButtonSharedProps}
            />
            <ButtonRowDivider/>
            <MenuButton
                onPress={() => navigation.navigate('Lobby', { playAFriend: true})}
                colors={['#90DF75', '#62B655']}
                text="Play a friend"
                image={bee2Icon}
                {...lobbyButtonSharedProps}
            />
            <ButtonRowDivider/>
            <MenuButton
                onPress={() => navigation.navigate('SelectGameType', { next: 'PlayWithAI', userName: playingName })}
                colors={['#747474', '#1F1F1F']}
                text="Practice"
                image={bee4Icon}
                {...lobbyButtonSharedProps}
            />
            <ButtonRowDivider/>
            <Divider empty height={30} />
            <Pressable onPress={() => setHowToPlayOpen(true)}>
                <Text maxFontSizeMultiplier={1} style={styles.howToPlayText}>
                    How to play?
                </Text>
            </Pressable>
            <HowToPlay visible={isHowToPlayOpen} setVisible={setHowToPlayOpen} />
        </>
    );
};

const styles = StyleSheet.create({
    howToPlayText: {
        fontFamily: 'Secular One',
        fontSize: 20,
        color: 'black',
        textDecorationLine: 'underline',
    },
});

export {MainLobby};
