import React, { useCallback, useMemo } from 'react';
import { View, StyleSheet, Text, FlatList } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { Popup } from '../Popup';
import LeaderBoardHeaderIcon from '../../../resources/svgComponents/LearBoardHeaderIcon';
import CoinIcon from '../../../resources/svgComponents/CoinIcon';
import UserBeeIcon from '../../../resources/svgComponents/UserBeeIcon';
import FirstPlaceIcon from '../../../resources/svgComponents/FirtsPlaceIcon';
import SecondPlaceIcon from '../../../resources/svgComponents/SecondPlaceIcon';
import ThirdPlaceIcon from '../../../resources/svgComponents/ThirdPlaceIcon';
import RibbonYellow from '../../../resources/svgComponents/Ribbon';
import RibbonYellowMobile from '../../../resources/svgComponents/RibbonMobile';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import { Divider } from '../Divider';
import { isMobile, isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { LeaderBoardData, LeaderBoardProps } from './utils';

const LeaderBoard = ({ data, isVisible, onClose }: LeaderBoardProps) => {

    const tableData = useMemo(() => {
        return data.map((item, index) => {
            return item.map((i) => {
                return { ...i, place: index + 1 };
            });
        });
    }, [data]);

    const shouldHidePlace = useMemo(() => {
        const isAllDataEqual = data.every((item) => {
            return item.every((i) => {
                return i.points === item[0].points && i.points === 0;
            });
        });
        return isAllDataEqual;
    }, [data]);

    function getIconByPlace(place: number) {
        switch (place) {
            case 1:
                return <FirstPlaceIcon containerStyle={styles.topThreeIconContainer} />;
            case 2:
                return <SecondPlaceIcon containerStyle={styles.topThreeIconContainer} />;
            case 3:
                return <ThirdPlaceIcon containerStyle={styles.topThreeIconContainer} />;
            default:
                return <UserBeeIcon containerStyle={{ marginRight: 10 }} />;
        }
    }


    const TableItem = useCallback((item: LeaderBoardData[0][0] & { place: number }, index: number) => {
        const placeSuffix = (place: number) => {
            switch (place) {
                case 1:
                    return 'st';
                case 2:
                    return 'nd';
                case 3:
                    return 'rd';
                default:
                    return 'th';
            }
        };
        return (
            <View key={index} style={styles.tableItemWrapper}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {!shouldHidePlace && <Text style={styles.placeText}>{`${item.place}${placeSuffix(item.place)}`}</Text>}
                    {getIconByPlace(item.place)}
                    <Text style={styles.tableItemText}>{item.userName}</Text>
                </View>
                <View style={styles.pointsBadge}>
                    <CoinIcon size={20} containerStyle={{ marginRight: 10 }} />
                    <Text style={styles.pointsText}>{item.points}</Text>
                </View>
            </View>
        );
    }, [shouldHidePlace]);

    return (
        <Popup hasHeader={true}
            title={'Leaderboard'}
            headerComponent={<LeaderBoardHeaderIcon containerStyle={styles.titleIconContainer} />}
            visible={isVisible}
            setVisible={onClose}
            closeOnBackdropPress={true}
            containerStyle={styles.popupContainer}
        ><>
                <View style={styles.titleRibbon}>
                    {(isMobile || isMobileApp) ?
                        <RibbonYellowMobile width="100.5%" height={130} />
                        :
                        <RibbonYellow width="100.5%" height="95%" />
                    }
                    <Text style={styles.title}>Leaderboard</Text>
                </View>
                {tableData.length > 0 && <FlatList style={styles.tableContainer}
                    data={tableData.flat()}
                    renderItem={({ item, index }) => TableItem(item, index)}

                />}
                <Divider height={10} empty />
            </>
        </Popup>
    );
};

const styles = StyleSheet.create({
    popupContainer: {
        borderColor: '#FFCF53',
        maxWidth: 540,
        height: 'auto',
        top: 20,
    },
    titleIconContainer: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        ...mbPlatformStyle({
            mobile: {
                transform: [{ scale: 0.8 }],
                top: -70,
            },
            web: {
                top: -80,
            },
        }),
    },
    titleRibbon: {
        position: 'relative',
        alignSelf: 'center',
        ...mbPlatformStyle({
            mobile: {
                width: '106.5%',
                marginTop: 30,
            },
            web: {
                marginTop: 40,
                width: '119%',
            },
        }),
    },
    title: {
        fontFamily: 'Secular One',
        fontSize: 22,
        fontWeight: '400',
        textTransform: 'uppercase',
        position: 'absolute',
        width: '100%',
        textAlign: 'center',
        top: '35%',
        color: '#000000',
        // ...mbPlatformStyle({
        //     mobile: {
        //         top: '30%',
        //         left: '34%',
        //     },
        //     web: {
        //         top: '30%',
        //         left: '39%',
        //     },
        // }),
    },
    pointsBadge: {
        minWidth: 80,
        height: 50,
        borderRadius: 25,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    pointsText: {
        fontFamily: 'Secular One',
        fontSize: 20,
        fontWeight: '400',
        color: COLORS.black,
    },
    tableContainer: {
        display: 'flex',
        width: '100%',
        ...mbPlatformStyle({
            mobile: {
                paddingHorizontal: 20,
            },
            web: {
                paddingHorizontal: 30,
            },
        }),
    },
    tableItemWrapper: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#FDF5EA',
        borderRadius: 7,
        height: 59,
        ...mbPlatformStyle({
            mobile: {
                paddingHorizontal: 8,
            },
            web: {
                paddingHorizontal: 20,
            },
        }),
    },
    tableItemText: {
        fontFamily: 'Inter',
        fontSize: 14,
        fontWeight: '600',
    },
    placeText: {
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: '500',
        textTransform: 'uppercase',
        ...mbPlatformStyle({
            mobile: {
                marginRight: 10,
            },
            web: {
                marginRight: 20,
            },
        }),
    },
    topThreeIconContainer: {
        marginRight: 10,
        width: 40,
        display: 'flex',
        alignItems: 'center',

    },
});

export { LeaderBoard };
